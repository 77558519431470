<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="85%"
      max-width="750px"
      v-if="dialog === true"
      :retain-focus="false"
      @keydown.esc="openDeleteDialog()"
    >
      <v-card class="pb-4">
        <v-form ref="form" v-model="valid">
          <v-card-title class="flex justify-between">
            <span class="headline">Log Client Activities</span>
            <span @click="openDeleteDialog()">
              <i class="fas fa-times text-gray-400 hover:text-gray-500 cursor-pointer"></i>
            </span>
          </v-card-title>
          <template v-if="draft">
            <p class="text-sm text-gray-500 ml-3 items-center p-2">
              <v-icon small class="mr-2">fas fa-info-circle</v-icon>
              Draft created on {{ draftTime }}
            </p>
            <p class="text-sm text-gray-500 ml-3 items-center p-2" v-if="activity.id">
              <v-icon small class="mr-2">fas fa-info-circle</v-icon>
              This draft is an edit of an existing note. If you need to log a new activity, click
              cancel and delete this draft.
            </p>
          </template>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12" v-if="location === 'header'">
                  <v-autocomplete
                    :loading="loading"
                    filled
                    @input="saveDraft"
                    :items="clients"
                    item-text="name"
                    item-value="id"
                    v-model="activity.clientId"
                    :rules="requiredTextRule"
                    label="Client"
                    hide-details
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    :disabled="!activity.clientId"
                    no-data-text="This client does not have any related stores."
                    filled
                    :items="excludedClient"
                    @input="saveDraft"
                    item-text="name"
                    multiple
                    item-value="id"
                    v-model="activity.associatedIds"
                    label="Related Stores"
                    hide-details
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    filled
                    :items="activityTypes"
                    @input="saveDraft"
                    item-text="name"
                    item-value="id"
                    v-model="activity.typeId"
                    :rules="requiredTextRule"
                    label="Activity type"
                    hide-details
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                    filled
                    @input="saveDraft"
                    v-model="activity.categoryId"
                    :items="takeRightCategories()"
                    item-text="name"
                    :rules="requiredTextRule"
                    item-value="id"
                    label="Category"
                    hide-details
                    required
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  v-if="activity.typeId === 3 || (activity.typeId === 4 && industryId === 2)"
                >
                  <template v-if="activity.clientId">
                    <v-btn @click="showActivityFlowModal()" color="success">
                      Create a note using a flow
                      <v-badge color="red" content="New" offset-x="-5" offset-y="-1" />
                    </v-btn>
                  </template>
                  <template v-else>
                    <p class="p-1 text-orange-600">Select a client to use the activity flow!</p>
                  </template>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu
                    filled
                    v-model="fromDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    :rules="requiredTextRule"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        filled
                        label="Date"
                        prepend-inner-icon="event"
                        readonly
                        :value="fromDateFormatted"
                        :rules="requiredTextRule"
                        hide-details
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      locale="en-in"
                      v-model="activity.fromDateVal"
                      no-title
                      :rules="requiredTextRule"
                      @input="
                        fromDateMenu = false;
                        saveDraft();
                      "
                      :min="minDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    filled
                    @input="saveDraft"
                    type="time"
                    v-model="activity.time"
                    :rules="requiredTextRule"
                    hide-details
                    label="Time"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="mb-0 pb-0">
                  <p class="p-0 font-semibold">Activity Details</p>
                  <custom-editor
                    :uploadImage="false"
                    @input="saveDraft"
                    v-model="activity.body"
                    required
                    :rules="requiredTextRule"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-file-input
                    @change="saveDraft"
                    v-model="activity.attachments"
                    label="Attachments"
                    multiple
                    :rules="filesShouldBeLessThanMBRule(100)"
                    small-chips
                    show-size
                    counter
                    prepend-icon=""
                    prepend-inner-icon="attachment"
                    filled
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-for="(image, index) in activity.attachments"
                  :key="index"
                >
                  <v-text-field
                    @input="saveDraft"
                    :label="`Description for ${image.name}`"
                    v-model="activity.labels[image.name]"
                    v-if="image.name !== undefined"
                    filled
                    :rules="requiredTextRule.concat(maxCharactersRule(50))"
                    hide-details="auto"
                  >
                    image
                  </v-text-field>
                </v-col>
              </v-row>

              <template v-if="activity.typeId === 2">
                <v-divider class="py-3" />

                <h6>CO-OP Deliverable</h6>
                <v-row>
                  <v-col cols="12">
                    <v-switch
                      @input="saveDraft"
                      v-model="coopScreenshots"
                      label="Upload Screenshots"
                      class="mb-3"
                      color="primary"
                      persistent-hint
                      hint="Only image files(.png, .jpg, .jpeg and .gif) are accepted."
                    />
                  </v-col>
                </v-row>

                <v-row v-if="coopScreenshots">
                  <v-col cols="12">
                    <v-file-input
                      @change="saveDraft"
                      v-model="currentFile"
                      :disabled="coopScreenshotCurrentDisabled"
                      label="Current Version"
                      accept="image/*"
                      :rules="fileShouldBeLessThanMBRule(10)"
                      small-chips
                      show-size
                      counter
                      :hint="coopScreenshotCurrentDisabled ? coopScreenshotFileExistsMessage : ''"
                      filled
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      @change="saveDraft"
                      v-model="previousFile"
                      :disabled="coopScreenshotPreviousDisabled"
                      label="Previous Version"
                      accept="image/*"
                      :rules="fileShouldBeLessThanMBRule(10)"
                      small-chips
                      show-size
                      counter
                      :hint="coopScreenshotPreviousDisabled ? coopScreenshotFileExistsMessage : ''"
                      filled
                    />
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card-text>
          <v-card-actions class="px-8">
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="openDeleteDialog()">Cancel</v-btn>
            <v-btn color="primary" :disabled="!valid" @click="postActivity">
              <v-progress-circular
                class="mr-2"
                size="20"
                indeterminate
                color="primary"
                v-if="saving"
              />
              <span v-else>Save</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" @keydown.esc="deleteDialog = false" persistent max-width="290">
      <v-card>
        <v-card-title class="error headline" style="font-weight: bold; color: white">
          Confirm Delete
        </v-card-title>
        <v-card-text class="font-weight-medium">
          Are you sure you want to delete this draft?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="error" @click="closeDialog">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="activityFlowModal" persistent width="95%" max-width="960px">
      <SEMActivityFlow
        :clientId="activity.clientId"
        v-if="activityFlowModal && activity.clientId && activity.typeId === 3"
        @update="updateActivity"
        @close="closeActivityModal"
      />
      <SocialActivityFlow
        :clientId="activity.clientId"
        v-else-if="activityFlowModal && activity.clientId && activity.typeId === 4"
        @update="updateActivity"
        @close="closeActivityModal"
      />
    </v-dialog>
  </div>
</template>

<script>
  import dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  import _ from "lodash";

  import ClientActivityResource from "@/api/resources/client/ClientActivityResource";
  import ClientResource from "@/api/resources/ClientResource";
  import { EventBus } from "@/bootstrap/EventBus";
  import SEMActivityFlow from "@/components/layout/activity-flows/SEMActivityFlow.vue";
  import SocialActivityFlow from "@/components/layout/activity-flows/SocialActivityFlow.vue";
  import CustomEditor from "@/components/utilities/CustomEditor.vue";
  import clientActivitiesMixin from "@/mixins/clientActivitiesMixin";

  dayjs.extend(utc);

  export default {
    name: "ClientActivity",
    components: { SEMActivityFlow, CustomEditor, SocialActivityFlow },
    mixins: [clientActivitiesMixin],
    data: () => ({
      dialog: false,
      menu: {},
      valid: true,
      deleteDialog: false,
      clients: [],
      categories: [],
      activityTypes: [],
      associatedStores: [],
      minDate: "2020-01-05",
      fromDateMenu: false,
      location: "header",
      activity: {
        fromDateVal: dayjs().format("YYYY-MM-DD"),
        associatedIds: [],
        loggedAt: "",
        body: "",
        time: dayjs().format("HH:mm"),
        clientId: null,
        typeId: null,
        categoryId: null,
        attachments: [],
        labels: {},
      },
      defaultItem: {
        fromDateVal: dayjs().format("YYYY-MM-DD"),
        associatedIds: [],
        loggedAt: "",
        body: "",
        time: dayjs().format("HH:mm"),
        clientId: null,
        typeId: null,
        categoryId: null,
        attachments: [],
        labels: {},
      },
      industryId: null,
      saving: false,
      loading: true,
      coopScreenshots: false,
      currentFile: null,
      previousFile: null,
      coopScreenshotCurrentVersionLabel: "Co-Op Screenshot Current",
      coopScreenshotPreviousVersionLabel: "Co-Op Screenshot Previous",
      coopScreenshotCurrentDisabled: false,
      coopScreenshotPreviousDisabled: false,
      coopScreenshotFileExistsMessage:
        "Please delete the existing file before uploading a new one!",
      activityFlowModal: false,
    }),
    watch: {
      "activity.clientId": function activityClientId() {
        this.associatedStores = [];
        if (!this.activity.id) {
          this.activity.associatedIds = [];
        }
        const client = this.clients.find((c) => c.id === this.activity.clientId);

        if (client && client.Group) {
          this.getAssociatedStores(client.Group.id);
        }

        if (client && client.industryId) {
          this.industryId = client.industryId;
        }
      },
      "activity.attachments": function activityAttachments() {
        if (!this.loading) {
          this.activity.attachments.forEach((attachment) => {
            if (!this.activity.labels[attachment.name]) {
              this.activity.labels[attachment.name] = attachment.name;
              this.$nextTick(() => {
                this.$refs.form.validate();
              });
            }
          });
        }
      },
      coopScreenshots(newValue) {
        if (!newValue) {
          this.resetCoopDeliverableFileFields();
        }
      },
      activityTypeIdWatcher() {
        this.coopScreenshots = false;
      },
      activityClientIdWatcher(value) {
        if (value) {
          this.getClientActivityDraft();
        }
      },
    },
    computed: {
      fromDateFormatted() {
        return this.activity.fromDateVal;
      },
      excludedClient() {
        return this.associatedStores.filter((client) => client.id !== this.activity.clientId);
      },
      activityTypeIdWatcher() {
        return this.activity.typeId;
      },
      activityClientIdWatcher() {
        return this.activity.clientId;
      },
    },
    async created() {
      await this.registerEvents();
      await this.getActivityTypesAndCategories();

      if (this.location === "header") {
        await this.getClients();
      }

      this.loading = false;
    },
    beforeDestroy() {
      this.unloadEvents();
    },
    methods: {
      async getClients() {
        if (this.$store.getters["authorization/currentUser"].UserType.name === "Employee") {
          this.clients = await ClientResource.getClientList({
            withFields: ["id", "name", "slug", "tenantId", "industryId"],
            withGroup: true,
          }).then((res) => res.data);
        } else {
          this.clients = _.sortBy(
            this.$store.getters["authorization/currentUserAuthorizedClients"],
            "name"
          );
        }
      },
      async getActivityTypesAndCategories() {
        const [activityTypes, categories] = await Promise.all([
          ClientActivityResource.getActivityTypes().then((res) => res.data),
          ClientActivityResource.getActivityCategories().then((res) => res.data),
        ]);

        this.activityTypes = activityTypes.filter(({ active }) => active);
        this.categories = categories;
      },
      takeRightCategories() {
        const type = this.activityTypes.find((t) => t.id === this.activity.typeId);

        if (type && type.sortable) {
          const categories = this.categories.filter(
            (category) =>
              category.typeId === this.activity.typeId &&
              (category.active === true || category.id === this.activity.categoryId)
          );

          return categories.sort((a, b) => {
            return (b.order != null) - (a.order != null) || a.order - b.order;
          });
        }

        const categories = this.categories.filter(
          (category) =>
            category.typeId === this.activity.typeId &&
            (category.active === true || category.id === this.activity.categoryId)
        );

        if (categories.length === 1) {
          this.activity.categoryId = categories[0].id;
        }

        return _.sortBy(categories, "name");
      },
      async postActivity() {
        if (!this.saving) {
          if (this.activity.body?.includes("[::") || this.activity.body?.includes("::]")) {
            this.showErrorNotification(
              "It seems like you have placeholders in the activity body. Please remove them before saving the activity."
            );

            return;
          }

          this.saving = true;
          const loggedAt = `${this.activity.fromDateVal} ${this.activity.time}`;

          this.activity.loggedAt = dayjs(loggedAt).utc().format("YYYY-MM-DD HH:mm:ssZ");
          const formData = new FormData();

          if (!this.activity.id) {
            delete this.activity.id;
          }
          Object.keys(this.activity).forEach((key) => {
            formData.append(key, this.activity[key]);
          });

          let i = 0;

          for (i; i < this.activity.attachments.length; i++) {
            const file = this.activity.attachments[i];
            const labelName = this.activity.labels[file.name];

            formData.append(`label[${i}]`, labelName || file.name);
            formData.append(`attachments[${i}]`, file);
          }

          if (this.currentFile) {
            formData.append(`label[${i}]`, this.coopScreenshotCurrentVersionLabel);
            formData.append(`attachments[${i}]`, this.currentFile);
            i += 1;

            if (this.previousFile) {
              formData.append(`label[${i}]`, this.coopScreenshotPreviousVersionLabel);
              formData.append(`attachments[${i}]`, this.previousFile);
            }
          }
          if (this.activity.id) {
            await ClientActivityResource.updateActivity(formData)
              .then(() => {
                this.showSuccessNotification("Client activity has been successfully updated.");

                this.closeDialog();
              })
              .catch(() => {
                this.showErrorNotification();
              })
              .finally(async () => {
                this.finalPost();
              });
          } else {
            await ClientActivityResource.createActivity(formData)
              .then(() => {
                this.showSuccessNotification("Client activity has been successfully created.");
                this.dialog = false;
                this.resetModal();
              })
              .catch(() => {
                this.showErrorNotification();
              })
              .finally(async () => {
                this.finalPost();
              });
          }
        }
      },
      finalPost() {
        this.deleteDraft();
        EventBus.$emit("refresh-profile-activities");
        this.saving = false;
        this.coopScreenshots = false;
      },
      resetModal() {
        this.activity = { ...this.defaultItem };
        this.resetCoopDeliverableFileFields();
      },
      openDialog() {
        this.dialog = true;
        this.location = "header";
      },
      openDeleteDialog() {
        if (this.draft) {
          this.deleteDialog = true;
        } else {
          this.closeDialog();
        }
      },
      closeDialog() {
        this.deleteDialog = false;
        this.dialog = false;
        this.resetModal();
        this.deleteDraft();
        this.$emit("close");
      },
      async getAssociatedStores(id) {
        this.associatedStores = await ClientResource.getManyClients(
          {
            clientsArray: [],
            groupId: id,
            scope: "active",
          },
          {
            withGroup: true,
            withFields: ["id", "name", "slug"],
          }
        ).then((res) => res.data);
      },
      resetCoopDeliverableFileFields() {
        this.currentFile = null;
        this.previousFile = null;
      },
      saveDraft: _.debounce(async function saveDraft() {
        await this.saveClientActivityDraft();
      }, 5000),
      async registerEvents() {
        return new Promise((resolve) => {
          EventBus.$on("show-activity-logger", (activity) => {
            activity.clientId = activity.client.id;
            activity.groupId = activity.client.Group?.id;

            if (activity.clientId !== this.activity.clientId) {
              this.activity = activity.activity
                ? { ...activity.activity, labels: {} }
                : { ...this.defaultItem, clientId: activity.clientId };
            } else if (activity.activity) {
              this.coopScreenshotCurrentDisabled = activity.coopScreenshotCurrentDisabled;
              this.coopScreenshotPreviousDisabled = activity.coopScreenshotPreviousDisabled;
              this.activity = { ...activity.activity, labels: {} };
            }

            this.industryId = activity.client?.industryId;

            if (activity.groupId) {
              this.getAssociatedStores(activity.groupId);
            }

            this.location = activity.location;
            this.dialog = true;
          });
          resolve();
        });
      },
      unloadEvents() {
        EventBus.$off("show-activity-logger");
      },
      showActivityFlowModal() {
        this.activityFlowModal = true;
      },
      closeActivityModal() {
        this.activityFlowModal = false;
      },
      updateActivity(body, attachments = null) {
        this.activity.body = body;

        if (attachments && attachments.length > 0) {
          attachments.forEach((attachment) => {
            this.activity.attachments.push(attachment);
          });
        }
      },
    },
  };
</script>
