import axios from "axios";
import axiosRetry from "axios-retry";

import axiosRetryConfig from "../../config/axios-retry";

axiosRetry(axios, axiosRetryConfig);

export default {
  getUserKey(key) {
    return axios.post(`${process.env.VUE_APP_TASK_IQ_PROXY}/login/token?token=${key}`);
  },
  createTask(token, task) {
    return axios.post(
      `${process.env.VUE_APP_TASK_IQ_PROXY}/v1/tasks`,
      { ...task },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },
  createTasks(token, tasks, clientId, reporterId) {
    return axios.post(
      `${process.env.VUE_APP_TASK_IQ_PROXY}/tasks/bulk-create`,
      { tasks, clientId, reporterId },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },
  getUserTask(key, userId = null) {
    return axios.get(
      `${process.env.VUE_APP_TASK_IQ_PROXY}/v1/users/tasks?user_id=${userId || ""}`,
      { headers: { Authorization: `Bearer ${key}` } }
    );
  },
  getTaskStatuses(token, taskIds) {
    return axios.post(
      `${process.env.VUE_APP_TASK_IQ_PROXY}/v1/tasks/task-statuses`,
      { task_ids: taskIds },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },
  getTasks(token, filters) {
    return axios.get(`${process.env.VUE_APP_TASK_IQ_PROXY}/v1/tasks/`, {
      params: { ...filters },
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getDepartmentsWithTypes() {
    return axios.get(`${process.env.VUE_APP_TASK_IQ_PROXY}/departments-with-types`);
  },
  updateTaskAssignee(taskId, assignee, token) {
    return axios.patch(
      `${process.env.VUE_APP_TASK_IQ_PROXY}/v1/tasks/${taskId}/assignee`,
      { assignee },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },
  getAllTaskStatusOptions(token) {
    return axios.get(`${process.env.VUE_APP_TASK_IQ_PROXY}/v1/tasks/statuses`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getAllDepartmentTemplates(token) {
    return axios.get(`${process.env.VUE_APP_TASK_IQ_PROXY}/v1/tasks/departments-templates`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getTaskTemplateFields(token, taskTypeId) {
    return axios.get(
      `${process.env.VUE_APP_TASK_IQ_PROXY}/v1/tasks/task-templates/${taskTypeId}/fields`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
  },
  getUserTaskTemplate(token) {
    return axios.get(`${process.env.VUE_APP_TASK_IQ_PROXY}/v1/users/task-templates`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getUserTaskIQActivities(params, token) {
    return axios.get(`${process.env.VUE_APP_TASK_IQ_PROXY}/v1/users/activities`, {
      params: { ...params },
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
