export default [
  {
    path: "/users",
    name: "users",
    component: () =>
      import(
        /* webpackChunkName: "users" */
        "../../../views/pages/users/index/Users.vue"
      ),
    meta: {
      title: "Users",
      requiresAuth: true,
      searchable: true,
      searchGroup: "Users",
    },
  },
  {
    path: "/users/:id/edit",
    props: true,
    name: "profiles.edit",
    component: () =>
      import(
        /* webpackChunkName: "profile.edit" */
        "../../../views/pages/profile/MyProfilePage.vue"
      ),
    meta: {
      title: "Edit Profile",
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(
        /* webpackChunkName: "profile" */
        "../../../views/pages/profile/MyProfilePage.vue"
      ),
    meta: {
      title: "My Profile",
      requiresAuth: true,
      clientAccessible: true,
    },
  },
  {
    path: "/users/:id/client-assign",
    name: "client-assign",
    component: () =>
      import(
        /* webpackChunkName: "client-assign" */
        "../../../views/pages/users/client-assign/ClientAssign.vue"
      ),
    meta: {
      title: "Edit Client Team",
      requiresAuth: true,
    },
  },
];
