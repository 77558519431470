export default [
  {
    path: "/settings",
    component: () =>
      import(
        /* webpackChunkName: "settings" */
        "../../../views/pages/settings/SettingsPage.vue"
      ),
    children: [
      {
        path: "accounting",
        name: "settings.accounting",
        component: () =>
          import(
            /* webpackChunkName: "settings.accounting" */
            "../../../views/pages/settings/accounting/AccountingSettingsIndex.vue"
          ),
        meta: {
          title: "Services",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Settings",
        },
      },
      {
        path: "activity-types",
        name: "settings.activity-types",
        component: () =>
          import(
            /* webpackChunkName: "settings.activity-types" */
            "../../../views/pages/settings/activity-types/index/ActivityTypesIndexPage.vue"
          ),
        meta: {
          title: "Activity Types",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Settings",
        },
      },
      {
        path: "cancellation-reasons",
        name: "settings.cancellation-reasons",
        component: () =>
          import(
            /* webpackChunkName: "settings.activity-types" */
            "../../../views/pages/settings/cancellation-reasons/index/CancellationReasonsIndex.vue"
          ),
        meta: {
          title: "Cancellation Reasons",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Settings",
          requiredRoles: ["Super Administrator", "Administrator", "Developer"],
        },
      },
      {
        path: "industries",
        component: () =>
          import(
            /* webpackChunkName: "industries" */
            "../../../views/pages/industries/IndustriesPage.vue"
          ),
        children: [
          {
            path: "",
            name: "industries.index",
            component: () =>
              import(
                /* webpackChunkName: "industries.index" */
                "../../../views/pages/industries/index/IndustriesIndexPage.vue"
              ),
            meta: {
              title: "Industries",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
            },
          },
        ],
      },
      {
        path: "manufacturers",
        component: () =>
          import(
            /* webpackChunkName: "manufacturers" */
            "../../../views/pages/manufacturers/ManufacturersPage.vue"
          ),
        children: [
          {
            path: "",
            name: "manufacturers.index",
            component: () =>
              import(
                /* webpackChunkName: "manufacturers.index" */
                "../../../views/pages/manufacturers/index/ManufacturersIndexPage.vue"
              ),
            meta: {
              title: "Manufacturers",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
            },
          },
        ],
      },
      {
        path: "metric-alarms",
        component: () => import("../../../views/pages/settings/metric-alarms/MetricAlarmsPage.vue"),
        children: [
          {
            path: "",
            name: "metric_alarms.index",
            component: () =>
              import("../../../views/pages/settings/metric-alarms/index/MetricAlarmsIndex.vue"),
            meta: {
              title: "Metric Alarms",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
              requiredRoles: ["Super Administrator", "Administrator", "Developer"],
            },
          },
        ],
      },
      {
        path: "partners",
        component: () =>
          import(
            /* webpackChunkName: "partners" */
            "../../../views/pages/partners/PartnersPage.vue"
          ),
        children: [
          {
            path: "",
            name: "partners.index",
            component: () =>
              import(
                /* webpackChunkName: "partners.index" */
                "../../../views/pages/partners/index/PartnersIndexPage.vue"
              ),
            meta: {
              title: "Partners",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
            },
          },
          {
            path: ":partnerId/commission-report",
            name: "commissionReport.index",
            component: () =>
              import(
                /* webpackChunkName: "commissionReport.index" */
                "../../../views/pages/partners/commission_report/CommissionReportPage.vue"
              ),
            meta: {
              title: "Commission Report",
              requiresAuth: true,
              searchable: false,
            },
          },
        ],
      },
      {
        path: "website-providers",
        name: "website_provider.index",
        component: () =>
          import(
            /* webpackChunkName: "website_provider.index" */
            "../../../views/pages/website-providers/WebsiteProvidersIndexPage.vue"
          ),
        meta: {
          title: "Website Providers",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Settings",
        },
      },
      {
        path: "profit-centers",
        component: () =>
          import("../../../views/pages/settings/profit-centers/ProfitCenterPage.vue"),
        children: [
          {
            path: "",
            name: "profitCenters.index",
            component: () =>
              import("../../../views/pages/settings/profit-centers/index/ProfitCenterIndex.vue"),
            meta: {
              title: "Profit Centers",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
            },
          },
        ],
      },
      {
        path: "carousel-upload",
        component: () =>
          import("../../../views/pages/settings/carousel-upload/CarouselUploadPage.vue"),
        children: [
          {
            path: "",
            name: "carousel-upload.index",
            component: () =>
              import("../../../views/pages/settings/carousel-upload/index/CarouselUploadIndex.vue"),
            meta: {
              title: "Carousel Upload",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
            },
          },
        ],
      },
      {
        path: "two-factor-authentication",
        component: () =>
          import(
            "../../../views/pages/settings/two-factor-requirements/TwoFactorRequirementsPage.vue"
          ),
        children: [
          {
            path: "",
            name: "two-factor-authentication.index",
            component: () =>
              import(
                "../../../views/pages/settings/two-factor-requirements/index/TwoFactorRequirementsIndex.vue"
              ),
            meta: {
              title: "Two-Factor Authentication",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
              requiredRoles: ["Super Administrator", "Administrator", "Developer"],
            },
          },
        ],
      },
      {
        path: "departments",
        component: () => import("../../../views/pages/settings/departments/DepartmentsPage.vue"),
        children: [
          {
            path: "",
            name: "departments.index",
            component: () =>
              import("../../../views/pages/settings/departments/index/DepartmentsIndex.vue"),
            meta: {
              title: "Departments",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
            },
          },
          {
            path: "/:id/chart",
            name: "departments.chart",
            component: () =>
              import("../../../views/pages/settings/departments/DepartmentChartPage.vue"),
            meta: {
              title: "Department Chart",
              requiresAuth: true,
            },
          },
          {
            path: "user-types",
            name: "departments.user_types",
            component: () =>
              import(
                "../../../views/pages/settings/departments/user-types/DepartmentUserTypesIndex.vue"
              ),
            meta: {
              title: "Department User Types",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
            },
          },
          {
            path: "user-abilities",
            name: "departments.user_abilities",
            component: () =>
              import(
                "../../../views/pages/settings/departments/user-abilities/DepartmentUserAbilities.vue"
              ),
            meta: {
              title: "Department User Abilities",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
            },
          },
        ],
      },
      {
        path: "push-reporting",
        component: () =>
          import("../../../views/pages/settings/push-reporting/PushReportingPage.vue"),
        children: [
          {
            path: "",
            name: "push-reporting.index",
            component: () =>
              import("../../../views/pages/settings/push-reporting/index/PushReportingIndex.vue"),
            meta: {
              title: "Push Reporting Settings",
              requiresAuth: true,
              requiredRoles: ["Super Administrator"],
            },
          },
        ],
      },
      {
        path: "groups",
        component: () => import("../../../views/pages/settings/groups/ClientGroupsPage.vue"),
        children: [
          {
            path: "",
            name: "groups.index",
            component: () =>
              import("../../../views/pages/settings/groups/index/ClientGroupsIndex.vue"),
            meta: {
              title: "Groups",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
            },
          },
        ],
      },
      {
        path: "coop",
        component: () => import("../../../views/pages/settings/coop/CoopPage.vue"),
        children: [
          {
            path: "/documents",
            name: "coop.documents",
            component: () =>
              import("../../../views/pages/settings/coop/documents/CoopDocumentsPage.vue"),
            meta: {
              title: "Co-Op Documents",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
              requiredRoles: ["Super Administrator", "Administrator"],
            },
          },
        ],
      },
      {
        path: "iam",
        name: "permission-role.index",
        component: () =>
          import(
            /* webpackChunkName: "permission-role.index" */
            "../../../views/pages/settings/permission-role/PermissionRoleIndex.vue"
          ),
        meta: {
          title: "Identity & Access Management",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Settings",
          requiredRoles: ["Super Administrator", "Administrator"],
        },
      },
      {
        path: "custom_fields",
        component: () => import("../../../views/pages/settings/custom-fields/CustomFieldsPage.vue"),
        children: [
          {
            path: "",
            name: "custom_fields.index",
            component: () =>
              import("../../../views/pages/settings/custom-fields/index/CustomFieldsIndex.vue"),
            meta: {
              title: "Interaction Custom Fields",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
              requiredRoles: ["Super Administrator", "Administrator", "Developer"],
            },
          },
        ],
      },
      {
        path: "interaction_types",
        component: () =>
          import("../../../views/pages/settings/interaction-types/InteractionTypesPage.vue"),
        children: [
          {
            path: "",
            name: "interaction_types.index",
            component: () =>
              import(
                "../../../views/pages/settings/interaction-types/index/InteractionTypesIndex.vue"
              ),
            meta: {
              title: "Interaction Types",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
              requiredRoles: ["Super Administrator", "Administrator", "Developer"],
            },
          },
        ],
      },
      {
        path: "regions",
        component: () => import("../../../views/pages/settings/regions/RegionPage.vue"),
        children: [
          {
            path: "",
            name: "regions.index",
            component: () => import("../../../views/pages/settings/regions/index/RegionIndex.vue"),
            meta: {
              title: "Region Settings",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
              requiredRoles: ["Super Administrator", "Administrator", "Developer"],
            },
          },
        ],
      },
      {
        path: "overwatch-alarms",
        component: () =>
          import("../../../views/pages/settings/overwatch-alarms/OverwatchAlarmsPage.vue"),
        children: [
          {
            path: "",
            name: "overwatch_alarms.index",
            component: () =>
              import(
                "../../../views/pages/settings/overwatch-alarms/index/OverwatchAlarmsIndex.vue"
              ),
            meta: {
              title: "Overwatch Alarms",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
              requiredRoles: ["Super Administrator", "Administrator", "Developer"],
            },
          },
        ],
      },
      {
        path: "tactics",
        component: () => import("../../../views/pages/settings/tactics/TacticsPage.vue"),
        children: [
          {
            path: "",
            name: "tactics.index",
            component: () => import("../../../views/pages/settings/tactics/index/TacticsIndex.vue"),
            meta: {
              title: "Tactics",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
              requiredRoles: ["Super Administrator", "Administrator", "Developer"],
            },
          },
        ],
      },
      {
        path: "seo-strategy-settings",
        name: "settings.seo-deliverables",
        component: () =>
          import("../../../views/pages/settings/seo-strategy-config/SeoStrategyConfigIndex.vue"),
        meta: {
          title: "SEO Strategy Settings",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Settings",
          requiredRoles: ["Super Administrator", "Administrator", "Director"],
        },
      },
      {
        path: "spend_data_sources",
        name: "spend_data_sources.index",
        component: () =>
          import("../../../views/pages/settings/spend-data-sources/SpendDataSourcesPage.vue"),
        meta: {
          title: "Spend Data Sources",
          requiresAuth: true,
          searchable: false,
          searchGroup: "Settings",
          requiredRoles: ["Developer"],
        },
      },
      {
        path: "client-tags-settings",
        name: "settings.client-tags",
        component: () => import("../../../views/pages/settings/client-tags/ClientTagsIndex.vue"),
        meta: {
          title: "Client Tags",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Settings",
          requiredRoles: ["Super Administrator", "Administrator"],
        },
      },
    ],
  },
];
