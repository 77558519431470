export default {
  methods: {
    showSuccessNotification(
      message = "Your request is successfully processed.",
      icon = "check_circle",
      options = {}
    ) {
      return this.$toast.success(message, {
        iconClass: "material-icons",
        iconChildren: icon,
        showCloseButtonOnHover: true,
        ...options,
      });
    },
    showErrorNotification(
      message = "There was an error processing your request. Please refresh this page and try again.",
      icon = "error",
      options = {}
    ) {
      return this.$toast.error(message, {
        iconClass: "material-icons",
        iconChildren: icon,
        showCloseButtonOnHover: true,
        ...options,
      });
    },
    showWarningNotification(message, icon = "announcement", options = {}) {
      return this.$toast.warning(message, {
        iconClass: "material-icons",
        iconChildren: icon,
        showCloseButtonOnHover: true,
        ...options,
      });
    },
    showInfoNotification(message, icon = "announcement", options = {}) {
      return this.$toast.info(message, {
        iconClass: "material-icons",
        iconChildren: icon,
        showCloseButtonOnHover: true,
        ...options,
      });
    },
    showErrorNotificationFromError(errors, options = {}) {
      let messages;

      if ([400, 500].includes(errors.response?.status) && errors.response?.data) {
        messages = errors.response?.data;
      } else {
        messages =
          "There was an error processing your request. Please refresh this page and try again.";
      }

      return this.$toast.error(messages, {
        iconClass: "material-icons",
        iconChildren: "error",
        showCloseButtonOnHover: true,
        ...options,
      });
    },
  },
};
