<template>
  <div>
    <v-divider dark class="ma-6"></v-divider>

    <div class="flex justify-between align-center">
      <h4 class="text-h4 font-weight-light">Tasks</h4>
      <div>
        <v-btn icon @click="this.getTask">
          <v-icon small>fas fa-sync-alt</v-icon>
        </v-btn>
        <v-btn icon @click="this.goToTaskIq">
          <v-icon small>fas fa-external-link-alt</v-icon>
        </v-btn>
      </div>
    </div>
    <div class="mt-5" v-if="error">Unable to connect to TaskIQ</div>
    <div v-else-if="tasks.length > 0" class="space-y-5">
      <task-list-item
        v-for="task in tasks"
        :key="task.id"
        :title="task.title"
        :client="task.client.name"
        :priority="task.priority.name"
        :due="task.due_date"
        :id="task.id"
      />
    </div>
    <div class="mt-5" v-else>You currently have 0 tasks.</div>
  </div>
</template>

<script>
  import TaskIQResource from "../../../../../api/resources/TaskIQResource";
  import TaskListItem from "./TaskListItem.vue";

  export default {
    name: "TaskList",
    components: { TaskListItem },
    data() {
      return {
        tasks: [],
        error: false,
      };
    },
    computed: {
      taskCount() {
        return this.tasks.length;
      },
      taskIqUrl() {
        return process.env.VUE_APP_TASK_IQ_CLIENT_PROXY;
      },
    },
    created() {
      this.getTask();
    },
    methods: {
      async getTask() {
        const currentUser = this.$store.getters["authorization/currentUser"];

        let tasks = await TaskIQResource.getUserTask(currentUser.kobiRefreshToken)
          .then((res) => res.data)
          .catch(() => {
            this.error = true;
          });

        if (tasks && tasks.length > 3) {
          tasks = tasks.slice(0, 3);
        }

        this.tasks = tasks;
      },
      goToTaskIq() {
        window.open(
          `${this.taskIqUrl}/auth/token/${this.$store.getters["authorization/currentUser"].kobiKey}`
        );
      },
    },
  };
</script>
