export const ignoredErrors = [
  /ResizeObserver loop limit exceeded/im,
  /Failed to fetch dynamically imported module/im,
  /Importing a module script failed/im,
  /loading chunk/im,
  /Loading CSS chunk/im,
  /Request failed with status code 403/im,
  /CanceledError/im,
];

export default {};
