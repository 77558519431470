<template>
  <v-card class="min-h-[400px]">
    <v-card-title class="flex justify-end">
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="w-full min-h-[50vh] px-12 pb-6">
        <v-fade-transition group hide-on-leave>
          <div v-if="currentStep === 'step1'" key="step-step1">
            <div>
              <h2>Were There Any Changes?</h2>

              <p class="my-8">
                Any strategy changes or new campaign launches, large budgets shifts, etc...?
              </p>

              <div class="flex flex-row space-x-3 mt-12">
                <v-btn x-large color="success" @click="goToStep1Yes">Yes</v-btn>
                <v-btn x-large color="error" @click="goToStep1No">No</v-btn>
              </div>
            </div>
          </div>
          <div v-else-if="currentStep === 'step1-yes'" key="step-step1-yes">
            <h3>Select one or more of the following...</h3>
            <div class="mt-8">
              <v-checkbox
                v-for="option in changeOptions"
                v-model="answers.changes"
                :label="option.label"
                :value="option.value"
                :key="option.label"
                hide-details
              />
            </div>
            <v-row class="mt-12">
              <v-btn @click="currentStep = 'step1'" color="secondary">Back</v-btn>
              <v-spacer />
              <v-btn @click="switchToStep1" color="primary" :disabled="answers.changes.length < 1">
                Next
              </v-btn>
            </v-row>
          </div>
          <div v-else-if="currentStep === 'step1-yes-details'" key="step-step1-yes-details">
            <h3>Provide details for each section(s):</h3>

            <div v-for="change in answers.changes" :key="change" class="mt-8">
              <h6>{{ getLabel(changeOptions, change) }}</h6>

              <div class="p-3">
                <v-textarea
                  v-model="answers.changesData[change].text"
                  :label="`Please provide details for ${getLabel(changeOptions, change)}`"
                  filled
                  rows="3"
                />
                <v-text-field
                  v-if="canAddImage(change)"
                  v-model="answers.changesData[change].imageUrl"
                  :label="`Image Url for ${getLabel(changeOptions, change)}`"
                  filled
                />
                <v-file-input
                  v-if="canAddImage(change)"
                  v-model="answers.changesData[change].imageFile"
                  :label="`Attachments for ${getLabel(changeOptions, change)}`"
                  multiple
                  :rules="filesShouldBeLessThanMBRule(10)"
                  small-chips
                  show-size
                  counter
                  prepend-icon=""
                  prepend-inner-icon="attachment"
                  filled
                />
              </div>

              <v-divider />
            </div>
            <v-row class="mt-12">
              <v-btn @click="currentStep = 'step1-yes'" color="secondary">Back</v-btn>
              <v-spacer />
              <v-btn @click="submitFinalData" outlined color="success" class="mr-2">Done</v-btn>
              <v-btn @click="currentStep = 'step1-no'" color="primary">Next</v-btn>
            </v-row>
          </div>
          <div v-else-if="currentStep === 'step1-no'" key="step-step1-no">
            <h3>Select a time frame...</h3>
            <p>We will get the client metrics for the selected time frame.</p>
            <div class="mt-8 w-full flex">
              <div class="w-1/2">
                <v-date-picker v-model="answers.dateRange" type="date" range />
              </div>
              <div class="w-1/2">
                <v-list>
                  <v-subheader>Presets</v-subheader>
                  <v-list-item-group class="">
                    <v-list-item
                      v-for="preset in dateRangesOptions"
                      :key="preset.label"
                      @click="setDate(preset)"
                    >
                      <v-list-item-content>
                        {{ preset.label }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

                <div class="" v-if="answers.dateRange?.length > 1">
                  <p class="mt-4">
                    <strong>Selected Date Range:</strong>
                    <br />
                    {{ formatDate(answers.dateRange[0], "MM/DD/YYYY") }} -
                    {{ formatDate(answers.dateRange[1], "MM/DD/YYYY") }}
                  </p>
                </div>
              </div>
            </div>
            <v-row class="mt-12">
              <v-btn @click="goToStep1" color="secondary">Back</v-btn>
              <v-spacer />
              <v-btn
                @click="getCampaignData"
                color="primary"
                :disabled="answers.dateRange?.length !== 2"
              >
                Next
              </v-btn>
            </v-row>
          </div>
          <div v-else-if="currentStep === 'step2-loading'" key="step-step2-loading">
            <h3>Please wait while we are getting the client metrics...</h3>
            <v-progress-linear indeterminate color="primary" class="mt-8" />
          </div>
          <div v-else-if="currentStep === 'step2'" key="step-step2">
            <h3>Select any options to continue...</h3>
            <div class="mt-8" v-if="metricData">
              <div class="box">
                <div class="w-1/12">
                  <v-checkbox v-model="answers.includeLeadVolume" hide-details />
                </div>

                <div class="w-10/12">
                  <h5>Lead Volume</h5>
                  <p class="mt-8">
                    <v-textarea v-model="answers.leadVolumeText" filled rows="4" hide-details />
                  </p>
                </div>
              </div>

              <div class="box mt-6">
                <div class="w-1/12">
                  <v-checkbox v-model="answers.includeCostPerLead" hide-details />
                </div>

                <div class="w-10/12">
                  <h5>Cost Per Lead</h5>
                  <p class="mt-8">
                    <v-textarea v-model="answers.costPerLeadText" filled rows="4" hide-details />
                  </p>
                </div>
              </div>
            </div>
            <v-row class="mt-12">
              <v-btn @click="currentStep = 'step1-no'" color="secondary">Back</v-btn>
              <v-spacer />
              <v-btn @click="moveToStep3" color="primary">Next</v-btn>
            </v-row>
          </div>
          <div v-else-if="currentStep === 'step2-optimizations'" key="step-step2-optimizations">
            <h3>Optimizations</h3>
            <p>Select one or more optimizations that were made to reduce the cost per lead.</p>
            <div class="mt-8">
              <v-checkbox
                v-for="option in optimizationOptions"
                v-model="answers.optimizations"
                :label="option.label"
                :value="option.value"
                :key="option.label"
                hide-details
              />
            </div>
            <v-row class="mt-12">
              <v-btn @click="currentStep = 'step2'" color="secondary">Back</v-btn>
              <v-spacer />
              <v-btn @click="currentStep = 'step3'" color="primary">Next</v-btn>
            </v-row>
          </div>
          <div v-else-if="currentStep === 'step3'" key="step-step3">
            <h3>Any Other Issues?</h3>
            <div class="mt-8">
              <div v-for="option in otherIssues" :key="option.label">
                <v-checkbox
                  v-model="option.value"
                  :label="option.label"
                  :value="option.value"
                  hide-details
                />
                <v-textarea
                  class="mt-3"
                  v-if="option.value"
                  rows="3"
                  v-model="option.text"
                  :label="'Provide details for ' + option.label"
                  filled
                  hide-details
                />
              </div>
            </div>
            <v-row class="mt-12">
              <v-btn @click="currentStep = 'step2'" color="secondary">Back</v-btn>
              <v-spacer />
              <v-btn @click="submitFinalData" color="primary">Done</v-btn>
            </v-row>
          </div>
        </v-fade-transition>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import dayjs from "dayjs";

  import ClientResource from "@/api/resources/ClientResource";
  import DashboardResource from "@/api/resources/DashboardResource";

  export default {
    name: "SEMActivityFlow",
    props: ["clientId"],
    data() {
      return {
        currentStep: "step1",
        loading: false,
        client: null,
        metricData: null,
        answers: {
          changes: [],
          changesData: {},
          dateRange: null,
          dateRangeLabel: null,
          includeLeadVolume: false,
          leadVolumeText: "",
          includeCostPerLead: false,
          costPerLeadText: "",
          costPerLeadIncreased: false,
          optimizations: [],
        },
        changeOptions: [
          {
            label: "Added Incentives",
            value: "added-incentives",
          },
          {
            label: "Bid Strategy Change",
            value: "big-strategy-change",
          },
          {
            label: "Budget Change",
            value: "budget-change",
          },
          {
            label: "Geo Targeting Change",
            value: "geo-targeting-change",
          },
          {
            label: "Keyword Implementation Change",
            value: "keyword-implementation-change",
          },
          {
            label: "New Campaign Launch",
            value: "new-campaign-launch",
          },
          {
            label: "New Strategy Change",
            value: "new-strategy-change",
          },
          {
            label: "Updated Assets (Creative Refresh)",
            value: "updated-assets",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
        optimizationOptions: [
          {
            label: "Account Optimization",
            value: "Account Optimization",
          },
          {
            label: "Ad Copy Split Testing",
            value: "Ad Copy Split Testing",
          },
          {
            label: "Bid Strategy",
            value: "Bid Strategy",
          },
          {
            label: "Campaign Launch",
            value: "Campaign Launch",
          },
          {
            label: "Clicks & Conversions by Hour",
            value: "Clicks & Conversions by Hour",
          },
          {
            label: "Creative Refresh",
            value: "Creative Refresh",
          },
          {
            label:
              "Geo Targeting Change (if selected, text box to input screenshot URL or upload image)",
            value: "Geo Targeting Change",
          },
          {
            label: "Heavy-up Higher Converting Days",
            value: "Heavy-up Higher Converting Days",
          },
          {
            label: "Keyword Optimization",
            value: "Keyword Optimization",
          },
        ],
        change: null,
        otherIssues: [
          {
            label: "Increase in Vehicles (e.g. twice as many)",
            text: "",
            value: false,
          },
          {
            label: "Feed Issue",
            text: "",
            value: false,
          },
          {
            label: "New Competitor",
            text: "",
            value: false,
          },
          {
            label: "Pricing",
            text: "",
            value: false,
          },
          {
            label: "Underpacing",
            text: "",
            value: false,
          },
        ],
      };
    },
    mounted() {
      this.currentStep = "step1";

      this.getClient();
    },
    computed: {
      dateRangesOptions() {
        return [
          {
            label: "Last 7 Days",
            startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
            endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          },
          {
            label: "Month to Date",
            startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
            endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          },
          {
            label: "Last 30 Days",
            startDate: dayjs().subtract(29, "day").format("YYYY-MM-DD"),
            endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          },
          {
            label: "Last 60 Days",
            startDate: dayjs().subtract(59, "month").startOf("month").format("YYYY-MM-DD"),
            endDate: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
          },
          {
            label: "Last 90 Days",
            startDate: dayjs().subtract(89, "month").startOf("month").format("YYYY-MM-DD"),
            endDate: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
          },
        ];
      },
    },
    methods: {
      goToStep1() {
        this.currentStep = this.answers.changes?.length > 0 ? "step1-yes-details" : "step1";
      },
      goToStep1Yes() {
        this.currentStep = "step1-yes";
      },
      goToStep1No() {
        this.currentStep = "step1-no";
        this.answers.changes = [];
      },
      initializeChangeData() {
        this.answers.changes.forEach((change) => {
          this.$set(this.answers.changesData, change, {
            text: null,
          });

          if (this.canAddImage(change)) {
            this.$set(this.answers.changesData, change, {
              imageUrl: null,
              imageFile: null,
            });
          }
        });
      },
      canAddImage(change) {
        return (
          change === "added-incentives" ||
          change === "geo-targeting-change" ||
          change === "updated-assets"
        );
      },
      close() {
        this.$emit("close");
      },
      async getClient() {
        ClientResource.getClient(this.clientId, {
          withProfiles: true,
          withFields: ["id", "tenantId", "name", "slug"],
        }).then((res) => {
          this.client = res.data;
        });
      },
      getLabel(options, value) {
        return options.find((option) => option.value === value)?.label || "";
      },
      setDate(preset) {
        this.answers.dateRange = [preset.startDate, preset.endDate];
      },
      getPresetLabel(startDate, endDate) {
        return (
          this.dateRangesOptions.find(
            (option) => option.startDate === startDate && option.endDate === endDate
          )?.label || null
        );
      },
      async getCampaignData() {
        this.currentStep = "step2-loading";

        if (!this.client) {
          await this.getClient();
        }

        const requestData = [
          {
            id: this.client.id,
            profiles: this.client.profiles.map((profile) => {
              const { identifier } = profile;

              return { name: profile.ProfileType.name, identifier };
            }),
          },
        ];

        await DashboardResource.getSemDashboard(
          requestData,
          this.answers.dateRange[0],
          this.answers.dateRange[1],
          true
        ).then((res) => {
          this.metricData = res.data && res.data.length > 0 ? res.data[0].data : null;

          const currentLeads = this.metricData?.current_period?.conversions || 0;
          const previousLeads = this.metricData?.previous_period?.conversions || 0;

          const dateRangeLabel =
            this.getPresetLabel(this.answers.dateRange[0], this.answers.dateRange[1]) ||
            "previous period";

          if (currentLeads > previousLeads) {
            this.answers.leadVolumeText = `Comparing the ${dateRangeLabel.toLowerCase()}, the lead volume has increased by ${(
              currentLeads - previousLeads
            ).toFixed(0)} leads. (Previous: ${previousLeads.toFixed(
              0
            )}, Current: ${currentLeads.toFixed(0)})`;
          } else if (currentLeads < previousLeads) {
            this.answers.leadVolumeText = `Comparing the ${dateRangeLabel.toLowerCase()}, the lead volume has decreased by ${(
              previousLeads - currentLeads
            ).toFixed(0)} leads. (Previous: ${previousLeads.toFixed(
              0
            )}, Current: ${currentLeads.toFixed(0)})`;
          } else {
            this.answers.leadVolumeText = `The lead volume remained the same.`;
          }

          const currentCostPerLead = this.metricData?.current_period?.costPerConversion || 0;
          const previousCostPerLead = this.metricData?.previous_period?.costPerConversion || 0;

          if (currentCostPerLead > previousCostPerLead) {
            this.answers.costPerLeadText = `Cost per lead has increased by $${(
              currentCostPerLead - previousCostPerLead
            ).toFixed(2)}. (Previous: $${previousCostPerLead.toFixed(
              2
            )}, Current: $${currentCostPerLead.toFixed(2)})`;

            this.answers.costPerLeadIncreased = true;
          } else if (currentCostPerLead < previousCostPerLead) {
            this.answers.costPerLeadText = `Cost per lead has decreased by $${(
              previousCostPerLead - currentCostPerLead
            ).toFixed(2)}. (Previous: $${previousCostPerLead.toFixed(
              2
            )}, Current: $${currentCostPerLead.toFixed(2)})`;

            this.answers.costPerLeadIncreased = false;
          } else {
            this.answers.costPerLeadText = `Cost per lead remained the same.`;
          }
        });

        this.currentStep = "step2";
      },
      switchToStep1() {
        this.currentStep = "step1-yes-details";
        this.initializeChangeData();
      },
      moveToStep3() {
        if (this.answers.costPerLeadIncreased && this.answers.includeCostPerLead) {
          this.currentStep = "step2-optimizations";
        } else {
          this.currentStep = "step3";
        }
      },
      submitFinalData() {
        let content = "";

        this.answers.changes.forEach((key) => {
          content += `<strong>${this.getLabel(this.changeOptions, key)}</strong>`;
          content += `${
            key in this.answers.changesData
              ? `<p>${
                  this.answers.changesData[key].text || `[::Please add your comments here::]`
                }</p>`
              : "<p>[::Please add your comments here::]</p>"
          }`;
          const imageUrl = this.answers.changesData[key]?.imageUrl;

          if (imageUrl) {
            content += `<a target="_blank" href="${imageUrl}">${imageUrl}</a>`;
          }
          content += "<p></p>";
        });

        if (this.answers.includeLeadVolume) {
          content += "<strong>Lead Volume</strong>";
          content += `<p>${this.answers.leadVolumeText}</p>`;
          content += "<p></p>";
        }

        if (this.answers.includeCostPerLead) {
          content += "<strong>Cost Per Lead</strong>";
          content += `<p>${this.answers.costPerLeadText}</p>`;
          content += "<p></p>";

          if (this.answers.costPerLeadIncreased && this.answers.optimizations?.length > 0) {
            content += "<strong>Optimizations</strong><p></p>";
            this.answers.optimizations.forEach((key) => {
              content += `<strong>- ${key}</strong>`;
              content += "<p>[::Please add your comments here::]</p>";
            });
            content += "<p></p>";
          }
        }

        this.otherIssues.forEach((otherIssue) => {
          if (otherIssue.value) {
            content += `<strong>${otherIssue.label}</strong>`;
            content += `<p>${otherIssue.text || "[::Please add your comments here::]"}</p>`;
            content += "<p></p>";
          }
        });

        const attachments = [];

        Object.values(this.answers.changesData).forEach((change) => {
          if (change.imageFile) {
            attachments.push(...change.imageFile);
          }
        });

        this.$emit("update", content, attachments);

        this.close();
      },
    },
  };
</script>

<style scoped>
  .box {
    @apply p-6 rounded-lg border border-gray-200 shadow flex flex-row space-x-4;
  }
</style>
