<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col md="2" cols="4">
        <v-autocomplete
          clearable
          v-model="filter.serviceId"
          :items="services"
          item-value="id"
          item-text="name"
          label="Services"
        ></v-autocomplete>
      </v-col>
      <v-col md="2" cols="4">
        <v-autocomplete
          clearable
          v-model="filter.serviceGroup"
          :items="serviceGroups"
          item-value="id"
          item-text="name"
          label="Service Group"
        ></v-autocomplete>
      </v-col>
      <v-col md="2" cols="4">
        <v-autocomplete
          v-model="filter.month"
          :items="months"
          item-value="value"
          item-text="name"
          label="Month"
        ></v-autocomplete>
      </v-col>
      <v-col md="2" cols="8">
        <v-autocomplete
          v-model="filter.year"
          item-value="value"
          item-text="name"
          :items="years"
          label="Year"
        ></v-autocomplete>
      </v-col>
      <v-col sm="2" md="1" lg="1" xl="1" cols="4">
        <v-btn :disabled="!valid" small class="mt-4" color="success" @click="submit()">
          Search
        </v-btn>
      </v-col>

      <v-col sm="2" md="1" lg="1" xl="1" cols="4">
        <v-btn-toggle>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!valid"
                v-bind="attrs"
                v-on="on"
                small
                class="mt-4"
                color="info"
                @click="submit('back')"
              >
                <v-icon small>fas fa-arrow-left</v-icon>
              </v-btn>
            </template>
            <span>See Previous Month</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="!valid"
                v-bind="attrs"
                v-on="on"
                small
                class="mt-4"
                color="info"
                @click="submit('forward')"
              >
                <v-icon small>fas fa-arrow-right</v-icon>
              </v-btn>
            </template>
            <span>See Next Month</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
  import dayjs from "dayjs";

  import { EventBus } from "@/bootstrap/EventBus";

  import ServiceGroupResource from "../../../../../api/resources/accounting/ServiceGroupResource";
  import ServiceResource from "../../../../../api/resources/accounting/ServiceResource";

  export default {
    async created() {
      await this.getInitialData();

      if (this.$route.query) {
        const filterKeys = Object.keys(this.filter);

        Object.keys(this.$route.query).forEach((filter) => {
          if (filterKeys.includes(filter)) {
            let value = this.$route.query[filter];

            if (filter !== "month") {
              value = Number(value);
            }

            this.filter[filter] = value;
          }
        });
      }
    },
    data() {
      return {
        valid: true,
        serviceGroups: [{ id: null, name: "None" }],
        services: [{ id: null, name: "None" }],
        filter: {
          month: dayjs().format("MMMM"),
          year: dayjs().format("YYYY"),
          serviceId: null,
          serviceGroup: null,
        },
        months: [
          {
            name: "All Future Budgets",
            value: "future",
          },
          {
            name: "January",
            value: "January",
          },
          {
            name: "February",
            value: "February",
          },
          {
            name: "March",
            value: "March",
          },
          {
            name: "April",
            value: "April",
          },
          {
            name: "May",
            value: "May",
          },
          {
            name: "June",
            value: "June",
          },
          {
            name: "July",
            value: "July",
          },
          {
            name: "August",
            value: "August",
          },
          {
            name: "September",
            value: "September",
          },
          {
            name: "October",
            value: "October",
          },
          {
            name: "November",
            value: "November",
          },
          {
            name: "December",
            value: "December",
          },
        ],
      };
    },
    computed: {
      years() {
        const nextYear = dayjs().add(1, "year").format("YYYY");

        const years = [
          { name: "None", value: null },
          { name: nextYear, value: nextYear },
        ];

        for (let i = 1; i < 7; i++) {
          years.push({
            name: dayjs(nextYear).subtract(i, "years").format("YYYY"),
            value: dayjs(nextYear).subtract(i, "years").format("YYYY"),
          });
        }

        return years;
      },
    },
    methods: {
      async getInitialData() {
        await Promise.allSettled([this.getServiceGroups(), this.getServices()]);
      },

      async getServiceGroups() {
        this.serviceGroups = this.serviceGroups.concat(
          await ServiceGroupResource.getServiceGroupList(false).then((res) => res.data)
        );
      },
      async getServices() {
        this.services = this.services.concat(
          await ServiceResource.getAllServicesOnly().then((res) => res.data)
        );
      },
      submit(time) {
        if (time) {
          let date;

          if (this.filter.month && this.filter.year && this.filter.month !== "future") {
            date = dayjs(`${this.filter.month} ${this.filter.year}`);
          } else {
            date = dayjs().format();
          }
          if (time === "forward") {
            date = date.add(1, "month");
          } else {
            date = date.subtract(1, "month");
          }
          this.filter.month = dayjs(date).format("MMMM");
          this.filter.year = dayjs(date).format("YYYY");
        }
        if (this.$refs.form.validate()) {
          if (this.$router.query) {
            this.$router.replace({ query: {} });
          }

          EventBus.$emit("budget-filter", this.filter);
        }
      },
    },
  };
</script>
