<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-autocomplete
          v-if="filteredAssociatedStores.length > 0"
          filled
          :items="filteredAssociatedStores"
          item-text="name"
          multiple
          item-value="id"
          v-model="currentInteraction.associatedStoreIds"
          label="Related Stores"
          required
        ></v-autocomplete>

        <v-autocomplete
          v-if="isAdSupport && clientManagers.length > 0"
          filled
          :items="clientManagers"
          item-text="fullName"
          item-value="userId"
          v-model="currentInteraction.onBehalfOf"
          clearable
          label="On Behalf Of"
        ></v-autocomplete>

        <interaction-fields :interaction="currentInteraction" ref="interactionFields" />
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="currentInteraction.interactionType && isEdit"
        color="red darken-1"
        text
        @click="$emit('handleClose')"
      >
        Close
      </v-btn>
      <v-btn
        color="primary"
        v-if="currentInteraction.interactionType"
        :disabled="!isValid() || loading"
        @click="handleSubmit"
      >
        <div class="item-center">
          <v-progress-circular :size="20" indeterminate class="mr-2" v-if="loading" />
          <span>{{ isEdit ? "Update" : "Save" }}</span>
        </div>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  import dayjs from "dayjs";

  import ClientResource from "@/api/resources/ClientResource";
  import { EventBus } from "@/bootstrap/EventBus";

  import InteractionResource from "../../../../../api/resources/InteractionResource";
  import InteractionFields from "./InteractionFields.vue";

  export default {
    name: "InteractionForm",
    components: { InteractionFields },
    props: ["interaction", "clientUsers"],
    data() {
      return {
        loading: false,
        currentInteraction: {},
        associatedStores: [],
        clientManagers: [],
        isAdSupport: false,
      };
    },
    computed: {
      isEdit() {
        return !!this.currentInteraction.id;
      },
      filteredAssociatedStores() {
        return this.associatedStores.filter(
          (store) => store.id !== this.currentInteraction.client.id
        );
      },
    },
    watch: {
      clientUsers: {
        deep: true,
        immediate: true,
        handler(newValue) {
          const currentUserId = this.$store.getters["authorization/currentUser"].id;

          this.clientManagers = newValue
            .map((clientUser) => {
              clientUser.fullName = clientUser.user?.fullName || "N/A";

              return clientUser;
            })
            .filter((clientUser) => {
              if (clientUser.ClientUserTypeId === 2 || clientUser.ClientUserTypeId === 1) {
                if (clientUser.userId === currentUserId && clientUser.ClientUserTypeId === 2) {
                  this.isAdSupport = true;
                }

                return clientUser.userId !== currentUserId;
              }

              return false;
            });
        },
      },
      interaction: {
        deep: true,
        immediate: true,
        handler() {
          this.currentInteraction = this.interaction;
        },
      },
    },
    created() {
      this.getAssociatedStores();
    },
    methods: {
      async handleSubmit() {
        try {
          this.loading = true;
          const interactionAdditionalFields = [];

          const formData = new FormData();
          let attachmentInteractionCustomFieldId;

          if (this.currentInteraction.labels) {
            for (const [key] of Object.entries(this.currentInteraction.labels)) {
              if (key) {
                const keyId = key.split("___")[1];

                attachmentInteractionCustomFieldId = keyId;
              }
            }
          }

          for (const [key, value] of Object.entries(this.currentInteraction.values)) {
            if (key && key === attachmentInteractionCustomFieldId) {
              interactionAdditionalFields.push({
                interactionCustomFieldId: +key,
                data: "attachments",
              });
              let i = 0;

              for (
                i;
                i < this.currentInteraction.values[attachmentInteractionCustomFieldId].length;
                i++
              ) {
                const file = this.currentInteraction.values[attachmentInteractionCustomFieldId][i];
                const labelName =
                  this.currentInteraction.labels[
                    `${file.name}___${attachmentInteractionCustomFieldId}`
                  ];

                formData.append(`label[${i}]`, labelName || file.name);
                formData.append(`attachments[${i}]`, file);
              }
            } else if (Array.isArray(value) && value.length > 0) {
              interactionAdditionalFields.push({
                interactionCustomFieldId: +key,
                data: value.join(","),
              });
            } else if (value) {
              interactionAdditionalFields.push({
                interactionCustomFieldId: +key,
                data: value,
              });
            }
          }
          const interactionAdditionalFieldsIds = interactionAdditionalFields.map(
            (i) => i.interactionCustomFieldId
          );

          for (const interactionCustomField of this.currentInteraction.interactionType
            .interactionCustomFields) {
            if (
              interactionAdditionalFieldsIds.indexOf(interactionCustomField.id) === -1 &&
              interactionCustomField.type === "boolean"
            ) {
              interactionAdditionalFields.push({
                interactionCustomFieldId: +interactionCustomField.id,
                data: false,
              });
            }
          }

          if (this.currentInteraction.onBehalfOf !== undefined) {
            formData.append("onBehalfOf", this.currentInteraction.onBehalfOf);
          }
          formData.append(
            "interactionAdditionalFields",
            JSON.stringify(interactionAdditionalFields)
          );
          formData.append("publishedAt", this.currentInteraction.publishedAt);
          formData.append("body", this.currentInteraction.body);
          formData.append("isPublic", this.currentInteraction.isPublic);
          formData.append("associatedStoreIds", this.currentInteraction.associatedStoreIds);
          const { id: currentUserId } = this.$store.getters["authorization/currentUser"];

          if (this.isEdit) {
            formData.append("id", this.currentInteraction.id);
            await InteractionResource.updateInteraction(formData)
              .then(async () => {
                this.showSuccessNotification("Interaction has been successfully updated.");
                this.$emit(
                  "handleClose",
                  this.currentInteraction.isHistory ? 0 : this.currentInteraction.interactionTypeId
                );
              })
              .catch((error) => {
                this.showErrorNotification(
                  error.response.data || "There was an error with your request."
                );
              });
          } else {
            formData.append("interactionTypeId", this.currentInteraction.interactionType.id);
            formData.append("userId", currentUserId);
            formData.append("clientId", this.currentInteraction.client.id);

            await InteractionResource.postInteractionData(formData)
              .then(async () => {
                this.showSuccessNotification("Interaction has been successfully created.");

                if (this.currentInteraction.shouldCreateTask) {
                  await this.$refs.interactionFields.$refs.createTaskComponent.createTask();
                }

                if (this.currentInteraction.shouldCreatePrimaryGoal) {
                  await this.createPrimaryGoal();
                }

                this.$emit("handleClose", this.currentInteraction.interactionType.id);
              })
              .catch(() => {
                this.showErrorNotification();
              });
          }
        } finally {
          this.loading = false;
        }
      },
      isValid() {
        let isValidArray = [];

        if (this.currentInteraction) {
          isValidArray = this.currentInteraction.interactionType.interactionCustomFields.filter(
            (customField) => {
              if (customField.interactionCustomField) {
                return (
                  !this.currentInteraction[customField.interactionCustomField.id] &&
                  customField.interactionCustomField.isRequired
                );
              }

              return false;
            }
          );
        }

        return (
          this.currentInteraction.formValid &&
          isValidArray.length === 0 &&
          (this.currentInteraction.shouldCreateTask ? this.currentInteraction.taskFormValid : true)
        );
      },
      async createPrimaryGoal() {
        const primaryGoal = {
          month: dayjs().startOf("month").format("YYYY-MM-DD"),
          goal: this.currentInteraction.primaryGoal,
        };

        await ClientResource.saveClientPrimaryGoal(this.currentInteraction.client.id, primaryGoal)
          .then(() => {
            this.showSuccessNotification("Primary goal has been added successfully.");

            EventBus.$emit("refresh-client");
          })
          .catch(() => {
            this.showErrorNotification("There was an error while saving primary goal.");
          });
      },
      async getAssociatedStores() {
        this.associatedStores = [];

        const groupId = this.interaction.client?.groupId;

        if (groupId) {
          this.associatedStores = await ClientResource.getManyClients(
            {
              clientsArray: [],
              groupId,
              scope: "active",
            },
            {
              withGroup: true,
              withFields: ["id", "name", "slug"],
            }
          ).then((res) => res.data);
        }
      },
    },
  };
</script>
