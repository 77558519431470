import Autolinker from "autolinker";

import AssetResource from "../api/resources/AssetResource";
import attachmentMixin from "./attachmentMixin";

export default {
  methods: {
    nl2br(str) {
      if (typeof str === "undefined" || str === null) {
        return "";
      }

      return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1<br>$2`);
    },
    async copyToClipboard(text, label = "Value") {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(text).then(() => {
          this.$toast.success(`${label} is copied to the clipboard.`);
        });
      } else {
        const textArea = document.createElement("textarea");

        textArea.value = text;

        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        const data = new Promise((res, rej) => {
          // eslint-disable-next-line no-unused-expressions
          document.execCommand("copy") ? res(true) : rej();
        });

        if (data) {
          this.$toast.success(`${label} is copied to the clipboard.`);
        }

        textArea.remove();
      }
    },
    async copyToClipboardHTML(text, label = "Value") {
      this.copy = text;
      document.addEventListener("copy", this.copyListener);
      document.execCommand("copy");
      document.removeEventListener("copy", this.copyListener);

      this.$toast.success(`${label} is copied to the clipboard.`);
    },
    copyListener(e) {
      e.clipboardData.setData("text/html", this.copy);
      e.clipboardData.setData("text/plain", this.copy);
      e.preventDefault();
    },
    openUrl(url, target = "_blank") {
      window.open(url, target);
    },
    async convertToFile(file) {
      return fetch(`data:${file.mimetype};base64,${file.data}`)
        .then((res) => {
          return res.arrayBuffer();
        })
        .then((buf) => {
          return new File([buf], file.name, { type: file.mimetype });
        });
    },
    async downloadSecureAsset(asset) {
      const path = new URL(asset.url).pathname;

      await AssetResource.getStaticAsset(path).then((res) => {
        const { data } = res;
        const blob = new Blob([data], { type: asset.mimeType });
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = attachmentMixin.methods.withExtension(asset);
        link.click();
      });
    },
    sanitizeHtml(html) {
      const div = document.createElement("div");

      div.innerHTML = html;

      return div.textContent || div.innerText || "";
    },
    hyperlinkContent(
      content,
      options = {
        stripPrefix: false,
        newWindow: true,
      }
    ) {
      return Autolinker.link(content || "", options);
    },
  },
};
