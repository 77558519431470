<template>
  <div class="p-6">
    <template v-if="loading">
      <v-progress-circular indeterminate size="32" color="primary" class="mr-2" />
      Please wait...
    </template>
    <div v-if="item">
      <h4 class="mb-2" :title="item.key">Description</h4>

      <div class="bg-white p-6 rounded custom-html" v-html="item.renderedFields.description"></div>

      <h4 class="mb-2 mt-4">Comments</h4>

      <v-timeline dense small v-if="comments.length > 0">
        <v-timeline-item
          v-for="comment in comments"
          :key="comment.id"
          :color="comment.author.displayName === 'Click Here Digital' ? 'primary' : 'secondary'"
          fill-dot
          small
          light
        >
          <div class="mb-1">
            <span class="font-bold">{{ getCommentAuthor(comment.author.displayName) }}</span>
            <span class="text-sm text-gray-600 ml-2">{{ comment.created }}</span>
          </div>
          <div class="bg-white px-4 pt-3 pb-1 rounded custom-html" v-html="comment.body"></div>
        </v-timeline-item>
      </v-timeline>

      <div v-if="comments.length === 0">
        <p class="text-gray-500">No comments</p>
      </div>

      <template v-if="isAvailableAddComment">
        <div class="mt-6 p-6">
          <h5 class="mb-2">Add A Comment</h5>
          <v-textarea
            v-model="comment"
            label="Comment"
            placeholder="Enter your comment here..."
            filled
            rows="5"
          ></v-textarea>

          <v-row justify="end">
            <v-col cols="auto">
              <v-btn color="primary" @click="addComment">Add Comment</v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import JiraResource from "@/api/resources/JiraResource";
  import issuesTypeMixin from "@/mixins/issuesTypeMixin";

  export default {
    name: "SupportRequestDetails",
    mixins: [issuesTypeMixin],
    props: ["issueKey", "type", "issue"],
    data() {
      return {
        loading: false,
        item: null,
        comment: "",
        atlassianUrl: "https://clickheredigital.atlassian.net",
      };
    },
    watch: {
      issueKey: {
        immediate: true,
        handler() {
          this.getIssue();
          this.comment = "";
        },
      },
    },
    computed: {
      currentUser() {
        const user = this.$store.getters["authorization/currentUser"];

        return {
          firstName: user.firstName,
          lastName: user.lastName,
          fullName: user.fullName,
          email: user.email,
        };
      },
      comments() {
        const comments = this.item.renderedFields.comment.comments || [];

        return comments.filter((comment) => {
          return (
            !comment.body.includes("[Internal]") && !comment.body.includes("&#91;Internal&#93;")
          );
        });
      },
      isAvailableAddComment() {
        if (this.item) {
          return [...this.issuesOpenStatuses, ...this.issuesClosedStatuses].includes(
            this.item.fields.status.name
          );
        }

        return false;
      },
    },
    methods: {
      async getIssue(updateOnly = false) {
        if (!updateOnly) {
          this.item = null;

          this.loading = true;
        }

        if (this.issue) {
          this.item = this.issue;

          this.loading = false;

          return;
        }

        await JiraResource.getIssue(this.issueKey)
          .then((response) => {
            this.item = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      getCommentAuthor(author) {
        return author === "Click Here Digital" ? "Reporter" : author;
      },
      async addComment() {
        if (this.comment === "") {
          this.showErrorNotification("Please enter a comment");

          return;
        }
        const submittedBy = `Submitted by ${this.currentUser.fullName} (${this.currentUser.email})`;
        const comment = `
          ${this.comment}

          ${submittedBy}
        `;

        await JiraResource.createComment(this.issueKey, comment)
          .then(() => {
            this.getIssue(true);
            this.showSuccessNotification("Your comment has been successfully posted.");
          })
          .catch(() => {
            this.showErrorNotification();
          })
          .finally(() => {
            this.comment = "";
          });
      },
    },
  };
</script>

<style>
  .custom-html {
    font-size: 0.9rem;
  }
  .custom-html h1 {
    font-size: 1.75rem;
  }
  .custom-html h2 {
    font-size: 1.5rem;
  }
  .custom-html h3 {
    font-size: 1.25rem;
  }
  .custom-html h4 {
    font-size: 1.15rem;
  }
  .custom-html h5 {
    font-size: 1.05rem;
  }
  .custom-html h6 {
    font-size: 1rem;
  }
  .custom-html .image-wrap {
    display: flex;
  }
  .custom-html .image-wrap img {
    width: auto;
    max-width: 100%;
    height: 100%;
  }
</style>
