import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);

export default {
  methods: {
    formatCurrency(amount, decimal = 2) {
      if (typeof amount === "undefined") {
        amount = 0;
      }

      const value = !Number.isNaN(amount) ? Number(amount) : 0;

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });

      return formatter.format(Number(value));
    },

    formatNumber(number, decimal = 0) {
      if (typeof number === "undefined") {
        number = 0;
      }

      const value = !Number.isNaN(number) ? Number(number) : 0;

      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });

      return formatter.format(value);
    },

    formatPercentage(number, decimal = 2) {
      if (typeof number === "undefined" || Number.isNaN(number)) {
        number = 0;
      }

      return `${parseFloat(number).toFixed(decimal)}%`;

      /**
       * Note : Replaced due to unexpected results
       *    Example that did not work:
       *       sem_conv_rate: 7.433628318584071,
       *"       bounce_rate": 34.1456882699518,
       */

      /*
       * const formatter = new Intl.NumberFormat("en-US", {
       *   style: "percent",
       *   minimumFractionDigits: decimal,
       *   maximumFractionDigits: decimal,
       * });
       *
       * return formatter.format(Number(number));
       */
    },

    formatPhoneNumber(number, asterisk = false) {
      const cleaned = `${number}`.replace(/\D/g, "");

      let format = /^(1|)?(\d{3})(\d{3})(\d{4})$/;

      if (asterisk) {
        format = /^(1|)?(\d{3})(\d{3})$/;
      }

      const match = cleaned.match(format);

      if (match) {
        const intlCode = match[1] ? "+1 " : "";

        return [intlCode, "(", match[2], ") ", match[3], "-", asterisk ? "****" : match[4]].join(
          ""
        );
      }

      return null;
    },

    ordinal(number) {
      const suffixes = ["th", "st", "nd", "rd"];
      const absNumber = Math.abs(number);
      const roundedNumber = Math.floor(absNumber);
      const lastDigit = roundedNumber % 10;
      const secondLastDigit = Math.floor(roundedNumber / 10) % 10;

      let suffix;

      if (secondLastDigit === 1 || lastDigit > 3) {
        // eslint-disable-next-line prefer-destructuring
        suffix = suffixes[0];
      } else {
        suffix = suffixes[lastDigit] || suffixes[0];
      }

      return `${roundedNumber}${suffix}`;
    },

    formatDate(date, format = "MM/DD/YYYY", withoutDefault = true, emptyValue = "-") {
      if (!date && withoutDefault) {
        return emptyValue;
      }

      return dayjs(date).format(format);
    },

    formatUTCDate(date, format = "MM/DD/YYYY", withoutDefault = true, emptyValue = "-") {
      if (!date && withoutDefault) {
        return emptyValue;
      }

      return dayjs.utc(date).format(format);
    },

    relativeTime(date, withoutDefault = true, emptyValue = "-") {
      if (!date && withoutDefault) {
        return emptyValue;
      }

      return dayjs(date).fromNow();
    },

    formatDuration(seconds) {
      const m = dayjs.unix(seconds);

      return m.format("mm:ss");
    },
  },
};
