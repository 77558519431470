import request from "../index";

export default {
  getIssues(projectKey, statuses) {
    return request.get("/jira/issues", { project_key: projectKey, statuses: statuses.join(",") });
  },
  getIssue(issueKey) {
    return request.get(`/jira/issue?issue_key=${issueKey}`);
  },
  createIssue(issue) {
    return request.post("/jira/issues", issue, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  createComment(issueKey, body) {
    return request.post("/jira/issue/comments", { issue_key: issueKey, body });
  },
  reopenTask(issueKey) {
    return request.put(`/jira/issues/${issueKey}`);
  },
};
