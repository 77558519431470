<template>
  <v-dialog persistent v-model="dialog" width="95%" max-width="1200">
    <v-card class="p-5">
      <h4 class="my-6">Confirm Budget Changes</h4>

      <p class="text-gray-600 my-6">
        In order to continue using the portal, please confirm any changes to the budget(s) listed
        below.
      </p>

      <v-data-table :loading="loading" :headers="headers" :items="changes">
        <template v-slot:item.client.name="{ item }">
          <div class="cursor-pointer text-blue-600" @click="showBudgets(item)">
            {{ item.client.name }}
          </div>
        </template>
        <template v-slot:item.previousAmount="{ item }">
          {{ formatCurrency(item.previousAmount) }}
        </template>
        <template v-slot:item.newAmount="{ item }">
          {{ formatCurrency(item.newAmount) }}
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ formatDate(item.createdAt, "LLL") }}
        </template>
        <template v-slot:item.confirmedAt="{ item }">
          <template v-if="item.confirmedAt">
            <v-icon color="success" class="mr-1" small>fa fa-check</v-icon>
            Confirmed
          </template>
          <template v-else>
            <v-btn small color="success" @click="confirmChange(item)">Confirm Now</v-btn>
          </template>
        </template>
      </v-data-table>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary darken-1" @click="closeDialog" v-if="allConfirmed">Close</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showBudgetsDialog" width="95%" max-width="1400" v-if="selectedClient">
      <v-card class="p-6">
        <h4 class="mb-6">{{ selectedClient.name }} Budgets</h4>
        <client-budget-table
          v-if="showBudgetsDialog"
          :headers="budgetHeaders"
          :items="budgets"
          :loading="budgetsTableLoading"
          :disableEdit="true"
        />
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
  import dayjs from "dayjs";

  import BudgetResource from "@/api/resources/accounting/BudgetResource";
  import ClientBudgetResource from "@/api/resources/client/BudgetResource";
  import { EventBus } from "@/bootstrap/EventBus";
  import ClientBudgetTable from "@/views/pages/clients/budgets/components/ClientBudgetTable.vue";

  export default {
    name: "BudgetChangesDialog",
    components: { ClientBudgetTable },
    data() {
      return {
        headers: [
          { text: "Client", value: "client.name" },
          { text: "Service", value: "Service.name" },
          { text: "Previous Amount", value: "previousAmount" },
          { text: "New Amount", value: "newAmount" },
          { text: "Change Detected", value: "createdAt" },
          { text: "", value: "confirmedAt" },
        ],
        budgetHeaders: [
          { text: "Service", sortable: false },
          { text: "Starts At", sortable: false },
          { text: "Ends At", sortable: false },
          { text: "Invoice Amount", sortable: false },
          { text: "Actual Spend", sortable: false },
          { text: "Notes", sortable: false, width: "25%" },
          { text: "Actions", sortable: false },
        ],
        items: [],
        changes: [],
        loading: false,
        dialog: false,
        showBudgetsDialog: false,
        filters: {
          month: dayjs().format("MMMM"),
          year: dayjs().format("YYYY"),
          serviceId: null,
          serviceGroup: null,
        },
        budgets: [],
        budgetsTableLoading: false,
        selectedClient: null,
      };
    },
    computed: {
      allConfirmed() {
        return this.changes.every((change) => change.confirmedAt);
      },
    },
    methods: {
      resetFilter() {
        this.filters = {
          month: dayjs().format("MMMM"),
          year: dayjs().format("YYYY"),
          serviceId: null,
          serviceGroup: null,
        };
      },
      showBudgets(item) {
        this.selectedClient = item.client;
        this.resetFilter();
        this.showBudgetsDialog = true;

        this.getBudgets();
      },
      checkForBudgetChanges() {
        const currentUser = this.$store.getters["authorization/currentUser"];

        BudgetResource.getBudgetChangesForUser(currentUser.id).then((response) => {
          if (response.data.length > 0) {
            this.changes = response.data;

            this.dialog = true;
          }
        });
      },
      closeDialog() {
        this.dialog = false;
      },
      async getBudgets() {
        if (!this.selectedClient) {
          return;
        }

        this.budgetsTableLoading = true;
        this.budgets = [];

        this.budgets = await ClientBudgetResource.getActiveBudgetsList(this.selectedClient.id, {
          ...this.filters,
        })
          .then((res) => res.data)
          .finally(() => {
            this.budgetsTableLoading = false;
          });
      },
      async confirmChange(item) {
        const authUser = this.$store.getters["authorization/currentUser"];

        await BudgetResource.confirmBudgetChange(item.id, authUser.id)
          .then(() => {
            item.confirmedAt = dayjs().format();

            this.showSuccessNotification("Budget change has been successfully confirmed.");
          })
          .catch(() => {
            this.showErrorNotification();
          });
      },
      registerEvents() {
        EventBus.$on("budget-filter", (filter) => {
          this.filters = filter;
          this.getBudgets();
        });
        EventBus.$on("budget-refresh", () => {
          this.getBudgets();
        });
      },
      unloadEvents() {
        EventBus.$off("budget-filter");
        EventBus.$off("budget-refresh");
      },
    },
    mounted() {
      this.registerEvents();

      this.checkForBudgetChanges();
    },
    beforeDestroy() {
      this.unloadEvents();
    },
  };
</script>
