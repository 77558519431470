const axiosRetryConfig = {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retryCondition(error) {
    switch (error.response?.status) {
      case 404:
      case 401:
      case 400:
        return false;
      default:
        return true;
    }
  },
};

export default axiosRetryConfig;
