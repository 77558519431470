<template>
  <div class="p-6">
    <div class="flex space-x-6 items-center">
      <h3>Support Requests</h3>

      <div>
        <v-btn small @click="showNewIssueDialog" color="primary">New Request</v-btn>
      </div>
    </div>

    <div class="w-full mt-6">
      <v-tabs v-model="tab">
        <v-tab>Open Requests</v-tab>
        <v-tab>Recently Closed</v-tab>
        <v-tab>My Requests</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="mt-3">
        <v-tab-item>
          <support-requests-table
            :loading="loading"
            :items="issues"
            :type="issueTypes.OPENED"
            @fetch="getData"
          />
        </v-tab-item>
        <v-tab-item>
          <support-requests-table
            :loading="loading"
            :items="issues"
            :type="issueTypes.CLOSED"
            @fetch="getData"
          />
        </v-tab-item>
        <v-tab-item>
          <support-requests-table
            :loading="loading"
            :items="issues"
            :type="issueTypes.MY"
            @fetch="getData"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>

    <v-dialog
      v-model="newRequestDialog"
      @keydown.esc="closeNewIssueDialog"
      persistent
      width="95%"
      max-width="700"
    >
      <v-card>
        <v-btn
          absolute
          right
          @click="closeNewIssueDialog"
          icon
          color="secondary lighten-3"
          class="mt-6"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <issue-helper @success="handleSuccess" v-if="newRequestDialog" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import JiraResource from "@/api/resources/JiraResource";
  import SupportRequestsTable from "@/components/utilities/components/SupportRequestsTable.vue";
  import IssueHelper from "@/components/utilities/IssueHelper.vue";
  import issuesTypeMixin from "@/mixins/issuesTypeMixin";

  export default {
    name: "SupportRequests",
    components: { SupportRequestsTable, IssueHelper },
    mixins: [issuesTypeMixin],
    data() {
      return {
        loading: false,
        issues: [],
        tab: "open",
        projectKey: "PS",
        newRequestDialog: false,
      };
    },
    watch: {
      tab() {
        this.getData();
      },
    },
    methods: {
      getData() {
        if (this.tab === 0) {
          this.getOpenIssues();
        } else if (this.tab === 1) {
          this.getClosedIssues();
        } else {
          this.getAllIssues();
        }
      },
      async getOpenIssues() {
        await this.getIssues(this.issuesOpenStatuses);
      },
      async getClosedIssues() {
        await this.getIssues(this.issuesClosedStatuses);
      },
      async getAllIssues() {
        await this.getIssues([...this.issuesOpenStatuses, ...this.issuesClosedStatuses]);
      },
      handleSuccess() {
        this.getOpenIssues();

        this.closeNewIssueDialog();
      },
      async getIssues(statuses) {
        this.loading = true;
        this.issues = [];

        await JiraResource.getIssues(this.projectKey, statuses)
          .then((res) => {
            this.issues = res.data;
          })
          .finally(() => {
            this.loading = false;
          });
      },
      showNewIssueDialog() {
        this.newRequestDialog = true;
      },
      closeNewIssueDialog() {
        this.newRequestDialog = false;
      },
    },
  };
</script>
