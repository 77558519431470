<template>
  <v-dialog v-model="dialog" @keydown.esc="cancel" persistent max-width="490">
    <v-card>
      <v-card-title class="error headline text-white font-bold">Confirm Delete</v-card-title>
      <v-card-text class="text-xl font-weight-medium py-4" v-if="amountOfItems > 1">
        Are you sure you want to delete these {{ amountOfItems }} items?
      </v-card-text>
      <v-card-text class="text-xl font-weight-medium py-4" v-else>
        Are you sure you want to delete this item?
      </v-card-text>
      <v-card-text v-if="message" class="pt-2">
        <span v-html="message"></span>
      </v-card-text>
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <v-btn text color="grey" @click="cancel">Cancel</v-btn>
        <v-btn color="error" @click="confirm">Confirm</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  /** How to Use
 * 
 *  Register component where used :
 *     <base-delete-dialog/>
 * 
 *  Within delete method have this block of code:
 * 
 *    EventBus.$emit("delete");
        EventBus.$on("confirmation", (confirm) => {
          if (confirm) {
            //Insert code here if user clicks confirm
          }
          EventBus.$off("confirmation");
        });
        
 */

  import { EventBus } from "@/bootstrap/EventBus";

  export default {
    name: "DeleteDialog",
    props: {
      emitKey: {
        type: String,
        default: "delete",
        required: false,
      },
    },
    data() {
      return {
        dialog: false,
        id: null,
        amountOfItems: 1,
        message: null,
      };
    },
    computed: {
      eventName() {
        if (this.id) {
          return `confirmation-${this.id}`;
        }

        return "confirmation";
      },
    },
    created() {
      this.registerEvents();
    },
    beforeDestroy() {
      this.unloadEvents();
    },
    methods: {
      registerEvents() {
        EventBus.$on(this.emitKey, (id = null, amountOfItems = 1, message = null) => {
          this.dialog = true;
          this.id = id;
          this.amountOfItems = Number(amountOfItems || 1);
          this.message = message;
        });
      },
      unloadEvents() {
        EventBus.$off(this.emitKey);
      },
      confirm() {
        this.dialog = false;
        EventBus.$emit(this.eventName, true);
      },
      cancel() {
        this.dialog = false;
        EventBus.$emit(this.eventName, false);
      },
    },
  };
</script>
