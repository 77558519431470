import NotificationResource from "../../../api/resources/NotificationResource";
import UserResource from "../../../api/resources/UserResource";
import { allowedLeaders, notificationEmail } from "../../../helpers/leadership";
import store from "../../../store";

export default [
  {
    path: "/taskiq",
    beforeEnter(to) {
      const { task_id: taskId } = to.query;

      window.location.href = `${process.env.VUE_APP_TASK_IQ_CLIENT_PROXY}/tasks/${taskId}`;
    },
  },
  {
    path: "/taskiq/login",
    name: "taskiq.login",
    beforeEnter: async (to, from, next) => {
      let redirectParam = "";

      const { redirect } = to.query;

      if (redirect) {
        redirectParam += `?redirect=${redirect}`;
      }

      const currentUser = store.getters["authorization/currentUser"];

      if (currentUser) {
        // Get user to check if session is still valid
        await UserResource.get(currentUser.id).then((res) => res.data);

        const { id, fullName, impersonatedBy, kobiKey, impersonatedUserName } =
          store.getters["authorization/currentUser"];
        const isImpersonating = impersonatedBy !== undefined;

        if (isImpersonating && allowedLeaders.includes(id)) {
          const emailToSend = notificationEmail(impersonatedUserName, fullName);

          await NotificationResource.sendInternalEmail(emailToSend);
        }

        if (kobiKey) {
          // Even if the user is impersonating, we use the original user's kobiKey
          window.location.replace(
            `${process.env.VUE_APP_TASK_IQ_CLIENT_PROXY}/auth/token/${kobiKey}${redirectParam}`
          );

          return;
        }
      }

      next({
        name: "login",
        query: { redirect: `/taskiq/login${redirectParam}` },
      });
    },
    meta: { partnerAccessible: true },
  },
];
