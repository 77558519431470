<template>
  <div class="p-1">
    <div class="flex px-4 py-2 pb-6">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Search"
        single-line
        dense
        hide-details
        clearable
      />
    </div>
    <v-data-table
      :search="search"
      :loading="loading"
      :headers="headers"
      :items="issues"
      disable-pagination
      hide-default-footer
      :single-expand="true"
      show-expand
      :expanded.sync="expanded"
      class="elevation-1"
    >
      <template v-slot:item.fields.created="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="capitalize">{{ relativeTime(item.fields.created) }}</span>
          </template>
          <span>{{ formatDate(item.fields.created, "LLL") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.fields.status.name="{ item }">
        <v-chip :color="itemStatusColor(item?.fields?.status?.name)" text-color="white">
          {{ filteredName(item.fields?.status?.name) }}
        </v-chip>
      </template>

      <template v-slot:item.fields.updated="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="capitalize">
              {{ relativeTime(item.fields.updated || item.fields.created) }}
            </span>
          </template>
          <span>{{ formatDate(item.fields.updated || item.fields.created, "LLL") }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.data-table-expand="{ item }">
        <div class="flex items-center justify-end space-x-3">
          <v-btn
            color="primary"
            small
            @click="expanded.includes(item) ? (expanded = []) : (expanded = [item])"
          >
            <i class="fas fa-edit mr-1"></i>
            Details
          </v-btn>

          <template v-if="item.fields?.status?.name === 'Done'">
            <v-btn color="success" small @click="reopenTask(item.key)">Reopen</v-btn>
          </template>
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="bg-gray-100" v-if="expanded.includes(item)">
          <support-request-details :issue-key="item.key" :type="type" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import JiraResource from "@/api/resources/JiraResource";
  import SupportRequestDetails from "@/components/utilities/components/SupportRequestDetails.vue";
  import issuesTypeMixin from "@/mixins/issuesTypeMixin";

  export default {
    name: "SupportRequestsTable",
    components: { SupportRequestDetails },
    mixins: [issuesTypeMixin],
    props: ["loading", "items", "type"],
    data() {
      return {
        search: "",
        headers: [
          { text: "Summary", value: "fields.summary" },
          { text: "Type", value: "fields.issuetype.name" },
          { text: "Created At", value: "fields.created" },
          { text: "Status", value: "fields.status.name" },
          { text: "Last Updated", value: "fields.updated" },
          {
            text: "",
            value: "data-table-expand",
            align: "end",
            sortable: false,
            filterable: false,
          },
        ],
        expanded: [],
      };
    },
    methods: {
      async reopenTask(issueKey) {
        await JiraResource.reopenTask(issueKey)
          .then(() => {
            this.$emit("fetch");

            this.showSuccessNotification("Task has been successfully reopened.");
          })
          .catch(() => {
            this.showErrorNotification();
          });
      },
      filteredName(name) {
        if (!name) {
          return "";
        }

        if (name === "Not Approved (Postpone)") {
          return "Submitted";
        }

        return name;
      },
    },
    computed: {
      currentUser() {
        return this.$store.getters["authorization/currentUser"];
      },
      issues() {
        if (this.type === this.issueTypes.MY) {
          return this.items.filter(({ fields: { description } }) => {
            const { email, id } = this.currentUser;
            const conditions = [`Reporter User ID: ${id}`, `Reporter User: .* ${email}`];

            return conditions.every((c) => description.match(c));
          });
        }

        return this.items;
      },
    },
  };
</script>
