import dayjs from "dayjs";

import DraftResource from "../api/resources/client/DraftResource";
import helpers from "./helpers";

export default {
  mixins: [helpers],
  data() {
    return {
      draft: null,
      draftType: "client-activity",
    };
  },
  computed: {
    draftTime() {
      if (this.draft) {
        return dayjs(this.draft.updatedAt).format("MM-DD-YYYY, h:mm:ss a");
      }

      return "N/A";
    },
  },
  methods: {
    async getClientActivityDraft() {
      const { clientId } = this.activity;

      if (!clientId) {
        return;
      }

      const { id: currentUserId } = this.$store.getters["authorization/currentUser"];
      const draft = await DraftResource.getDraft(clientId, currentUserId, this.draftType).then(
        (res) => res.data
      );

      if (draft) {
        const { data, attachments } = draft;

        // There could be a draft for the same client but not related with given activity
        if (
          data.id &&
          data.id !== "null" &&
          this.activity.id &&
          Number(data.id) !== Number(this.activity.id)
        ) {
          return;
        }

        this.activity = {
          ...data,
          id: data.id && data.id !== "null" ? Number(data.id) : null,
          associatedIds: JSON.parse(data.associatedIds),
          labels: JSON.parse(data.labels),
          categoryId: Number(data.categoryId),
          clientId: Number(data.clientId),
          typeId: Number(data.typeId),
          attachments: [],
        };

        this.currentFile = await Promise.all(
          attachments
            .filter((attachment) => attachment.type === "currentCoop")
            .map((attachment) => this.convertToFile(attachment))
        );

        this.previousFile = await Promise.all(
          attachments
            .filter((attachment) => attachment.type === "previousCoop")
            .map((attachment) => this.convertToFile(attachment))
        );

        this.activity.attachments = await Promise.all(
          attachments
            .filter((attachment) => attachment.type === "attachment")
            .map((attachment) => this.convertToFile(attachment))
        );

        if (this.currentFile || this.previousFile) {
          this.coopScreenshots = true;
        }

        this.draft = draft;
      } else {
        this.draft = null;
      }
    },

    async deleteDraft() {
      if (this.draft) {
        const { clientId, userId, draftType } = this.draft;

        await DraftResource.deleteDraft(clientId, userId, draftType);

        this.draft = null;
      }
    },

    async saveClientActivityDraft() {
      if (this.dialog) {
        const activity = { ...this.activity };

        if (!activity.clientId) {
          return;
        }

        activity.labels = JSON.stringify(activity.labels);
        activity.associatedIds = JSON.stringify(activity.associatedIds);
        activity.userId = this.$store.getters["authorization/currentUser"].id;

        delete activity.attachments;

        const formData = new FormData();

        Object.keys(activity).forEach((key) => {
          formData.append(`data[${key}]`, activity[key]);
        });

        formData.append("clientId", activity.clientId);
        formData.append("userId", activity.userId);
        formData.append("draftType", this.draftType);

        let i = 0;

        for (i; i < this.activity.attachments.length; i++) {
          const file = this.activity.attachments[i];

          formData.append(`attachmentTypes[${i}]`, "attachment");
          formData.append(`attachments[${i}]`, file);
        }

        if (this.currentFile) {
          formData.append(`attachmentTypes[${i}]`, "currentCoop");
          formData.append(`attachments[${i}]`, this.currentFile);
          i += 1;

          if (this.previousFile) {
            formData.append(`attachmentTypes[${i}]`, "prevCoop");
            formData.append(`attachments[${i}]`, this.previousFile);
          }
        }

        this.draft = await DraftResource.saveDraft(formData).then((res) => res.data);
      }
    },
  },
};
