import request from "../../index";

export default {
  getDraft(clientId, userId, draftType) {
    return request.get(`/drafts?clientId=${clientId}&userId=${userId}&draftType=${draftType}`);
  },
  saveDraft(draft) {
    return request.post("/drafts", draft, { headers: { "Content-Type": "multipart/form-data" } });
  },
  deleteDraft(clientId, userId, draftType) {
    return request.delete(`/drafts?clientId=${clientId}&userId=${userId}&draftType=${draftType}`);
  },
};
