import * as Sentry from "@sentry/vue";

import ClientResource from "../api/resources/ClientResource";
import TaskIQResource from "../api/resources/TaskIQResource";
import dashboardMixin from "../mixins/dashboardMixin";
import store from "../store";

async function processAuth(user, router, route = null) {
  const { methods } = dashboardMixin;

  localStorage.clear();
  let tokenError = null;
  let tokenResponse = {};

  if (!user.pdfUser && user.kobiKey && user.kobiKey !== "") {
    tokenResponse = await TaskIQResource.getUserKey(user.kobiKey)
      .then((response) => response.data)
      .catch(() => {
        tokenError = true;
      });
  }

  user.kobiRefreshToken = tokenError ? null : tokenResponse.accessToken;

  store.dispatch("authorization/login", user);
  store.dispatch("layout/openNotificationMenu");
  store.dispatch("layout/openMobileMenu");

  Sentry.setUser({
    id: user.id,
    username: user.fullName,
    email: user.email,
  });

  if (!user.pdfUser) {
    // This needs to be at the end
    ClientResource.getSimpleClientList().then();
  }

  if (route) {
    if (
      route.query.redirect &&
      route.query.redirect !== "/" &&
      !route.query.redirect.startsWith("/login") &&
      !route.query.redirect.startsWith("/logout")
    ) {
      await router.push({ path: route.query.redirect });
    } else {
      const departments = store.getters["authorization/currentUser"].departments.map((d) => d.id);

      if (departments.some(methods.isFulfillment)) {
        await router.push({ name: "dashboard.pacing" });

        return;
      }

      if (departments.some(methods.isAccountManagement)) {
        await router.push({ name: "dashboard.account-management" });

        return;
      }

      if (departments.some(methods.isSEO)) {
        await router.push({ name: "dashboard.seo" });

        return;
      }

      if (departments.some(methods.isSEM)) {
        await router.push({ name: "dashboard.sem" });

        return;
      }

      if (departments.some(methods.isSocial)) {
        await router.push({ name: "dashboard.social" });

        return;
      }

      if (departments.some(methods.isProgrammatic)) {
        await router.push({ name: "dashboard.programmatic" });
      }

      await router.push({ name: "dashboard.monthly-report" });
    }
  }
}

export default processAuth;
