import request from "../index";

export default {
  getInteractionTypes() {
    return request.get("/interaction-types");
  },
  postInteractionType(interactionType) {
    return request.post("/interaction-types", interactionType);
  },
  updateInteractionType(interactionType, id) {
    return request.put(`/interaction-types/${id}`, interactionType);
  },
  deleteInteractionTypeById(id) {
    return request.delete(`/interaction-types/${id}`);
  },
};
