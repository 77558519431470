import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);

/**
 * ATTENTION!!!
 * This file is also maintained in TaskIQ.
 * Please in case of update, ensure to edit both.
 */
export const allowedLeaders = [
  3, // bo@clickheredigital.com
  4, // bj@clickheredigital.com
  39, // holly@clickheredigital.com
  95, // zack.hill@clickheredigital.com
  142, // sem@clickheredigital.com
  145, // rashaad@clickheredigital.com
  305, // shelly@clickheredigital.com
  4298, // greer@clickheredigital.com
];

export const notificationEmail = (originalUser, leaderUser) => {
  return {
    from: "noreply@clickheredigital.com",
    to: "it@clickheredigital.com",
    subject: "Forbidden TaskIQ Access Attempt",
    html: `<p>User "${originalUser}" impersonated "${leaderUser}", and tried to log into TaskIQ at ${dayjs().format(
      "LLL"
    )}.</p><br>--<br>Click Here Digital`,
    noTemplate: true,
  };
};
