import request from "../index";

export default {
  login(email, password) {
    return request.post("/users/signIn", {
      email,
      password,
    });
  },
  loginWithImpersonation(userId, currentUserId) {
    return request.post("/users/signInWithUserImpersonation", { userId, currentUserId });
  },
  loginWith2FA(email, password, token, secret, authAccessToken) {
    return request.post("/users/signInWith2FA", {
      email,
      password,
      token,
      secret,
      authAccessToken,
    });
  },
  sendRecovery2FACode(email, authAccessToken) {
    return request.post("/users/sendRecovery2FACode", { email, authAccessToken });
  },
  add2FAToUsers(userIds) {
    return request.post("/users/add2FAToUsers", { userIds });
  },
  resetPassword(email) {
    return request.post("/users/reset-password", { email });
  },
  verifyPasswordResetToken(token) {
    return request.post("/users/verify-reset-token", { token });
  },
  submitResetPassword(params) {
    return request.post("/users/update-password", params);
  },
  getUsersList(filters = {}) {
    return request.get("/users", filters);
  },
  getUsersById(userIds) {
    return request.post("/users/getByIds", { userIds });
  },
  getManyUsers(userIds = [], filters = {}) {
    return request.post("/users/getManyUsers", { userIds, filters });
  },
  getActiveUsersList(params) {
    return request.get("/users/active", params);
  },
  getUsersWithSecondType(activeOnly = false) {
    if (activeOnly) {
      return request.get("/users/active?userTypeId=2");
    }

    return request.get("/users?userTypeId=2");
  },
  postUserData(user) {
    return request.post("/users", user);
  },
  get(userId) {
    return request.get(`/users/${userId}`);
  },
  getUserSessions(userId, startDate = null, endDate = null) {
    return request.get(`/users/${userId}/sessions`, { startDate, endDate });
  },
  update(userId, payload) {
    return request.put(`/users/${userId}`, payload);
  },
  uploadProfileAvatar(userId, payload) {
    return request.post(`/users/${userId}/avatar`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  getAuthorizedClients(userId, signal = null) {
    return request.get(`/user/${userId}/my-clients/`, {}, { signal });
  },
  getAdditionalSupportClients(userId) {
    return request.get(`/user/${userId}/additional-support-clients`);
  },
  getUserRoles() {
    return request.get("/roles");
  },
  checkPermissions() {
    return request.post("/users/checkPermissions");
  },
  disable2FA(userId) {
    return request.post(`/users/${userId}/disable2FA`);
  },
  enable2FA(userId, body) {
    return request.post(`/users/${userId}/enable2FA`, { ...body });
  },
  set2FA() {
    return request.post("/users/set2FA");
  },
  getUserTypes() {
    return request.get("/user-types");
  },
  getAllActivities(userId) {
    return request.get(`/user/${userId}/activities`);
  },
  getUserByEmail(email) {
    return request.get(`/users/getUserByEmail?email=${email}`);
  },
  getUsernameByEmail(body) {
    const { email, tenantId } = body;

    return request.post(`/users/getUsernameByEmail`, { email, tenantId });
  },
  generateTaskIQToken(userId) {
    return request.post(`/users/${userId}/generateToken`, {});
  },
  getBlockedIps() {
    return request.get("/users/blockedIps");
  },
  deleteIpFromBlockedIps(ip) {
    return request.delete(`/users/blockedIps/${ip}`);
  },
  getScheduledTerminations(active = true) {
    return request.get("/scheduled-user-terminations", { active });
  },
  createScheduledTermination(termination) {
    return request.post("/scheduled-user-terminations", termination);
  },
  updateScheduledTermination(termination) {
    return request.put(`/scheduled-user-terminations/${termination.id}`, termination);
  },
  deleteScheduledTermination(id) {
    return request.delete(`/scheduled-user-terminations/${id}`);
  },
};
