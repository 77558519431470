<template>
  <div>
    <v-menu open-on-hover top offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div class="d-inline">
          <v-btn class="mr-1" v-bind="attrs" v-on="on" icon color="primary">
            <v-icon small>fa fa-plus-circle</v-icon>
          </v-btn>
        </div>
      </template>

      <v-list>
        <v-subheader>Create</v-subheader>
        <v-list-item @click="showInteractionModal = true">Contact</v-list-item>
        <v-list-item @click="showActivitiesModal">Client Activity</v-list-item>
      </v-list>
    </v-menu>

    <client-activity
      ref="clientActivityModal"
      v-if="showActivitiesDialog"
      @close="showActivitiesDialog = false"
    />

    <client-interaction v-if="showInteractionModal" @handleClose="showInteractionModal = false" />
  </div>
</template>

<script>
  import { EventBus } from "@/bootstrap/EventBus";
  import ClientInteraction from "@/views/layout/header/components/ClientInteraction.vue";

  import ClientActivity from "./ClientActivity.vue";

  export default {
    name: "ActionMenu",
    components: { ClientInteraction, ClientActivity },
    data() {
      return {
        showActivitiesDialog: false,
        showInteractionModal: false,
      };
    },
    created() {
      this.registerEvents();
    },
    beforeDestroy() {
      this.unloadEvents();
    },
    methods: {
      showActivitiesModal() {
        this.showActivitiesDialog = true;

        this.$nextTick(() => {
          this.$refs.clientActivityModal.openDialog();
        });
      },
      registerEvents() {
        EventBus.$on("open-activity-logger", (activity) => {
          this.showActivitiesDialog = true;

          this.$nextTick(() => {
            EventBus.$emit("show-activity-logger", activity);
          });
        });
      },
      unloadEvents() {
        EventBus.$off("open-activity-logger");
      },
    },
  };
</script>
