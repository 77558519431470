export default {
  namespaced: true,
  state: {
    tenant: {},
    user: null,
    clients: [],
    authAccessToken: null,
    hasGroupAccess: false,
    groupIds: [],
    settings: {
      visibleVSDColumns: null,
      visibleHeavyUpColumns: null,
    },
  },
  getters: {
    currentTenant: (state) => state.tenant,
    currentTenantId: (state) => (state.tenant ? state.tenant.id : null),
    currentUserId: (state) => (state.user ? state.user.id : null),
    currentUser: (state) => state.user,
    userSettings: (state) => state.settings,
    currentUserAccessToken: (state) => (state.user ? state.user.accessToken : null),
    currentUserFullName: (state) => state.user.fullName,
    isAuthenticated: (state) => !!state.user,
    currentUserAuthorizedClients: (state) => state.clients,
    isEmployee: (state) => state.user && state.user.UserType.name === "Employee",
    currentUserDepartmentIds: (state) =>
      state.user?.departments.map((department) => department.id) || [],
    hasGroupAccess: (state) => state.hasGroupAccess,
    groupIds: (state) => state.groupIds,
  },
  mutations: {
    SET_TENANT(state, data) {
      state.tenant = data;
    },
    SET_VSD_VISIBLE_COLUMNS(state, data) {
      state.settings.visibleVSDColumns = data;
    },
    SET_HEAVYUP_VISIBLE_COLUMNS(state, data) {
      state.settings.visibleHeavyUpColumns = data;
    },
    UPDATE_USER(state, data) {
      state.user = { ...state.user, ...data };
    },
    LOGIN(state, data) {
      state.user = { ...data };
      state.authAccessToken = data.authAccessToken;
    },
    LOGOUT(state) {
      state.user = null;
      state.clients = [];
    },
    SET_CLIENTS(state, data) {
      state.clients = data.clients;
      state.groupIds = data.groupIds;
      state.hasGroupAccess = data.hasGroupAccess;
    },
  },
  actions: {
    setTenant(context, data) {
      context.commit("SET_TENANT", data);
    },
    updateUser(context, data) {
      context.commit("UPDATE_USER", data);
    },
    setVsdVisibleColumns(context, data) {
      context.commit("SET_VSD_VISIBLE_COLUMNS", data);
    },
    setHeavyUpVisibleColumns(context, data) {
      context.commit("SET_HEAVYUP_VISIBLE_COLUMNS", data);
    },
    login(context, data) {
      context.commit("LOGIN", data);
    },
    logout(context) {
      context.commit("LOGOUT");
    },
    setClients(context, data) {
      const clients = data.clients.map(({ id, name, slug, groupId, Group, industryId }) => ({
        id,
        name,
        slug,
        groupId,
        Group,
        industryId,
      }));

      data.clients = clients;

      context.commit("SET_CLIENTS", data);
    },
  },
};
