export default {
  data() {
    return {};
  },
  filters: {
    toMb: (value) => {
      if (!value) {
        return "0 MB";
      }

      return `${(value / 1024 / 1024).toFixed(2)} MB`;
    },
  },
  methods: {
    withExtension(attachment) {
      const name = attachment.label || attachment.filename;

      if (!name) {
        return "";
      }

      const re = /(?:\.([^.]+))?$/;

      const extension = re.exec(name)[1];

      if (extension) {
        return name;
      }

      return `${name}.${re.exec(attachment.filename)[1]}`;
    },
  },
};
