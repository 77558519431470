<template>
  <v-app>
    <header-bar
      :userImpersonation="haveUserImpersonation"
      v-if="isAuthenticated && !guestUser && !sharedRoute"
    />
    <sidebar :showGeoIQ="showGeoIQ" v-if="isAuthenticated && !guestUser && !sharedRoute" />

    <notifications-bar v-if="isAuthenticated && !guestUser && !sharedRoute" />

    <v-main
      class="main-content"
      v-if="
        isAuthenticated ||
        !$route.meta ||
        $route.meta.requiresAuth === undefined ||
        $route.meta.requiresAuth === false
      "
    >
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-main>

    <SocketListener v-if="isAuthenticated && !guestUser && isNotPDFUser && !sharedRoute" />

    <delete-dialog />

    <update-password-modal
      v-if="
        isAuthenticated &&
        !guestUser &&
        !sharedRoute &&
        currentUser?.mustChangePassword &&
        !haveUserImpersonation
      "
      :user="currentUser"
    />

    <BudgetChangesDialog v-if="shouldShowBudgetChangeDialog" />
  </v-app>
</template>

<script>
  import DeleteDialog from "@/components/global/BaseDeleteDialog.vue";
  import BudgetChangesDialog from "@/components/layout/BudgetChangesDialog.vue";
  import SocketListener from "@/components/utilities/SocketListener.vue";

  import ClientResource from "./api/resources/ClientResource";
  import TenantResource from "./api/resources/TenantResource";
  import UserResource from "./api/resources/UserResource";
  import HeaderBar from "./views/layout/header/HeaderBar.vue";
  import NotificationsBar from "./views/layout/notificationsbar/NotificationsBar.vue";
  import UpdatePasswordModal from "./views/layout/ResetPasswordModal/UpdatePasswordModal.vue";
  import Sidebar from "./views/layout/sidebar/Sidebar.vue";

  export default {
    name: "App",
    components: {
      BudgetChangesDialog,
      SocketListener,
      NotificationsBar,
      HeaderBar,
      Sidebar,
      DeleteDialog,
      UpdatePasswordModal,
    },
    data() {
      return {
        fab: false,
        showGeoIQ: false,
      };
    },
    computed: {
      haveUserImpersonation() {
        return this.$store.getters["authorization/currentUser"].impersonatedBy;
      },
      isAuthenticated() {
        return this.$store.getters["authorization/isAuthenticated"];
      },
      currentUser() {
        return this.$store.getters["authorization/currentUser"];
      },
      guestUser() {
        return this.$store.getters["authorization/currentUser"]
          ? this.$store.getters["authorization/currentUser"].UserType.name === "Guest"
          : true;
      },
      sharedRoute() {
        return !!this.$route?.name?.includes("shared.");
      },
      shouldShowBudgetChangeDialog() {
        return (
          this.currentUser &&
          !this.currentUser?.mustChangePassword &&
          !this.haveUserImpersonation &&
          !this.guestUser
        );
      },
    },
    async created() {
      this.setTheme();
      await Promise.allSettled([
        this.getTenantDetails(),
        this.setAuthorizedClients(),
        this.isGeoIQShown(),
      ]);
    },
    mounted() {
      if (this.isAuthenticated) {
        if (this.haveUserImpersonation) {
          this.heap.identify(this.$store.getters["authorization/currentUser"].impersonatedBy);
        } else {
          const user = this.$store.getters["authorization/currentUser"];

          if (user) {
            this.heap.identify(user.id);

            this.heap.addUserProperties({
              email: user.email,
              Name: user.fullName,
              "User Type": user.UserType.name,
            });
          }
        }
      }
    },
    updated() {
      // Handles renaming the page when the route changes
      this.setPageTitle();
      this.setTheme();
    },
    methods: {
      async getTenantDetails() {
        await TenantResource.getTenantDetails()
          .then((res) => {
            const {
              id,
              name,
              slug,
              logo,
              primaryColor,
              TenantCarouselItems: tenantCarouselItems,
              partnerTenant,
            } = res.data;

            this.$store.dispatch("authorization/setTenant", {
              id,
              name,
              slug,
              logo,
              tenantCarouselItems,
              primaryColor,
              partnerTenant,
            });

            if (primaryColor) {
              this.$vuetify.theme.themes.light.primary = `#${primaryColor}`;
            }
            this.setPageTitle();
          })
          .catch(() => {
            this.$router.push({ name: "login" });
          });
      },
      async isGeoIQShown() {
        if (this.isAuthenticated && !this.guestUser && this.isNotPDFUser) {
          if (this.$store.getters["authorization/currentUser"].UserType.name === "Client") {
            const params = {
              service: "GeoIQ License",
              clientId: this.$store.getters["authorization/currentUserAuthorizedClients"].map(
                (client) => client.id
              ),
            };
            const clients = await ClientResource.getAllClientsWithBudgets(
              { ...params },
              {
                withFields: ["id"],
              }
            ).then((res) => res.data);

            this.showGeoIQ = clients.length > 0;
          } else {
            this.showGeoIQ = true;
          }
        }
      },
      async setAuthorizedClients() {
        if (this.isAuthenticated && !this.guestUser && this.isNotPDFUser) {
          await UserResource.getAuthorizedClients(this.currentUser.id).then((res) => {
            this.$store.dispatch("authorization/setClients", res.data);
          });
        }
      },
      onScroll(e) {
        if (typeof window === "undefined") {
          return;
        }
        const top = window.pageYOffset || e.target.scrollTop || 0;

        this.fab = top > 20;
      },
      toTop() {
        this.$vuetify.goTo(0);
      },
      setPageTitle() {
        const { name } = this.$store.getters["authorization/currentTenant"];

        document.title = `${this.$route.meta.title} - ${name}` || "Portal";
      },
      setTheme() {
        const { primaryColor } = this.$store.getters["authorization/currentTenant"];

        if (primaryColor) {
          this.$vuetify.theme.themes.light.primary = `#${primaryColor}`;
        } else {
          const currentUser = this.$store.getters["authorization/currentUser"];

          if (currentUser && currentUser.partner && currentUser.partner.primaryColor) {
            this.$vuetify.theme.themes.light.primary = `#${currentUser.partner.primaryColor}`;
          } else {
            this.$vuetify.theme.themes.light.primary = "#2854DF";
          }
        }
      },
    },
  };
</script>
