<template>
  <v-form v-model="value.formValid" ref="form" v-if="value">
    <v-row>
      <v-col cols="12" sm="12">
        <v-textarea
          :rules="requiredTextRule"
          v-model="value.body"
          filled
          clearable
          rows="2"
          label="Body *"
          hide-details="auto"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" xs="6" sm="6" md="6">
        <v-menu v-model="isMenuDate" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="value.publishedAt"
              hide-details
              label="Published At"
              readonly
              filled
              v-bind="attrs"
              v-on="on"
              @click:clear="value.publishedAt = null"
            ></v-text-field>
          </template>
          <v-date-picker
            :max="currentDate"
            v-model="value.publishedAt"
            @change="isMenuDate = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col lg="3" xs="6" sm="6" md="6">
        <v-switch v-model="value.isPublic" :label="`Public`" hide-details />
      </v-col>
      <v-col lg="3" xs="6" sm="6" md="6">
        <v-checkbox
          v-model="value.shouldCreatePrimaryGoal"
          label="Update Primary Goal?"
          v-if="value.id === undefined"
          hide-details
        />
      </v-col>
      <v-col lg="3" xs="6" sm="6" md="6">
        <v-checkbox
          v-model="value.shouldCreateTask"
          label="Create a Task In TaskIQ"
          v-if="value.id === undefined"
          hide-details
        />
      </v-col>
    </v-row>

    <v-row v-if="value.shouldCreatePrimaryGoal">
      <v-col cols="12">
        <v-textarea
          :rules="requiredTextRule"
          v-model="value.primaryGoal"
          filled
          label="New Primary Goal"
          hide-details="auto"
          hint="Existing primary goal for this month will be updated."
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row v-if="value.shouldCreateTask">
      <v-col cols="12">
        <create-task :interaction="value" v-model="value.taskFormValid" ref="createTaskComponent" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        v-if="value.interactionType && value.interactionType.interactionCustomFields"
      >
        <template v-for="customField in value.interactionType.interactionCustomFields">
          <v-text-field
            v-if="isType(customField.type, 'duration')"
            v-model="value.values[customField.id]"
            filled
            placeholder="HH:MM"
            maxlength="5"
            @input="formatTime(customField.id)"
            :label="`${customField.name}`"
            :rules="
              customField.isRequired ? durationTimeRule.concat(requiredTextRule) : durationTimeRule
            "
            :key="customField.id"
          ></v-text-field>
          <v-text-field
            v-if="isType(customField.type, 'text')"
            v-model="value.values[customField.id]"
            filled
            clearable
            :label="`${customField.name}`"
            :rules="customField.isRequired ? requiredTextRule : []"
            :key="customField.id"
          ></v-text-field>
          <v-textarea
            v-if="isType(customField.type, 'paragraph')"
            v-model="value.values[customField.id]"
            filled
            clearable
            :label="`${customField.name}`"
            :rules="customField.isRequired ? requiredTextRule : []"
            :key="customField.id"
          />
          <v-checkbox
            :key="customField.id"
            v-if="isType(customField.type, 'boolean')"
            v-model="value.values[customField.id]"
            :label="`${customField.name}`"
            filled
            :rules="customField.isRequired ? requiredTextRule : []"
          />
          <v-select
            :items="formatDefaultValues(customField.defaultValue)"
            item-value="name"
            item-text="name"
            v-if="isType(customField.type, 'options')"
            v-model="value.values[customField.id]"
            filled
            clearable
            :label="`${customField.name}`"
            :rules="customField.isRequired ? requiredTextRule : []"
            :key="customField.id"
          />
          <v-autocomplete
            :items="customField.users"
            item-value="id"
            item-text="fullName"
            v-if="isType(customField.type, 'related users type')"
            v-model="value.values[customField.id]"
            filled
            clearable
            :label="`${customField.name}`"
            :rules="customField.isRequired ? requiredTextRule : []"
            :key="customField.id"
            multiple
          />
          <template v-if="isType(customField.type, 'attachment')">
            <v-file-input
              v-model="value.values[customField.id]"
              :label="`${customField.name}`"
              multiple
              :key="customField.id"
              :rules="
                customField.isRequired
                  ? requiredArrayRule.concat(filesShouldBeLessThanMBRule(100))
                  : filesShouldBeLessThanMBRule(100)
              "
              small-chips
              show-size
              counter
              filled
              @change="setLabelDefaultValue(customField.id)"
            />
            <template v-for="(image, index) in value.values[customField.id]">
              <v-text-field
                :key="index"
                :label="`Description for ${image.name}`"
                v-model="value.labels[`${image.name}___${customField.id}`]"
                v-if="image.name !== undefined"
                filled
                :rules="requiredTextRule.concat(maxCharactersRule(50))"
                hide-details="auto"
                class="mb-3"
              >
                image
              </v-text-field>
            </template>
          </template>
          <div :key="customField.id" v-if="isType(customField.type, 'date')">
            <h4 class="mb-1">{{ customField.name }}</h4>
            <v-date-picker
              v-model="value.values[customField.id]"
              :title="customField.name"
              scrollable
              :rules="customField.isRequired ? requiredTextRule : []"
            />
          </div>
        </template>
        <template v-if="value.attachments && value.attachments.length > 0">
          <br />
          <v-chip-group>
            <v-chip
              close
              class="px-5"
              color="primary"
              text-color="white"
              v-for="attachment in value.attachments"
              :key="attachment.id"
              @click="downloadSecureAsset(attachment)"
              @click:close="submitDeletedAttachment(attachment.id)"
            >
              <v-icon left small>fas fa-download</v-icon>
              {{ attachment.label || attachment.filename }}
            </v-chip>
          </v-chip-group>
        </template>
      </v-col>
    </v-row>
    <small>*indicates required field</small>
  </v-form>
</template>
<script>
  import dayjs from "dayjs";

  import AssetResource from "../../../../../api/resources/AssetResource";
  import CreateTask from "../pages/components/CreateTask.vue";

  export default {
    name: "InteractionFields",
    props: ["interaction"],
    components: { CreateTask },
    data() {
      return {
        isMenuDate: false,
        value: null,
      };
    },
    computed: {
      currentDate() {
        return dayjs().format("YYYY-MM-DD");
      },
    },
    methods: {
      formatDefaultValues(values) {
        const array = [];

        values.split("\n").forEach((value) => {
          array.push({ name: value });
        });

        return array;
      },
      formatTime(customFieldId) {
        const value = this.value.values[customFieldId];

        if (value.length === 2 && value[1] === ":") {
          this.value.values[customFieldId] = `0${value[0]}:`;
        } else if (value.length === 2 && Number(value) > 24) {
          this.value.values[customFieldId] = "24:";
        } else if (value.length === 2) {
          this.value.values[customFieldId] += ":";
        } else if (value.length > 3) {
          let [hour, minutes] = value.split(":");

          if (Number(hour) > 24 || (Number(hour) === 24 && Number(minutes) > 0)) {
            hour = 23;
          }
          if (Number(minutes) > 59) {
            minutes = 59;
          }
          if (minutes === undefined) {
            minutes = "00";
          }
          this.value.values[customFieldId] = `${hour}:${minutes}`;
        }
      },
      isType(value, type) {
        return value.toLowerCase() === type;
      },
      async submitDeletedAttachment(id) {
        await AssetResource.deleteAttachment(id).then(() => {
          this.value.attachments = this.value.attachments.filter((attach) => attach.id !== id);
        });
      },
      setLabelDefaultValue(fieldId) {
        const files = this.value.values[fieldId];

        files.forEach((file) => {
          let filename = file.name;

          if (filename.length >= 50) {
            const extension = filename.split(".").pop();

            filename = `${filename.substring(0, 48 - extension.length).trim()}.${extension}`;
          }

          this.value.labels[`${file.name}___${fieldId}`] = filename;
        });
      },
    },
    watch: {
      interaction: {
        immediate: true,
        handler() {
          this.value = this.interaction;
        },
      },
    },
  };
</script>
