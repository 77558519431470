import Vue from "vue";
import Vuex from "vuex";

import modules from "./config/modules";
import plugins from "./config/plugins";
import state from "./config/state";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  state,
  modules,
  plugins,
  strict: debug,
});

if (window.Cypress) {
  // eslint-disable-next-line no-underscore-dangle
  window.__store__ = store;
}

export default store;
