import request from "../../index";

export default {
  getActiveBudgetsList(clientId, filters) {
    return request.get(`/clients/${clientId}/budgets/active`, filters);
  },
  getMTDPacingTotals(sectionId, clientIds) {
    return request.post("/clients/mtd-pacing-totals", { sectionId, clientIds });
  },
};
