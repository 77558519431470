const issueStatusesMap = {
  OPENED: {
    IN_PROGRESS: "In Progress",
    APPROVED: "Approved",
    WAITING_FOR_CONFIRMATION: "Waiting for Confirmation",
    SUBMITTED: "Submitted",
    NOT_APPROVED: "Not Approved (Postpone)",
  },
  CLOSED: {
    DONE: "Done",
    REJECTED: "Rejected",
  },
};

export default {
  data() {
    return {
      issueTypes: {
        OPENED: "opened",
        CLOSED: "closed",
        MY: "my",
      },
      issuesOpenStatuses: [...Object.values(issueStatusesMap.OPENED)],
      issuesClosedStatuses: [...Object.values(issueStatusesMap.CLOSED)],
      issueStatusesMap,
    };
  },
  methods: {
    itemStatusColor(status) {
      if (status === issueStatusesMap.OPENED.WAITING_FOR_CONFIRMATION) {
        return "error";
      }

      if (status === issueStatusesMap.OPENED.IN_PROGRESS) {
        return "warning animate-pulse";
      }

      if (status === issueStatusesMap.CLOSED.REJECTED) {
        return "error";
      }

      if (status === issueStatusesMap.CLOSED.DONE) {
        return "success";
      }

      return "secondary";
    },
  },
};
