import * as Sentry from "@sentry/vue";

import store from "../../../store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../../../views/pages/auth/Login.vue"),
    meta: {
      title: "Sign In",
      guest: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: { clientAccessible: true },
    beforeEnter: (to, from, next) => {
      store.dispatch("authorization/logout");

      Sentry.setUser(null);

      next({ name: "login" });
    },
  },
  {
    path: "/reset-password",
    name: "password.reset",
    component: () =>
      import(
        /* webpackChunkName: "password.reset" */ "../../../views/pages/auth/ResetPassword.vue"
      ),
    meta: {
      title: "Reset Your Password",
      guest: true,
    },
  },
  {
    path: "/auth/google/callback",
    name: "auth.google.callback",
    component: () =>
      import(
        /* webpackChunkName: "auth.google.callback" */ "../../../views/pages/auth/GoogleAuthentication.vue"
      ),
    meta: { guest: true, title: "Google Authentication" },
  },
  {
    path: "/guest",
    name: "guest",
    component: () => import("../../../views/pages/auth/Guest.vue"),
    meta: {
      title: "Guest Access",
      guest: true,
    },
    beforeEnter: (to, from, next) => {
      if (!to.query.token) {
        return next({ name: "login" });
      }

      return next();
    },
  },
];
