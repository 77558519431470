<template>
  <v-card class="min-h-[400px]">
    <v-card-title class="flex justify-end">
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="w-full min-h-[50vh] px-12 pb-6">
        <v-fade-transition group hide-on-leave>
          <div v-if="currentStep === 'step1'" key="step-step1">
            <div>
              <h2>Were There Any Changes?</h2>

              <p class="my-8">
                Any strategy changes or new campaign launches, large budgets shifts, etc...?
              </p>

              <div class="flex flex-row space-x-3 mt-12">
                <v-btn x-large color="success" @click="goToStep1Yes">Yes</v-btn>
                <v-btn x-large color="error" @click="goToStep1No">No</v-btn>
              </div>
            </div>
          </div>
          <div v-else-if="currentStep === 'step1-yes'" key="step-step1-yes">
            <h3>Select one or more of the following...</h3>
            <div class="mt-8">
              <v-checkbox
                v-for="option in changeOptions"
                v-model="answers.changes"
                :label="option.label"
                :value="option.value"
                :key="option.label"
                hide-details
              />
            </div>
            <v-row class="mt-12">
              <v-btn @click="currentStep = 'step1'" color="secondary">Back</v-btn>
              <v-spacer />
              <v-btn @click="switchToStep1" color="primary" :disabled="answers.changes.length < 1">
                Next
              </v-btn>
            </v-row>
          </div>
          <div v-else-if="currentStep === 'step1-yes-details'" key="step-step1-yes-details">
            <h3>Provide details for each section(s):</h3>

            <div v-for="change in answers.changes" :key="change" class="mt-8">
              <h6>{{ getLabel(changeOptions, change) }}</h6>

              <div class="p-3">
                <v-textarea
                  v-model="answers.changesData[change].text"
                  :label="`Please provide details for ${getLabel(changeOptions, change)}`"
                  filled
                  rows="3"
                />
                <v-text-field
                  v-if="canAddImage(change)"
                  v-model="answers.changesData[change].imageUrl"
                  :label="`Image Url for ${getLabel(changeOptions, change)}`"
                  filled
                />
                <v-file-input
                  v-if="canAddImage(change)"
                  v-model="answers.changesData[change].imageFile"
                  :label="`Attachments for ${getLabel(changeOptions, change)}`"
                  multiple
                  :rules="filesShouldBeLessThanMBRule(10)"
                  small-chips
                  show-size
                  counter
                  prepend-icon=""
                  prepend-inner-icon="attachment"
                  filled
                />
              </div>

              <v-divider />
            </div>
            <v-row class="mt-12">
              <v-btn @click="currentStep = 'step1-yes'" color="secondary">Back</v-btn>
              <v-spacer />
              <v-btn @click="submitFinalData" outlined color="success" class="mr-2">Done</v-btn>
              <v-btn @click="currentStep = 'step1-no'" color="primary">Next</v-btn>
            </v-row>
          </div>
          <div v-else-if="currentStep === 'step1-no'" key="step-step1-no">
            <h3>Select a time frame...</h3>
            <p>We will get the client metrics for the selected time frame.</p>
            <div class="mt-8 w-full flex">
              <div class="w-1/2">
                <v-date-picker v-model="answers.dateRange" type="date" range />
              </div>
              <div class="w-1/2">
                <v-list>
                  <v-subheader>Presets</v-subheader>
                  <v-list-item-group class="">
                    <v-list-item
                      v-for="preset in dateRangesOptions"
                      :key="preset.label"
                      @click="setDate(preset)"
                    >
                      <v-list-item-content>
                        {{ preset.label }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

                <div class="" v-if="answers.dateRange?.length > 1">
                  <p class="mt-4">
                    <strong>Selected Date Range:</strong>
                    <br />
                    {{ formatDate(answers.dateRange[0], "MM/DD/YYYY") }} -
                    {{ formatDate(answers.dateRange[1], "MM/DD/YYYY") }}
                  </p>
                </div>
              </div>
            </div>
            <v-row class="mt-12">
              <v-btn @click="goToStep1" color="secondary">Back</v-btn>
              <v-spacer />
              <v-btn
                @click="getCampaignData"
                color="primary"
                :disabled="answers.dateRange?.length !== 2"
              >
                Next
              </v-btn>
            </v-row>
          </div>
          <div v-else-if="currentStep === 'step2-loading'" key="step-step2-loading">
            <h3>Please wait while we are getting the client metrics...</h3>
            <v-progress-linear indeterminate color="primary" class="mt-8" />
          </div>
          <div v-else-if="currentStep === 'step2'" key="step-step2">
            <h3>Select any options to continue...</h3>
            <div class="mt-4" v-if="metricData">
              <div class="box mt-2">
                <div class="w-1/12">
                  <v-checkbox v-model="answers.includeConversions" hide-details />
                </div>
                <div class="w-10/12">
                  <h5>Conversions</h5>
                  <p>
                    <span class="text-subtitle-2">New</span>
                    <v-textarea v-model="answers.conversionsNewText" filled rows="3" hide-details />
                  </p>
                  <p>
                    <span class="text-subtitle-2">Used</span>
                    <v-textarea
                      v-model="answers.conversionsUsedText"
                      filled
                      rows="3"
                      hide-details
                    />
                  </p>
                </div>
              </div>
              <div class="box mt-2">
                <div class="w-1/12">
                  <v-checkbox v-model="answers.includeCpc" hide-details />
                </div>
                <div class="w-10/12">
                  <h5>Cost per click</h5>
                  <p>
                    <span class="text-subtitle-2">New</span>
                    <v-textarea v-model="answers.cpcNewText" filled rows="3" hide-details />
                  </p>
                  <p>
                    <span class="text-subtitle-2">Used</span>
                    <v-textarea v-model="answers.cpcUsedText" filled rows="3" hide-details />
                  </p>
                </div>
              </div>
              <div class="box mt-2">
                <div class="w-1/12">
                  <v-checkbox v-model="answers.includeClicks" hide-details />
                </div>
                <div class="w-10/12">
                  <h5>Clicks</h5>
                  <p>
                    <span class="text-subtitle-2">New</span>
                    <v-textarea v-model="answers.clicksNewText" filled rows="3" hide-details />
                  </p>
                  <p>
                    <span class="text-subtitle-2">Used</span>
                    <v-textarea v-model="answers.clicksUsedText" filled rows="3" hide-details />
                  </p>
                </div>
              </div>
              <div class="box mt-2">
                <div class="w-1/12">
                  <v-checkbox v-model="answers.includeCtr" hide-details />
                </div>
                <div class="w-10/12">
                  <h5>Click through rate</h5>
                  <p>
                    <span class="text-subtitle-2">New</span>
                    <v-textarea v-model="answers.ctrNewText" filled rows="3" hide-details />
                  </p>
                  <p>
                    <span class="text-subtitle-2">Used</span>
                    <v-textarea v-model="answers.ctrUsedText" filled rows="3" hide-details />
                  </p>
                </div>
              </div>

              <div class="box mt-2">
                <div class="w-1/12">
                  <v-checkbox v-model="answers.includeUsers" hide-details />
                </div>
                <div class="w-10/12">
                  <h5>Users</h5>
                  <p>
                    <v-textarea v-model="answers.usersText" filled rows="3" hide-details />
                  </p>
                </div>
              </div>

              <div class="box mt-2">
                <div class="w-1/12">
                  <v-checkbox v-model="answers.includeSessions" hide-details />
                </div>
                <div class="w-10/12">
                  <h5>Sessions</h5>
                  <p>
                    <v-textarea v-model="answers.sessionsText" filled rows="3" hide-details />
                  </p>
                </div>
              </div>
            </div>
            <v-row class="mt-12">
              <v-btn @click="currentStep = 'step1-no'" color="secondary">Back</v-btn>
              <v-spacer />
              <v-btn @click="moveToStep3" color="primary">Next</v-btn>
            </v-row>
          </div>
          <div v-else-if="currentStep === 'step3'" key="step-step3">
            <h3>Any Other Issues?</h3>
            <div class="mt-8">
              <div v-for="option in otherIssues" :key="option.label">
                <v-checkbox
                  v-model="option.value"
                  :label="option.label"
                  :value="option.value"
                  hide-details
                />
                <v-textarea
                  class="mt-3"
                  v-if="option.value"
                  rows="3"
                  v-model="option.text"
                  :label="'Provide details for ' + option.label"
                  filled
                  hide-details
                />
              </div>
            </div>
            <v-row class="mt-12">
              <v-btn @click="currentStep = 'step2'" color="secondary">Back</v-btn>
              <v-spacer />
              <v-btn @click="submitFinalData" color="primary">Done</v-btn>
            </v-row>
          </div>
        </v-fade-transition>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import dayjs from "dayjs";

  import ClientResource from "@/api/resources/ClientResource";
  import DashboardResource from "@/api/resources/DashboardResource";

  export default {
    name: "SocialActivityFlow",
    props: ["clientId"],
    data() {
      return {
        currentStep: "step1",
        loading: false,
        client: null,
        metricData: null,
        answers: {
          changes: [],
          changesData: {},
          dateRange: null,
          dateRangeLabel: null,
          includeCpc: false,
          includeClicks: false,
          includeConversions: false,
          includeCtr: false,
          includeUsers: false,
          includeSessions: false,
          cpcNewText: "",
          clicksNewText: "",
          conversionsNewText: "",
          ctrNewText: "",
          usersText: "",
          sessionsText: "",
          cpcUsedText: "",
          clicksUsedText: "",
          conversionsUsedText: "",
          ctrUsedText: "",
          conversionsTotalText: "",
        },
        changeOptions: [
          {
            label: "Added Incentives",
            value: "added-incentives",
          },
          {
            label: "Bid Strategy Change",
            value: "big-strategy-change",
          },
          {
            label: "Budget Change",
            value: "budget-change",
          },
          {
            label: "Geo Targeting Change",
            value: "geo-targeting-change",
          },
          {
            label: "Keyword Implementation Change",
            value: "keyword-implementation-change",
          },
          {
            label: "New Campaign Launch",
            value: "new-campaign-launch",
          },
          {
            label: "New Strategy Change",
            value: "new-strategy-change",
          },
          {
            label: "Updated Assets (Creative Refresh)",
            value: "updated-assets",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
        change: null,
        otherIssues: [
          {
            label: "Increase in Vehicles (e.g. twice as many)",
            text: "",
            value: false,
          },
          {
            label: "Feed Issue",
            text: "",
            value: false,
          },
          {
            label: "New Competitor",
            text: "",
            value: false,
          },
          {
            label: "Pricing",
            text: "",
            value: false,
          },
          {
            label: "Underpacing",
            text: "",
            value: false,
          },
        ],
      };
    },
    mounted() {
      this.currentStep = "step1";
      this.getClient();
    },
    computed: {
      dateRangesOptions() {
        return [
          {
            label: "Last 7 Days",
            startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
            endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          },
          {
            label: "Month to Date",
            startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
            endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          },
          {
            label: "Last 30 Days",
            startDate: dayjs().subtract(29, "day").format("YYYY-MM-DD"),
            endDate: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          },
          {
            label: "Last 60 Days",
            startDate: dayjs().subtract(59, "month").startOf("month").format("YYYY-MM-DD"),
            endDate: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
          },
          {
            label: "Last 90 Days",
            startDate: dayjs().subtract(89, "month").startOf("month").format("YYYY-MM-DD"),
            endDate: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
          },
        ];
      },
    },
    methods: {
      goToStep1() {
        this.currentStep = this.answers.changes?.length > 0 ? "step1-yes-details" : "step1";
      },
      goToStep1Yes() {
        this.currentStep = "step1-yes";
      },
      goToStep1No() {
        this.currentStep = "step1-no";
        this.answers.changes = [];
      },
      initializeChangeData() {
        this.answers.changes.forEach((change) => {
          this.$set(this.answers.changesData, change, {
            text: null,
          });

          if (this.canAddImage(change)) {
            this.$set(this.answers.changesData, change, {
              imageUrl: null,
              imageFile: null,
            });
          }
        });
      },
      canAddImage(change) {
        return (
          change === "added-incentives" ||
          change === "geo-targeting-change" ||
          change === "updated-assets"
        );
      },
      close() {
        this.$emit("close");
      },
      async getClient() {
        ClientResource.getClient(this.clientId, {
          withProfiles: true,
          withFields: ["id", "tenantId", "name", "slug"],
        }).then((res) => {
          this.client = res.data;
        });
      },
      getLabel(options, value) {
        return options.find((option) => option.value === value)?.label || "";
      },
      setDate(preset) {
        this.answers.dateRange = [preset.startDate, preset.endDate];
      },
      getPresetLabel(startDate, endDate) {
        return (
          this.dateRangesOptions.find(
            (option) => option.startDate === startDate && option.endDate === endDate
          )?.label || null
        );
      },
      compareTotalMetricsResults(metrics, fieldLabel) {
        const previousMetricTotal =
          metrics[`previous${fieldLabel}New`] + metrics[`previous${fieldLabel}Used`];
        const currentMetricTotal =
          metrics[`current${fieldLabel}New`] + metrics[`current${fieldLabel}Used`];

        return `TOTAL = Current ${currentMetricTotal} vs. Previous ${previousMetricTotal}.`;
      },
      compareMetricsResults(dateRangeLabel, metrics, field, fieldLabel) {
        let previousMetric = metrics[`previous${field}`];
        let currentMetric = metrics[`current${field}`];

        const genericFields = ["users", "sessions"];

        if (previousMetric === null && currentMetric === null) {
          if (genericFields.includes(field.toLowerCase())) {
            return `No data available for this date range.`;
          }

          return `No ${field.includes("Used") ? "used" : "new"} campaigns to pull data for.`;
        }

        if (previousMetric === null && currentMetric !== null) {
          return `There is no data to compare during this time frame.`;
        }

        if (previousMetric !== null && currentMetric === null) {
          return `There is no data during this current period to compare to the previous campaign that was active.`;
        }

        const amount = previousMetric - currentMetric;

        const percentage = Math.abs(
          previousMetric !== 0 ? (amount * 100) / previousMetric : currentMetric
        );

        let situationLabel = "";

        if (amount === 0) {
          return `The ${fieldLabel} remained the same.`;
        }

        if (amount > 0) {
          situationLabel = "decreased";
        } else if (amount < 0) {
          situationLabel = "increased";
        }

        switch (fieldLabel) {
          case "cost per click":
            previousMetric = this.formatCurrency(previousMetric, 2);
            currentMetric = this.formatCurrency(currentMetric, 2);
            break;

          case "click through rate":
            previousMetric = this.formatPercentage(previousMetric, 2);
            currentMetric = this.formatPercentage(currentMetric, 2);
            break;

          default:
            break;
        }

        if (fieldLabel === "conversions") {
          return `Comparing the ${dateRangeLabel.toLowerCase()}, the ${fieldLabel} ${situationLabel}. (Previous: ${previousMetric}, Current: ${currentMetric})`;
        }

        return `Comparing the ${dateRangeLabel.toLowerCase()}, the ${fieldLabel} ${situationLabel} by ${this.formatPercentage(
          percentage,
          2
        )}. (Previous: ${previousMetric}, Current: ${currentMetric})`;
      },
      async getCampaignData() {
        this.currentStep = "step2-loading";

        if (!this.client) {
          await this.getClient();
        }

        const requestData = [
          {
            id: this.client.id,
            profiles: this.client.profiles.map((profile) => {
              const { identifier } = profile;

              return { name: profile.ProfileType.name, identifier };
            }),
          },
        ];

        await DashboardResource.getSocialSegmentedMetrics(
          requestData,
          this.answers.dateRange[0],
          this.answers.dateRange[1],
          false
        ).then((res) => {
          this.metricData = res.data && res.data.length > 0 ? res.data[0].data : null;

          const dateRangeLabel =
            this.getPresetLabel(this.answers.dateRange[0], this.answers.dateRange[1]) ||
            "previous period";
          const metrics = {
            currentCpcNew: this.metricData?.current_period.social.new.cpc,
            previousCpcNew: this.metricData?.previous_period.social.new.cpc,
            currentCpcUsed: this.metricData?.current_period.social.used.cpc,
            previousCpcUsed: this.metricData?.previous_period.social.used.cpc,

            currentClicksNew: this.metricData?.current_period.social.new.clicks,
            previousClicksNew: this.metricData?.previous_period.social.new.clicks,
            currentClicksUsed: this.metricData?.current_period.social.used.clicks,
            previousClicksUsed: this.metricData?.previous_period.social.used.clicks,

            currentConversionsNew: this.metricData?.current_period.social.new.conversions,
            previousConversionsNew: this.metricData?.previous_period.social.new.conversions,
            currentConversionsUsed: this.metricData?.current_period.social.used.conversions,
            previousConversionsUsed: this.metricData?.previous_period.social.used.conversions,

            currentCtrNew: this.metricData?.current_period.social.new.ctr,
            previousCtrNew: this.metricData?.previous_period.social.new.ctr,
            currentCtrUsed: this.metricData?.current_period.social.used.ctr,
            previousCtrUsed: this.metricData?.previous_period.social.used.ctr,

            currentUsers: this.metricData?.current_period.analytics.users,
            previousUsers: this.metricData?.previous_period.analytics.users,

            currentSessions: this.metricData?.current_period.analytics.sessions,
            previousSessions: this.metricData?.previous_period.analytics.sessions,
          };

          this.answers.cpcNewText = this.compareMetricsResults(
            dateRangeLabel,
            metrics,
            "CpcNew",
            "cost per click"
          );
          this.answers.cpcUsedText = this.compareMetricsResults(
            dateRangeLabel,
            metrics,
            "CpcUsed",
            "cost per click"
          );
          this.answers.clicksNewText = this.compareMetricsResults(
            dateRangeLabel,
            metrics,
            "ClicksNew",
            "clicks"
          );
          this.answers.clicksUsedText = this.compareMetricsResults(
            dateRangeLabel,
            metrics,
            "ClicksUsed",
            "clicks"
          );
          this.answers.conversionsNewText = this.compareMetricsResults(
            dateRangeLabel,
            metrics,
            "ConversionsNew",
            "conversions"
          );

          this.answers.conversionsTotalText = this.compareTotalMetricsResults(
            metrics,
            "Conversions"
          );

          this.answers.conversionsUsedText = this.compareMetricsResults(
            dateRangeLabel,
            metrics,
            "ConversionsUsed",
            "conversions"
          );

          this.answers.ctrNewText = this.compareMetricsResults(
            dateRangeLabel,
            metrics,
            "CtrNew",
            "click through rate"
          );

          this.answers.ctrUsedText = this.compareMetricsResults(
            dateRangeLabel,
            metrics,
            "CtrUsed",
            "click through rate"
          );

          this.answers.usersText = this.compareMetricsResults(
            dateRangeLabel,
            metrics,
            "Users",
            "users"
          );

          this.answers.sessionsText = this.compareMetricsResults(
            dateRangeLabel,
            metrics,
            "Sessions",
            "sessions"
          );
        });

        this.currentStep = "step2";
      },
      switchToStep1() {
        this.currentStep = "step1-yes-details";
        this.initializeChangeData();
      },
      moveToStep3() {
        this.currentStep = "step3";
      },
      submitFinalData() {
        let content = "";

        this.answers.changes.forEach((key) => {
          content += `<strong>${this.getLabel(this.changeOptions, key)}</strong>`;
          content += `${
            key in this.answers.changesData
              ? `<p>${
                  this.answers.changesData[key].text || `[::Please add your comments here::]`
                }</p>`
              : "<p>[::Please add your comments here::]</p>"
          }`;
          const imageUrl = this.answers.changesData[key]?.imageUrl;

          if (imageUrl) {
            content += `<a target="_blank" href="${imageUrl}">${imageUrl}</a>`;
          }
          content += "<p></p>";
        });

        if (this.answers.includeConversions) {
          content += `<br>`;
          content += `<strong>Conversions ${this.answers.conversionsTotalText}</strong>`;
          content += `<br>`;
        }

        if (
          this.answers.includeConversions ||
          this.answers.includeCpc ||
          this.answers.includeClicks ||
          this.answers.includeCtr
        ) {
          content += `<br>`;
          content += `<b>New</b>`;
          if (this.answers.includeConversions) {
            content += `<li><b>Conversions: </b>${this.answers.conversionsNewText}</li>`;
          }
          if (this.answers.includeCpc) {
            content += `<li><b>Cost Per Click: </b>${this.answers.cpcNewText}</li>`;
          }
          if (this.answers.includeClicks) {
            content += `<li><b>Clicks: </b>${this.answers.clicksNewText}</li>`;
          }
          if (this.answers.includeCtr) {
            content += `<li><b>Click Through Rate: </b>${this.answers.ctrNewText}</li>`;
          }
          content += `</ul>`;
          content += `<br>`;
          content += `<b>Used</b>`;
          if (this.answers.includeConversions) {
            content += `<li><b>Conversions: </b>${this.answers.conversionsUsedText}</li>`;
          }
          if (this.answers.includeCpc) {
            content += `<li><b>Cost Per Click: </b>${this.answers.cpcUsedText}</li>`;
          }
          if (this.answers.includeClicks) {
            content += `<li><b>Clicks: </b>${this.answers.clicksUsedText}</li>`;
          }
          if (this.answers.includeCtr) {
            content += `<li><b>Click Through Rate: </b>${this.answers.ctrUsedText}</li>`;
          }
          content += `</ul>`;
          content += `<br>`;
        }

        if (this.answers.includeUsers) {
          content += "<strong>Users</strong>";
          content += `<ul>`;
          content += `<li>${this.answers.usersText}</li>`;
          content += `</ul>`;
          content += `<br>`;
        }

        if (this.answers.includeSessions) {
          content += "<strong>Sessions</strong>";
          content += `<ul>`;
          content += `<li>${this.answers.sessionsText}</li>`;
          content += `</ul>`;
          content += `<br>`;
        }

        this.otherIssues.forEach((otherIssue) => {
          if (otherIssue.value) {
            content += `<strong>${otherIssue.label}</strong>`;
            content += `<p>${otherIssue.text || "[::Please add your comments here::]"}</p>`;
            content += "<p></p>";
          }
        });

        const attachments = [];

        Object.values(this.answers.changesData).forEach((change) => {
          if (change.imageFile) {
            attachments.push(...change.imageFile);
          }
        });

        this.$emit("update", content, attachments);

        this.close();
      },
    },
  };
</script>

<style scoped>
  .box {
    @apply p-6 rounded-lg border border-gray-200 shadow flex flex-row space-x-4;
  }
</style>
