/* eslint-disable import/no-cycle */
import profile from "../../mixins/mapping";
import store from "../../store";
import request from "../index";

export default {
  getClientList(filters = {}) {
    return request.get("/clients", { ...filters });
  },

  getSimpleClientList(excludeDeleted = true) {
    return request.get("/clients/list/simple", { excludeDeleted }).then((res) => {
      if (excludeDeleted) {
        const noDuplicatedGroupIds = [];

        res.data
          .filter((watchlistClient) => watchlistClient.PriorityWatchlists?.length > 0)
          .forEach((watchlistClient) => {
            if (!noDuplicatedGroupIds.includes(watchlistClient.groupId)) {
              noDuplicatedGroupIds.push(watchlistClient.groupId);
            }
          });

        store.dispatch("layout/setSimpleClientList", res.data).then();
        store.dispatch("layout/setWatchlistGroupIds", noDuplicatedGroupIds).then();
      }

      return res;
    });
  },

  getListWithClientUsers() {
    return request.get("/clients/list/withClientUsers");
  },
  getClientsWithAttributes(fields, active = true) {
    return request.get(`/clients/getClientsWithAttributes?fields=${fields}&active=${active}`);
  },
  getFCAClientList(extras = {}) {
    // extras = { withProfiles: bool, withActiveBudgets: bool, withSettings: bool }
    return request.get("/clients/fca", { ...extras });
  },
  getActiveClientList(filters = {}) {
    return request.get("/clients/getActiveClientsReport", { ...filters });
  },
  getInactiveClientList(params) {
    return request.get("/clients/inactive", params);
  },
  getUsersFromClient(data) {
    return request.post("/users/getUsersFromClient", data);
  },
  getClient(slug, params = {}) {
    return request.get(`/clients/${slug}`, { ...params });
  },
  getNonProspectClientList(params) {
    return request.get(`/clients/nonProspects`, { ...params });
  },
  getClientContacts(slug) {
    return request.get(`/clients/${slug}/contacts`);
  },
  saveClientPrimaryGoal(clientSlug, goal) {
    return request.post(`/clients/${clientSlug}/primary-goals`, { goal });
  },
  deleteClientPrimaryGoal(clientSlug, id) {
    return request.delete(`/clients/${clientSlug}/primary-goals/${id}`);
  },
  getClientCompetitors(slug) {
    return request.get(`/clients/${slug}/competitors`);
  },
  getAllClientsWithProfiles(params = {}) {
    return request.get("/clients/getAllClientsWithProfiles", { ...params });
  },
  updateClient(client) {
    return request.put(`/clients/${client.id}`, client);
  },
  clientProfile(clientId) {
    return profile.getProfilesForClient(clientId);
  },
  getClientSales(clientId, startDate = null, endDate = null) {
    return request.get(`/clients/${clientId}/sales`, { startDate, endDate });
  },
  getClientLeads(clientId, startDate = null, endDate = null) {
    return request.get(`/clients/${clientId}/leads`, { startDate, endDate });
  },
  getClientBudgets(clientId, params) {
    return request.get(`/clients/${clientId}/client-budgets`, params);
  },
  getClientsByServiceGroup(params) {
    return request.post(`/clients/getClientsByServiceGroup`, params);
  },
  getClientBudgetBreakout(clientId, params) {
    return request.get(`/clients/${clientId}/budgets`, params);
  },
  getAllClientsWithBudgets(params, queryParams = {}) {
    // Params for budgets are clientId(single id/array), service(name/id), serviceGroup(name/id), month, year, scope(active/inactive)
    return request.post("/clients/getAllClientsWithBudgets", params, {
      params: { ...queryParams },
    });
  },
  getClientScripts(slug) {
    return request.get(`/clients/${slug}/scripts`);
  },
  getAllCancelledClients(params) {
    return request.get(`/clients/reports/getAllCancellations`, params);
  },
  getCredentialTypesDictionary() {
    return request.get("/credential-types");
  },
  getCredentialScriptTypesDictionary() {
    return request.get("/script-types");
  },
  postClientCredential(params) {
    return request.post("/credentials", params);
  },
  deleteClientCredential(id) {
    return request.delete(`/credentials/${id}`);
  },
  updateClientCredential(id, params) {
    return request.put(`/credentials/${id}`, params);
  },
  postClientScript(params) {
    return request.post("/scripts", params);
  },
  deleteClientScript(id) {
    return request.delete(`/scripts/${id}`);
  },
  updateClientScript(id, params) {
    return request.put(`/scripts/${id}`, params);
  },
  getClientCredentials(id) {
    return request.get(`/clients/${id}/credentials`);
  },
  setClientDefaultValues(id, params) {
    return request.post(`/clients/${id}/setDefaultValue`, params);
  },
  getManyClients(body, queryParams = {}) {
    return request.post("/clients/getManyClients", body, { params: { ...queryParams } });
  },
  assignTeamMember(clientId, params) {
    const { clientUserTypeId, userId } = params;
    const teamMembers = [];
    const teamMember = {
      clientUserTypeId,
      userId,
    };

    teamMembers.push(teamMember);

    return request.post("/clients/assignTeamMembers", { clientId, teamMembers });
  },
  unassignTeamMember(clientId, params) {
    const { clientUserTypeId, userId } = params;
    const teamMembers = [];
    const teamMember = {
      clientUserTypeId,
      userId,
    };

    teamMembers.push(teamMember);

    return request.post("/clients/removeTeamMembers", { clientId, teamMembers });
  },

  getClientYoYLeads(clientId, years) {
    return request.get(`/clients/${clientId}/yoy/leads`, { years });
  },
  getClientYoYInternetLeads(clientId, years) {
    return request.get(`/clients/${clientId}/yoy/internet-leads`, { years });
  },
  getClientYoYSetAppointment(clientId, years) {
    return request.get(`/clients/${clientId}/yoy/set-appointments`, { years });
  },
  getClientYoYShownAppointment(clientId, years) {
    return request.get(`/clients/${clientId}/yoy/shown-appointments`, { years });
  },
  getClientYoYSoldAppointment(clientId, years) {
    return request.get(`/clients/${clientId}/yoy/sold-appointments`, { years });
  },
  getClientYoYSales(clientId, years) {
    return request.get(`/clients/${clientId}/yoy/sales`, { years });
  },
  getUtilizationReport(month, year, ids, type) {
    return request.get(
      `/clients/reports/serviceUtilization?month=${month}&year=${year}&ids=${ids}&type=${type}`
    );
  },
  getActiveSettings() {
    return request.get("/clients/settings", { active: true });
  },
  getSetting(setting) {
    return request.get("/clients/settings", { setting });
  },
  getClientSettings(clientId, setting = null) {
    return request.get(`/clients/${clientId}/settings`, { setting });
  },
  updateClientSetting(data) {
    return request.post(`/clients/${data.clientId}/settings`, data);
  },
  cancelClient(clientId, params) {
    return request.put(`/clients/${clientId}/cancelNow`, params);
  },
  getAllByUserId(params) {
    return request.post(`/clients/getAllByUserId`, params);
  },
  getDashboardLinks(clientId) {
    return request.get(`/clients/${clientId}/dashboard-links`);
  },
  createdDashboardLink(link) {
    return request.post(`/clients/${link.clientId}/dashboard-links`, { link });
  },
  updateDashboardLink(link) {
    return request.put(`/clients/${link.clientId}/dashboard-links/${link.id}`, { link });
  },
  deleteDashboardLink(clientId, linkId) {
    return request.delete(`/clients/${clientId}/dashboard-links/${linkId}`);
  },
  getImplementationNotes(clientId) {
    return request.get(`/clients/${clientId}/implementation-notes`);
  },
  updateImplementationNote(data, clientId) {
    return request.put(`/clients/${clientId}/implementation-notes/${data.id}`, data);
  },
  createImplementationNote(data, clientId) {
    return request.post(`/clients/${clientId}/implementation-notes`, data);
  },
  getClientBuyers(clientId, params) {
    return request.get(`/clients/${clientId}/firstDataMapping`, { ...params });
  },
  getUserRelatedClients(userId) {
    return request.get(`/user/get-clients/${userId}`);
  },
  assignNewUserForClients(newUser, prevUserId) {
    return request.post(`/user/assign-new-clients-use`, { newUser, userId: prevUserId });
  },
  getSaleIndustryTypes(id) {
    return request.get(`/sale-types/industry/${id}`);
  },
  getLeadIndustryTypes(id) {
    return request.get(`/lead-types/industry/${id}`);
  },
  getAppointmentIndustryTypes(id) {
    return request.get(`/appointment-types/industry/${id}`);
  },
  postClientSales(params) {
    return request.post("/sales", params);
  },
  deleteClientSales(id) {
    return request.delete(`/sales/${id}`);
  },
  updateClientSales(id, params) {
    return request.put(`/sales/${id}`, params);
  },
  getClientAppointments(id) {
    return request.get(`/clients/${id}/appointments`);
  },
  postClientLeads(params) {
    return request.post("/leads", params);
  },
  postClientAppointments(params) {
    return request.post("/appointments", params);
  },
  updateClientLeads(id, params) {
    return request.put(`/leads/${id}`, params);
  },
  updateClientAppointments(id, params) {
    return request.put(`/appointments/${id}`, params);
  },
  getClientSalesDataReport(year) {
    return request.get(`/sales/report?year=${year}`);
  },
  findByClientIds(clientIds, month) {
    return request.post("/sales/findByClientIds", { clientIds, month });
  },

  // Deliverable Templates
  getDeliverableTemplates() {
    return request.get(`/deliverable-templates`);
  },
  createDeliverableTemplate(params) {
    return request.post("/deliverable-templates", params);
  },
  updateDeliverableTemplate(params) {
    return request.put(`/deliverable-templates/${params.id}`, params);
  },
  deleteDeliverableTemplate(id) {
    return request.delete(`/deliverable-templates/${id}`);
  },

  // Deliverable Types
  getDeliverableTypes() {
    return request.get(`/deliverable-types`);
  },
  createDeliverableType(params) {
    return request.post("/deliverable-types", params);
  },
  updateDeliverableType(params) {
    return request.put(`/deliverable-types/${params.id}`, params);
  },

  // Deliverable Categories
  getDeliverableCategories() {
    return request.get(`/deliverable-categories`);
  },
  createDeliverableCategory(params) {
    return request.post("/deliverable-categories", params);
  },
  updateDeliverableCategory(params) {
    return request.put(`/deliverable-categories/${params.id}`, params);
  },

  // Deliverable Statuses
  getDeliverableStatuses() {
    return request.get(`/deliverable-statuses`);
  },
  createDeliverableStatus(params) {
    return request.post("/deliverable-statuses", params);
  },
  updateDeliverableStatus(params) {
    return request.put(`/deliverable-statuses/${params.id}`, params);
  },

  // Deliverables
  getClientsWithDeliverables(params) {
    return request.get(`/deliverables/allClientsWithDeliverables`, params);
  },
  getClientDeliverables(params) {
    return request.get(`/deliverables/client`, params);
  },
  createDeliverable(params) {
    return request.post("/deliverables", params);
  },
  createDeliverableInBulk(deliverables) {
    return request.post("/deliverables/create-bulk-action", { deliverables });
  },
  deleteDeliverable(id) {
    return request.delete(`/deliverables/${id}`);
  },
  updateDeliverable(params) {
    return request.put(`/deliverables/${params.id}`, params);
  },
  updateDeliverableStatusInBulk(params) {
    return request.post(`/deliverables/update-bulk-status`, params);
  },

  // Cancellation Reasons
  getCancellationReasons() {
    return request.get("/cancellation-reasons");
  },
  createCancellationReason(params) {
    return request.post(`/cancellation-reasons`, params);
  },
  updateCancellationReason(params) {
    return request.put(`/cancellation-reasons/${params.id}`, params);
  },
  deleteCancellationReason(id) {
    return request.delete(`/cancellation-reasons/${id}`);
  },

  // Achievements
  getAllAchievements(params) {
    return request.get(`/achievements`, params);
  },
  createAchievement(params) {
    return request.post("/achievements", params);
  },
  deleteAchievement(id) {
    return request.delete(`/achievements/${id}`);
  },
  updateAchievement(params) {
    return request.put(`/achievements/${params.id}`, params);
  },
  approveAchievement(params) {
    return request.post(`/achievements/${params.id}/approve`, params);
  },
  rejectAchievement(params) {
    return request.post(`/achievements/reject`, params);
  },
  getAchievementRejections(params) {
    return request.get(`/achievements/${params.id}/rejections`);
  },

  // Client Tags
  getAllTags(withClientTags = false) {
    return request.get(`/client-tags?withClientTags=${withClientTags}`);
  },
  createTag(params) {
    return request.post("/client-tags", params);
  },
  deleteTag(id) {
    return request.delete(`/client-tags/${id}`);
  },
  updateTag(params) {
    return request.put(`/client-tags/${params.id}`, params);
  },
  getTagsByClientId(clientId) {
    return request.get(`/client-tags/client/${clientId}`);
  },
  assignClientTag(clientId, tags) {
    return request.post(`/client-tags/assign/${clientId}`, tags);
  },
  unassignClientTag(id) {
    return request.get(`/client-tags/unassign/${id}`);
  },
};
