var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.isAuthenticated && !_vm.guestUser && !_vm.sharedRoute)?_c('header-bar',{attrs:{"userImpersonation":_vm.haveUserImpersonation}}):_vm._e(),(_vm.isAuthenticated && !_vm.guestUser && !_vm.sharedRoute)?_c('sidebar',{attrs:{"showGeoIQ":_vm.showGeoIQ}}):_vm._e(),(_vm.isAuthenticated && !_vm.guestUser && !_vm.sharedRoute)?_c('notifications-bar'):_vm._e(),(
      _vm.isAuthenticated ||
      !_vm.$route.meta ||
      _vm.$route.meta.requiresAuth === undefined ||
      _vm.$route.meta.requiresAuth === false
    )?_c('v-main',{staticClass:"main-content"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1),_c('v-btn',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("keyboard_arrow_up")])],1)],1):_vm._e(),(_vm.isAuthenticated && !_vm.guestUser && _vm.isNotPDFUser && !_vm.sharedRoute)?_c('SocketListener'):_vm._e(),_c('delete-dialog'),(
      _vm.isAuthenticated &&
      !_vm.guestUser &&
      !_vm.sharedRoute &&
      _vm.currentUser?.mustChangePassword &&
      !_vm.haveUserImpersonation
    )?_c('update-password-modal',{attrs:{"user":_vm.currentUser}}):_vm._e(),(_vm.shouldShowBudgetChangeDialog)?_c('BudgetChangesDialog'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }