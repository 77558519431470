import request from "../../index";

export default {
  getClientActivity(slug, activityTypeId, startDate = null, endDate = null) {
    return request.get(`/clients/${slug}/activities`, { activityTypeId, startDate, endDate });
  },
  getClientActivityWithPagination(slug, activityTypeId, params, startDate = null, endDate = null) {
    return request.get(`/clients/${slug}/paginated-activities`, {
      activityTypeId,
      startDate,
      endDate,
      ...params,
    });
  },
  getAllActivities(startDate = null, endDate = null) {
    return request.get("/activities", {
      startDate,
      endDate,
    });
  },
  findActivitiesByClientIds(clientIds, startDate, endDate) {
    return request.post("/activities/findByClientIds", { clientIds, startDate, endDate });
  },
  createActivity(activity) {
    return request.post("/activities", activity, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  updateActivity(activity) {
    return request.post(`/activities/update`, activity, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteActivity(activityId) {
    return request.delete(`/activities/${activityId}`);
  },

  getActivityTypes() {
    return request.get("/activity-types");
  },
  createActivityType(activityType) {
    return request.post("/activity-types", activityType);
  },
  updateActivityType(activityTypeId, activityType) {
    return request.put(`/activity-types/${activityTypeId}`, activityType);
  },
  deleteActivityType(activityTypeId) {
    return request.delete(`/activity-types/${activityTypeId}`);
  },
  getActivityCategories() {
    return request.get("/categories");
  },
  createActivityCategory(category) {
    return request.post("/categories", category);
  },
  updateActivityCategory(categoryId, category) {
    return request.put(`/categories/${categoryId}`, category);
  },
  deleteActivityCategory(categoryId) {
    return request.delete(`/categories/${categoryId}`);
  },

  getUserPerformance(date, typeId) {
    return request.get("/activityReports/userPerformance", { date, typeId });
  },
  getClientActivityReport(date, excludeNonProgramClients = false) {
    return request.get("/activityReports/clientReport", { date, excludeNonProgramClients });
  },
  sendActivityEmail(data) {
    return request.post(`/activityReports/send-activity-email`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
};
