<template>
  <div class="mb-5 text-white pa-3 opaque-bg">
    <div class="flex justify-between align-center">
      <span class="font-weight-bold text-h6 my-0">{{ event.summary }}</span>

      <v-btn icon @click="openUrl(event.htmlLink)">
        <v-icon small>fas fa-external-link-alt</v-icon>
      </v-btn>
    </div>
    <p class="subtitle-1 mb-0">Starts {{ relativeTime(event.start.dateTime) }}</p>
  </div>
</template>

<script>
  export default {
    name: "EventListItem",
    props: ["event"],
  };
</script>

<style scoped>
  .opaque-bg {
    background: rgba(255, 255, 255, 0.25);
    border-radius: 0.375rem;
  }
</style>
