import request from "../../index";

export default {
  getServiceGroupList(withRelations = true) {
    return request.get("/serviceGroups", { withRelations });
  },
  postServiceGroupData(serviceGroup) {
    return request.post("/serviceGroups", serviceGroup);
  },
  updateServiceGroup(serviceGroup, id) {
    return request.put(`/serviceGroups/${id}`, serviceGroup);
  },
  getServiceGroupById(id) {
    return request.get(`/serviceGroups/${id}`);
  },
  deleteServiceGroupById(id) {
    return request.delete(`/serviceGroups/${id}`);
  },
};
