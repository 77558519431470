<template>
  <v-dialog :value="true" persistent width="85%" max-width="950px">
    <v-card class="pb-8">
      <v-card-title class="flex justify-between">
        <span class="headline">Log Client Interaction</span>
        <span @click="$emit('handleClose')">
          <i class="fas fa-times text-gray-400 hover:text-gray-500 cursor-pointer"></i>
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="text-center mt-10" v-if="loading">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </div>
          <template v-else>
            <v-select
              filled
              :items="interactionTypes"
              item-text="name"
              item-value="id"
              v-model="interaction.interactionType"
              @change="(event) => changeInteractionType(event)"
              label="Interaction Type"
              required
            ></v-select>
            <v-autocomplete
              :items="clients"
              filled
              item-text="name"
              return-object
              v-model="interaction.client"
              label="Client"
              required
            ></v-autocomplete>
            <interaction-form
              :clientUsers="interaction.client.ClientUsers"
              v-if="interaction.interactionType && interaction.client"
              :interaction="interaction"
              @handleClose="handleClose"
            />
          </template>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import dayjs from "dayjs";

  import ClientResource from "@/api/resources/ClientResource";
  import InteractionTypesResource from "@/api/resources/InteractionTypesResource";

  import InteractionForm from "../../../pages/clients/profile/components/InteractionForm.vue";

  export default {
    name: "ClientInteraction",
    components: { InteractionForm },
    data: () => ({
      dialog: false,
      associatedStores: [],
      interactionTypes: [],
      interaction: {
        client: null,
        associatedStoreIds: [],
        interactionType: {},
        formValid: false,
        values: {},
        isPublic: true,
        labels: {},
        publishedAt: dayjs().format("YYYY-MM-DD"),
      },
      loading: false,
      clients: [],
      interactionType: null,
    }),
    created() {
      this.fetchInitialData();
    },
    computed: {
      excludedClient() {
        return this.associatedStores.filter((client) => client.id !== this.interaction.client?.id);
      },
    },
    methods: {
      changeInteractionType(interactionTypeId) {
        this.interaction.interactionType = this.interactionTypes.find(
          (interactionType) => interactionType.id === interactionTypeId
        );
      },
      async getClients() {
        this.clients = await ClientResource.getListWithClientUsers().then((res) => res.data);
      },
      async handleClose() {
        this.$emit("handleClose");
      },
      async getInteractionTypes() {
        this.interactionTypes = await InteractionTypesResource.getInteractionTypes().then(
          (res) => res.data
        );
      },
      async fetchInitialData() {
        this.loading = true;

        await Promise.allSettled([this.getInteractionTypes(), this.getClients()]);

        this.loading = false;
      },
    },
  };
</script>
