export default {
  methods: {
    calculateSum(numbers = []) {
      if (!Array.isArray(numbers) || numbers.length === 0) {
        return 0;
      }

      return numbers.reduce((s, n) => s + n);
    },
    calculateMarkup(amount, markupPercentages = []) {
      try {
        const markupAmounts = Array.from(new Set([...markupPercentages]));

        return markupAmounts.reduce((sum, markup) => {
          sum *= (markup + 100) / 100;

          return sum;
        }, amount);
      } catch (error) {
        console.error(error);

        return 0.0;
      }
    },
  },
};
