<template>
  <div class="relative select-none">
    <p class="absolute strength" :class="'level_' + description.level"></p>
    <p class="absolute text" :class="'text_' + description.level">
      {{ description.text }}
    </p>
  </div>
</template>
<script>
  import { zxcvbn } from "@zxcvbn-ts/core";

  export default {
    props: {
      value: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        descriptions: [
          { level: 0, text: "Very weak password" },
          { level: 1, text: "Weak password" },
          { level: 2, text: "Medium password" },
          { level: 3, text: "Good password" },
          { level: 4, text: "Strong password" },
        ],
      };
    },
    computed: {
      score() {
        const hasValue = this.value && this.value.length > 0;

        if (!hasValue) {
          return 0;
        }

        const score = zxcvbn(this.value).score + 1;

        this.$emit("score", score);

        return score;
      },
      description() {
        return this.value && this.value.length > 0 && this.descriptions[this.score - 1];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .strength {
    height: 10px;
    border-radius: 40px;
    transition: all 0.5s linear;
  }
  .text {
    left: 50%;
    transform: translate(-50%, 0);
    top: -0.5rem;
  }
  @mixin strengthLevel($position, $width, $color) {
    bottom: $position;
    width: $width;
    background-color: $color;
  }
  @mixin textColor($color) {
    color: $color;
  }
  .level_0 {
    @include strengthLevel(0, 20%, #d0413c);
  }
  .level_1 {
    @include strengthLevel(0, 40%, #d25921);
  }
  .level_2 {
    @include strengthLevel(0, 60%, #e18330);
  }
  .level_3 {
    @include strengthLevel(0, 80%, #f0b03f);
  }
  .level_4 {
    @include strengthLevel(0, 100%, #4caf50);
  }
  .text_0 {
    @include textColor(#d0413c);
  }
  .text_1 {
    @include textColor(#d25921);
  }
  .text_2 {
    @include textColor(#e18330);
  }
  .text_3 {
    @include textColor(#f0b03f);
  }
  .text_4 {
    @include textColor(#4caf50);
  }
</style>
