import accounting from "./accounting";
import admin from "./admin";
import agreements from "./agreements";
import auth from "./auth";
import clients from "./clients";
import dashboard from "./dashboard";
import reporting from "./reporting";
import settings from "./settings";
import shared from "./shared";
import siteiq from "./siteiq";
import support from "./support";
import taskiq from "./task-iq";
import tools from "./tools";
import users from "./users";

export default [
  ...auth,
  ...dashboard,
  ...clients,
  ...agreements,
  ...accounting,
  ...siteiq,
  ...reporting,
  ...users,
  ...settings,
  ...taskiq,
  ...tools,
  ...admin,
  ...shared,
  ...support,

  {
    path: "*",
    name: "error.page_not_found",
    component: () =>
      import(
        /* webpackChunkName: "error.page_not_found" */
        "../../views/pages/system/PageNotFound.vue"
      ),
    meta: { title: "Page Not Found" },
  },
];
