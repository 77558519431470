import request from "../index";

export default {
  getTenantDetails() {
    const { hostname } = new URL(window.location.href);

    return request.post(`/tenants/${hostname.split(".")[0]}`, { hostname });
  },
  getTenantAuthServices(tenantId) {
    return request.get(`/tenants/auth/${tenantId}`);
  },
  verifyGoogleAuthCallbackToken(tenantId, code) {
    return request.get(`/tenants/auth/${tenantId}/google/callback/?code=${code}`);
  },
  verifyGoogleAccessToken(tenantId, accessToken) {
    return request.post(`/tenants/auth/${tenantId}/google/verify`, { accessToken });
  },
  getTenantSettings(setting = null) {
    return request.get("/tenants/settings", { setting });
  },
  updateTenantSetting(setting, value) {
    return request.post("/tenants/settings", { setting, value });
  },

  // Guest Access Link Related Routes
  verifyGuessAccessToken(token) {
    return request.post("/guest-accesses/verify", { token });
  },
  getGuessAccessLinks(clientId = null) {
    return request.get(`/guest-accesses?clientId=${clientId || ""}`);
  },
  createGuessAccess(accessDetails) {
    return request.post("/guest-accesses", { accessDetails });
  },
  revokeGuessAccess(id) {
    return request.delete(`/guest-accesses/${id}`);
  },
  requestGuestAccess(details) {
    return request.post("/guest-accesses/request", details);
  },
  getTenantCarouselItems() {
    return request.get("/tenants/carousel");
  },
  uploadTenantCarouselItem(params) {
    return request.post("/tenants/carousel", params, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteTenantCarouselItem(id) {
    return request.delete(`/tenants/carousel/${id}`);
  },
};
