import request from "../index";

export default {
  getStaticAsset(url, params = {}, options = { responseType: "arraybuffer" }) {
    return request.get(url, params, options);
  },
  uploadAttachment(formData) {
    return request.post(`/assets/upload`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteAttachment(assetId) {
    return request.delete(`/assets/${assetId}`);
  },
  getByAttachableType(params) {
    return request.post(`/override-file/getByAttachableType`, params);
  },
  uploadAsset(file) {
    return request.post(`/override-file/uploadAsset`, file, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  deleteByAttachableId(id) {
    return request.delete(`/override-file/${id}`);
  },
  getFacebookAds(ads, force, format) {
    return request.post(
      "/assets/facebook/ads",
      { ...ads, force, format },
      {
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      }
    );
  },
};
