export default [
  {
    path: "/shared",
    component: () => import("../../../views/pages/auth/Shared.vue"),
    children: [
      {
        path: "coop-files/:publicId",
        name: "shared.coop-files",
        component: () => import("../../../views/pages/shared/CoopFiles.vue"),
        meta: {
          title: "Co-Op Files",
          searchable: false,
          clientAccessible: true,
        },
        beforeEnter: (to, from, next) => {
          if (!to.params.publicId) {
            return next({ name: "login" });
          }

          return next();
        },
      },
    ],
  },
  {
    path: "/ad-preview/:previewId",
    name: "shared.ad-preview",
    component: () => import("../../../views/pages/shared/AdPreview.vue"),
    meta: {
      title: "Ad Preview",
      searchable: false,
      guest: true,
    },
    beforeEnter: (to, from, next) => {
      if (!to.params.previewId || Number.isNaN(Number(to.params.previewId))) {
        return next({ name: "login" });
      }

      return next();
    },
  },
];
