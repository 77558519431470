export default [
  {
    path: "/budgets",
    name: "accounting.index",
    component: () =>
      import(
        /* webpackChunkName: "accounting.index" */ "../../../views/pages/accounting/Accounting.vue"
      ),
    children: [
      {
        path: "active-budgets",
        name: "activeBudgets.index",
        component: () =>
          import(
            /* webpackChunkName: "activeBudgets.index" */
            "../../../views/pages/budgets/active_budgets/ActiveBudgetsIndex.vue"
          ),
        meta: {
          title: "Active Budgets",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Accounting",
        },
      },
      {
        title: "Change Budgets",
        name: "budgetChange.index",
        path: "change-budgets/:slug",
        component: () =>
          import(
            /* webpackChunkName: "budgetChange.index" */
            "../../../views/pages/budgets/budget_change/index/BudgetChangeIndexPage.vue"
          ),
        meta: {
          title: "Change Budgets",
          requiresAuth: true,
        },
      },
      {
        path: "create",
        name: "budgets.create",
        component: () =>
          import(
            /* webpackChunkName: "budgets.create" */
            "../../../views/pages/budgets/create/BudgetCreatePage.vue"
          ),
        meta: {
          title: "Create Budget",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Accounting",
        },
      },
      {
        path: "create/:slug",
        name: "budgets.clientCreate",
        component: () =>
          import(
            /* webpackChunkName: "budgets.clientCreate" */
            "../../../views/pages/budgets/create/BudgetCreatePage.vue"
          ),
        meta: {
          title: "Create Budget",
          requiresAuth: true,
        },
      },
      {
        path: ":id/edit",
        name: "budgets.edit",
        component: () =>
          import(
            /* webpackChunkName: "budgets.edit" */
            "../../../views/pages/budgets/edit/BudgetEditPage.vue"
          ),
        meta: {
          title: "Edit Budget",
          requiresAuth: true,
        },
      },
      {
        path: "client_view",
        name: "clientView.index",
        component: () =>
          import(
            /* webpackChunkName: "clientView.index" */
            "../../../views/pages/budgets/client_view/ClientViewIndex.vue"
          ),
        meta: {
          title: "Client Budgets",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Accounting",
        },
      },
      {
        path: "inactive-budgets",
        name: "inactiveBudgets.index",
        component: () =>
          import(
            /* webpackChunkName: "inactiveBudgets.index" */
            "../../../views/pages/budgets/inactive/InactiveBudgetsIndex.vue"
          ),
        meta: {
          title: "Inactive Budgets",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Accounting",
        },
      },
      {
        path: "historical-budgets",
        name: "historicalBudgets.index",
        component: () =>
          import(
            /* webpackChunkName: "historicalBudgets.index" */
            "../../../views/pages/budgets/historical/HistoricalBudgetsIndex.vue"
          ),
        meta: {
          title: "Historical Budgets",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Accounting",
        },
      },
      {
        path: "upcoming-cancellations",
        name: "cancellations.index",
        component: () =>
          import(
            /* webpackChunkName: "cancellations.index" */
            "../../../views/pages/budgets/upcoming/UpcomingCancellations.vue"
          ),
        meta: {
          title: "Upcoming Cancellations",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Accounting",
        },
      },
      {
        path: "budget-reconciliation",
        name: "budgetReconciliation.index",
        component: () =>
          import(
            /* webpackChunkName: "budgetReconciliation.index" */
            "../../../views/pages/budgets/budget_reconciliation/BudgetReconciliationIndex.vue"
          ),
        meta: {
          title: "Budget Reconciliation",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Accounting",
        },
      },
      {
        path: "quick-view",
        name: "quickView.index",
        component: () =>
          import(
            /* webpackChunkName: "quickView.index" */
            "../../../views/pages/budgets/quick_view/QuickViewIndex.vue"
          ),
        meta: {
          title: "Budget Quick View",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Accounting",
        },
      },
      {
        path: "budget-comparison",
        name: "budgetComparison.index",
        component: () =>
          import("../../../views/pages/budgets/budget_comparison/BudgetComparisonIndex.vue"),
        meta: {
          title: "Budget Comparison",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Accounting",
        },
      },
      {
        path: "/allocations",
        component: () => import("../../../views/pages/budgets/allocations/BudgetAllocations.vue"),
        children: [
          {
            path: "",
            name: "budgetAllocations.index",
            component: () =>
              import(
                /* webpackChunkName: "budgetAllocations.index" */
                "../../../views/pages/budgets/allocations/index/BudgetAllocationsIndex.vue"
              ),
            meta: {
              title: "Budget Allocations",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Accounting",
            },
          },
          {
            path: "social",
            name: "budgetAllocations.social",
            component: () =>
              import(
                /* webpackChunkName: "budgetAllocations.social" */
                "../../../views/pages/budgets/allocations/social/SocialBudgetAllocations.vue"
              ),
            meta: {
              title: "Social Budget Allocation",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Accounting",
            },
          },
          {
            path: "premium/display",
            name: "budgetAllocations.premium.display",
            component: () =>
              import(
                /* webpackChunkName: "budgetAllocations.premium.display" */
                "../../../views/pages/budgets/allocations/premium/PremiumDisplayBudgetAllocations.vue"
              ),
            meta: {
              title: "Premium Display Budget Allocation",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Accounting",
            },
          },
          {
            path: "premium/video",
            name: "budgetAllocations.premium.video",
            component: () =>
              import(
                /* webpackChunkName: "budgetAllocations.premium.video" */
                "../../../views/pages/budgets/allocations/premium/PremiumVideoBudgetAllocations.vue"
              ),
            meta: {
              title: "Premium Video Budget Allocation",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Accounting",
            },
          },
          {
            path: "paid-search",
            name: "budgetAllocations.paid-search",
            component: () =>
              import(
                /* webpackChunkName: "budgetAllocations.paidSearch" */
                "../../../views/pages/budgets/allocations/paid_search/PaidSearch.vue"
              ),
            meta: {
              title: "Paid Search Budget Allocation",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Accounting",
            },
          },
        ],
      },
      {
        path: "parent-organizations",
        component: () =>
          import("../../../views/pages/settings/parent_organizations/ParentOrganizationsPage.vue"),
        children: [
          {
            path: "",
            name: "parentOrganizations.index",
            component: () =>
              import(
                "../../../views/pages/settings/parent_organizations/index/ParentOrganizationsIndex.vue"
              ),
            meta: {
              title: "Parent Organizations",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Accounting",
            },
          },
        ],
      },
    ],
  },
];
