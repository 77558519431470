import Vue from "vue";
import VueRouter from "vue-router";

import { ignoredErrors } from "../helpers/error";
import store from "../store";
import routes from "./routes";

Vue.use(VueRouter);

/*
 * Here is we silence Navigation Duplicated & Navigation Canceled errors firing a sentry event
 * It's inspired from https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function checkRoles(route) {
  if (route.meta && route.meta.requiredRoles) {
    const { roles } = store.getters["authorization/currentUser"];

    return !!(
      roles.includes("Super Administrator") ||
      roles.includes("Administrator") ||
      roles.some((role) => route.meta.requiredRoles.includes(role))
    );
  }

  return true;
}

const publicRoutes = [
  "login",
  "password.reset",
  "auth.google.callback",
  "taskiq.login",
  "guest",
  "shared.coop-files",
  "shared.ad-preview",
];
const pdfRoutes = ["clients.insights", "co-op.seo", "clients.seo-division-report"];
const publicUserTypes = ["Client", "Partner", "Regional Manager"];
let pathInfo;

router.beforeEach(async (to, from, next) => {
  store.dispatch("layout/setCurrentRouteName", to.name);

  pathInfo = {
    fromFullPath: from.fullPath,
    toFullPath: to.fullPath,
    retry: to.query.retry ? to.query.retry : 0,
  };

  if (to.query && to.query.token && pdfRoutes.includes(to.name)) {
    // if token is invalid it will redirect to login page
    await store.dispatch("authorization/login", {
      accessToken: to.query.token,
      UserType: { name: "Guest" },
    });

    return next();
  }

  // Check authentication
  const isAuthorized = store.getters["authorization/isAuthenticated"];

  if (!isAuthorized && !publicRoutes.includes(to.name)) {
    return next({ name: "login", query: { redirect: to.fullPath } });
  }

  if (isAuthorized && to.name === "login") {
    return next({ name: "dashboard.monthly-report" });
  }

  // check roles
  if (!checkRoles(to)) {
    return next({ name: "dashboard.monthly-report" });
  }

  // if user is a client, check if route is accessible
  if (isAuthorized && !to.meta?.guest) {
    const { UserType } = store.getters["authorization/currentUser"];
    const authorizedClients = store.getters["authorization/currentUserAuthorizedClients"].map(
      (client) => client.slug
    );

    if (
      publicUserTypes.includes(UserType.name) &&
      !to.meta?.clientAccessible &&
      !to.meta?.partnerAccessible
    ) {
      return next({ name: "dashboard.monthly-report" });
    }

    if (
      publicUserTypes.includes(UserType.name) &&
      to.name === "clients.insights" &&
      !authorizedClients.includes(to.params.slug)
    ) {
      return next({ name: "dashboard.monthly-report" });
    }
  }

  return next();
});

router.onError((error) => {
  if (ignoredErrors.some((ignoredError) => error.message?.match(ignoredError))) {
    if (Number(pathInfo.retry) === 0) {
      const url = new URL(pathInfo.toFullPath, window.location.origin);

      url.searchParams.set("retry", "1");
      url.searchParams.set("from", encodeURIComponent(pathInfo.fromFullPath));

      window.location.href = url.href;
    } else {
      try {
        const url = new URL(pathInfo.toFullPath, window.location.origin);
        const from = url.searchParams.get("from");

        if (from) {
          window.location.href = decodeURIComponent(from);
        } else {
          window.location.href = "/?refresh=1";
        }
      } catch (err) {
        window.location.href = "/?refresh=0";
      }
    }
  }
});

export default router;
