<template>
  <v-navigation-drawer
    app
    fixed
    width="340"
    mobile-breakpoint="960"
    right
    dark
    class="soft-shadowed-left notifications-bar pa-8"
    v-model="isNotificationMenuOpen"
    v-if="showNotificationBar"
    :style="navBarStyles"
  >
    <div class="flex justify-between items-center">
      <h1 class="text-h4 font-weight-light">Your Day</h1>

      <v-btn icon class="mb-4">
        <v-icon color="white" @click="handleCloseNotificationMenu">far fa-window-close</v-icon>
      </v-btn>
    </div>

    <p class="font-weight-light my-3">{{ this.currentDate }}</p>

    <task-list class="mt-4" />

    <event-list />
  </v-navigation-drawer>
</template>

<script>
  import dayjs from "dayjs";
  import tinyColor from "tinycolor2";

  import EventList from "./components/EventList/EventList.vue";
  import TaskList from "./components/TaskList/TaskList.vue";

  export default {
    name: "NotificationsBar",
    components: { EventList, TaskList },
    methods: {
      handleCloseNotificationMenu() {
        this.$store.dispatch("layout/closeNotificationMenu");
      },
    },
    computed: {
      navBarStyles() {
        const color = tinyColor(this.$vuetify.theme.themes.light.primary);

        return `background: linear-gradient(180deg, ${color.darken(20)} 0%, ${color.lighten(
          30
        )} 100%);`;
      },
      isNotificationMenuOpen: {
        get() {
          return this.$store.getters["layout/isNotificationMenuOpen"];
        },
        set(newValue) {
          this.$store.dispatch("layout/setNotificationMenuStatus", newValue);
        },
      },
      currentDate() {
        return dayjs().format("MMMM DD, YYYY");
      },
      showNotificationBar() {
        return (
          this.isNotPDFUser &&
          this.$store.getters["authorization/currentUser"].UserType.name === "Employee"
        );
      },
    },
  };
</script>

<style scoped>
  ::v-deep ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .notifications-bar {
    @apply fixed text-white right-0 top-0 h-screen overflow-y-scroll w-full md:w-[356px] z-[100] pb-[600px] transition-transform duration-200;
  }
</style>
