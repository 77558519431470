<template>
  <div></div>
</template>
<script>
  export default {
    name: "SocketListener",

    computed: {
      currentUser() {
        return this.$store.getters["authorization/currentUser"];
      },
    },

    created() {
      this.$socket.client.connect();
    },

    sockets: {
      connect() {
        this.$socket.client.emit("online", {
          userId: this.currentUser.id,
          socketId: this.$socket.client.id,
        });
      },
    },

    beforeDestroy() {
      this.$socket.client.disconnect();
    },
  };
</script>
