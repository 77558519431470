export default [
  {
    path: "/admin",
    component: () => import("../../../views/pages/admin/Admin.vue"),
    children: [
      {
        path: "helpers",
        name: "admin.helpers",
        component: () =>
          import(
            /* webpackChunkName: "admin.helpers" */
            "../../../views/pages/admin/helpers/AdminHelpers.vue"
          ),
        meta: {
          title: "Admin Helpers",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Tools",
          requiredRoles: ["Super Administrator", "Administrator"],
        },
      },
      {
        path: "audit-logs",
        name: "admin.audit-logs",
        component: () =>
          import(
            /* webpackChunkName: "admin.audit" */
            "../../../views/pages/admin/audit/AuditLogs.vue"
          ),
        meta: {
          title: "Audit Logs",
          requiresAuth: true,
          searchable: false,
          requiredRoles: ["Super Administrator", "Administrator"],
        },
      },
    ],
  },
];
