export default [
  {
    path: "/",
    component: () => import("../../../views/pages/dashboard/Dashboard.vue"),
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
    children: [
      // user Type 1 Sections
      {
        path: "",
        name: "dashboard.monthly-report",
        component: () =>
          import("../../../views/pages/dashboard/pages/monthly/DashboardMonthlyPage.vue"),
        meta: { title: "Dashboard", clientAccessible: true },
      },
      {
        path: "onboarded",
        name: "dashboard.onboarded-report",
        component: () =>
          import("../../../views/pages/dashboard/pages/onboarded/DashboardRecentlyOnboarded.vue"),
        meta: { title: "Dashboard" },
      },
      {
        path: "pacing",
        name: "dashboard.pacing",
        component: () =>
          import("../../../views/pages/dashboard/pages/pacing/DashboardPacingPage.vue"),
        meta: { title: "Dashboard" },
      },
      {
        path: "account-management-dashboard",
        name: "dashboard.account-management",
        component: () =>
          import("../../../views/pages/dashboard/pages/am/DashboardAccountManagement.vue"),
        meta: {
          title: "Account Management Dashboard",
          requiredRoles: ["Super Administrator", "Administrator", "Account Manager"],
        },
      },
      {
        path: "social-dashboard",
        name: "dashboard.social",
        component: () => import("../../../views/pages/dashboard/pages/social/DashboardSocial.vue"),
        meta: { title: "Social Dashboard" },
      },
      {
        path: "sem-dashboard",
        name: "dashboard.sem",
        component: () => import("../../../views/pages/dashboard/pages/sem/DashboardSEM.vue"),
        meta: { title: "SEM Dashboard" },
      },
      {
        path: "programmatic-dashboard",
        name: "dashboard.programmatic",
        component: () =>
          import("../../../views/pages/dashboard/pages/programmatic/DashboardProgrammatic.vue"),
        meta: { title: "Programmatic Dashboard" },
      },
      {
        path: "seo-dashboard",
        name: "dashboard.seo",
        component: () => import("../../../views/pages/dashboard/pages/seo/DashboardSEO.vue"),
        meta: { title: "SEO Dashboard" },
      },
      {
        path: "team-dashboard",
        name: "dashboard.team",
        component: () => import("../../../views/pages/dashboard/pages/team/DashboardTeam.vue"),
        meta: { title: "Team Dashboard" },
      },

      //  User Type 1 Sections End
    ],
  },
];
