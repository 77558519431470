<template>
  <div>
    <p class="font-weight-bold mt-4 mb-0">
      <v-btn class="px-0" text @click="openTask">{{ title }}</v-btn>
    </p>
    <p class="font-weight-light">{{ client }}</p>
    <p class="mt-1">
      <v-chip color="primary" class="mr-4">{{ formattedPriority }}</v-chip>
      <v-chip color="info">Due {{ relativeTime(due) }}</v-chip>
    </p>
  </div>
</template>

<script>
  export default {
    name: "TaskListItem",
    props: ["title", "client", "priority", "due", "id"],
    methods: {
      openTask() {
        window.open(`${process.env.VUE_APP_TASK_IQ_CLIENT_PROXY}/tasks/${this.id}`, "_blank");
      },
    },
    computed: {
      formattedPriority() {
        return this.priority;
      },
    },
  };
</script>
