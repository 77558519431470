export default [
  {
    path: "/support-requests/:issueKey",
    component: () =>
      import(
        /* webpackChunkName: "support" */
        "../../../components/utilities/components/SupportRequestDialog.vue"
      ),
    meta: {
      title: "Support Request",
      requiresAuth: true,
    },
  },
];
