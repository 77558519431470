export default {
  namespaced: true,
  state: {
    currentRouteName: "",
    isNavigationMenuCollapsed: false,
    isMobileMenuOpen: true,
    isNotificationMenuOpen: false,
    simpleClientList: [],
    watchlistGroupIds: [],
    isSubheaderOpen: true,
  },
  getters: {
    currentRouteName: (state) => {
      return state.currentRouteName;
    },
    isNavigationMenuCollapsed: (state) => {
      return state.isNavigationMenuCollapsed;
    },
    isMobileMenuOpen: (state) => {
      return state.isMobileMenuOpen;
    },
    isNotificationMenuOpen: (state) => {
      return state.isNotificationMenuOpen;
    },
    simpleClientList: (state) => {
      return state.simpleClientList;
    },
    watchlistGroupIds: (state) => {
      return state.watchlistGroupIds;
    },
    isSubheaderOpen: (state) => {
      return state.isSubheaderOpen;
    },
  },
  mutations: {
    SET_CURRENT_ROUTE_NAME(state, routeName) {
      state.currentRouteName = routeName;
    },
    TOGGLE_NOTIFICATION_MENU_COLLAPSE(state) {
      state.isNavigationMenuCollapsed = !state.isNavigationMenuCollapsed;
    },
    TOGGLE_MOBILE_MENU(state) {
      state.isMobileMenuOpen = !state.isMobileMenuOpen;
    },
    OPEN_MOBILE_MENU(state) {
      state.isMobileMenuOpen = true;
    },
    CLOSE_MOBILE_MENU(state) {
      state.isMobileMenuOpen = false;
    },
    TOGGLE_NOTIFICATION_MENU(state) {
      state.isNotificationMenuOpen = !state.isNotificationMenuOpen;
    },
    OPEN_NOTIFICATION_MENU(state) {
      state.isNotificationMenuOpen = true;
    },
    CLOSE_NOTIFICATION_MENU(state) {
      state.isNotificationMenuOpen = false;
    },
    SET_NOTIFICATION_MENU_STATUS(state, status) {
      state.isNotificationMenuOpen = status;
    },
    SET_SIMPLE_CLIENT_LIST(state, clients) {
      state.simpleClientList = clients;
    },
    SET_WATCHLIST_GROUP_IDS(state, groupIds) {
      state.watchlistGroupIds = groupIds;
    },
    TOGGLE_SUBHEADER(state) {
      state.isSubheaderOpen = !state.isSubheaderOpen;
    },
  },
  actions: {
    setCurrentRouteName(context, routeName) {
      context.commit("SET_CURRENT_ROUTE_NAME", routeName);
    },
    toggleNotificationMenuCollapse(context) {
      context.commit("TOGGLE_NOTIFICATION_MENU_COLLAPSE");
    },
    toggleMobileMenu(context) {
      context.commit("TOGGLE_MOBILE_MENU");
    },
    openMobileMenu(context) {
      context.commit("OPEN_MOBILE_MENU");
    },
    closeMobileMenu(context) {
      context.commit("CLOSE_MOBILE_MENU");
    },
    toggleNotificationMenu(context) {
      context.commit("TOGGLE_NOTIFICATION_MENU");
    },
    openNotificationMenu(context) {
      context.commit("OPEN_NOTIFICATION_MENU");
    },
    closeNotificationMenu(context) {
      context.commit("CLOSE_NOTIFICATION_MENU");
    },
    setNotificationMenuStatus(context, status) {
      context.commit("SET_NOTIFICATION_MENU_STATUS", status);
    },
    setSimpleClientList(context, clients) {
      context.commit("SET_SIMPLE_CLIENT_LIST", clients);
    },
    setWatchlistGroupIds(context, groupIds) {
      context.commit("SET_WATCHLIST_GROUP_IDS", groupIds);
    },
    toggleSubheader(context) {
      context.commit("TOGGLE_SUBHEADER");
    },
  },
};
