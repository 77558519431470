<template>
  <jira-issue-helper
    ref="helper"
    :project-key="projectKey"
    :issue-types="issueTypes"
    :current-user="currentUser"
    title="Submit A Request"
    @issueTypeChange="onIssueTypeChange"
    @submitForm="submitForm"
  >
    <template #description>
      <v-alert dense border="left" type="warning">
        Please check open requests before submitting a new one.
      </v-alert>
    </template>
    <template v-slot:before-description="{ fields }">
      <div class="flex space-x-4 w-full">
        <select
          type="text"
          placeholder="Priority"
          name="priority"
          required
          v-model="priority"
          @change="fields['Priority'] = priority"
          style="width: 27%"
        >
          <option value="" disabled>Priority</option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>

        <input
          type="text"
          placeholder="Application Area (Ex: Insights Report, Pacing Report, etc.)"
          required
          name="applicationArea"
          v-model="fields['Application Area']"
          style="width: 76%"
        />
      </div>
    </template>

    <template v-slot:after-description="{ fields }">
      <div v-if="selectedIssueType === 'Bug'" class="flex flex-col space-y-3">
        <div>
          <v-label>Related Clients</v-label>
          <v-card class="mt-1">
            <v-autocomplete
              class="without-shadow m-3"
              :items="clients"
              v-model="fields['Related Clients']"
              clearable
              hide-details
              multiple
              :item-value="(item) => `${item.name} (ID: ${item.id})`"
              :item-text="(item) => `${item.name} (ID: ${item.id})`"
              label="Clients"
            />
          </v-card>
        </div>
        <div>
          <v-label>
            Steps to Produce
            <sup>*</sup>
          </v-label>
          <v-textarea
            class="mt-1"
            solo
            hide-details
            required
            label="Provide us the steps to produce the issue"
            v-model="fields['Steps to Produce']"
          />
        </div>
        <div>
          <v-label>
            Expected Behavior
            <sup>*</sup>
          </v-label>
          <v-textarea
            class="mt-1"
            solo
            hide-details
            required
            label="What should be the expected behavior?"
            v-model="fields['Expected Behavior']"
          />
        </div>
        <div>
          <v-label>
            Actual Behavior
            <sup>*</sup>
          </v-label>
          <v-textarea
            class="mt-1"
            solo
            hide-details
            required
            label="What happens instead?"
            v-model="fields['Actual Behavior']"
          />
        </div>
      </div>
    </template>
  </jira-issue-helper>
</template>

<script>
  import JiraIssueHelper from "@clickheredigital/jira-issue-helper";

  import ClientResource from "@/api/resources/ClientResource";
  import JiraResource from "@/api/resources/JiraResource";

  export default {
    name: "IssueHelper",
    components: {
      JiraIssueHelper,
    },
    data() {
      return {
        issueTypes: ["Bug", "New Feature"],
        priority: "Medium",
        clients: [],
        selectedIssueType: "null",
      };
    },
    async created() {
      this.clients = await ClientResource.getClientList({
        withFields: ["id", "name"],
        withGroup: true,
      }).then((res) => res.data);
    },
    computed: {
      projectKey() {
        if (process.env.NODE_ENV === "production") {
          return "PS"; // Production Support
        }

        return "TEST";
      },
      currentUser() {
        const user = this.$store.getters["authorization/currentUser"];

        return {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          fullName: user.fullName,
          email: user.email,
        };
      },
    },
    methods: {
      submitForm(issue) {
        JiraResource.createIssue(issue)
          .then(() => {
            this.showSuccessNotification(
              "Thanks for your input. Your issue has been successfully sent."
            );

            this.$emit("success");
          })
          .catch(() => {
            this.$refs.helper?.updateStatus(
              "There was an issue sending your request. Please try again.",
              false
            );

            this.$refs.helper?.toggleLoading();
          });
      },
      onIssueTypeChange(issueType) {
        this.selectedIssueType = issueType;
      },
    },
  };
</script>
<style scoped>
  .without-shadow div input {
    box-shadow: none !important;
  }
</style>
