<template>
  <v-app-bar app color="white" extension-height="80" class="soft-shadowed" height="80">
    <v-icon class="align-self-center hidden-lg-and-up mr-6" @click="toggleSidebar">
      fa fa-bars
    </v-icon>

    <v-toolbar-title class="hidden-xs-only">
      <router-link :to="{ name: 'dashboard.monthly-report' }">
        <img alt="Portal Logo" :src="logo" class="max-h-[50px]" />
      </router-link>
    </v-toolbar-title>

    <template
      v-if="
        ['Employee', 'Partner'].includes(
          this.$store.getters['authorization/currentUser'].UserType.name
        ) && isNotPDFUser
      "
    >
      <v-spacer />

      <v-autocomplete
        v-if="this.$store.getters['authorization/currentUser'].UserType.name === 'Employee'"
        v-model="searchInput"
        :search-input.sync="searchTerm"
        clearable
        filled
        rounded
        hide-details
        placeholder="Search"
        :items="searchItems"
        item-text="name"
        :no-filter="true"
        return-object
        :menu-props="{ closeOnContentClick: true }"
      >
        <template v-slot:item="data">
          <v-list-item @click="selectSearchResult(data.item)">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.group"
                v-if="searchTerm"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>

      <action-menu />

      <template v-if="$store.getters['authorization/currentUser'].UserType.name === 'Employee'">
        <v-btn
          class="mr-1"
          icon
          href="https://sites.google.com/clickherepublishing.com/chp/"
          target="_blank"
        >
          <v-icon small>fa fa-info-circle</v-icon>
        </v-btn>

        <v-btn icon @click="existingRequestsDialog = true" class="mr-1">
          <v-icon small color="indigo darken-4">fas fa-question-circle</v-icon>
        </v-btn>

        <v-dialog
          v-model="existingRequestsDialog"
          fullscreen
          width="95%"
          max-width="1200"
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-btn
              absolute
              right
              @click="existingRequestsDialog = false"
              icon
              color="secondary lighten-3"
              class="mt-6"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <support-requests v-if="existingRequestsDialog" />
          </v-card>
        </v-dialog>
      </template>
      <v-btn
        class="mr-1"
        icon
        color="warning"
        @click="toggleNotificationBar"
        v-if="this.$store.getters['authorization/currentUser'].UserType.name === 'Employee'"
      >
        <v-icon small>far fa-bell</v-icon>
      </v-btn>
    </template>
    <template v-if="userImpersonation" #extension>
      <v-container class="bg-orange-500 rounded">
        <span class="font-bold">Caution!</span>
        You are impersonating {{ $store.getters["authorization/currentUserFullName"] }}
        <v-btn color="grey darken-4 white--text" class="ml-2 font-bold" @click="stopImpersonating">
          Stop Impersonating
        </v-btn>
      </v-container>
    </template>
  </v-app-bar>
</template>

<script>
  import dayjs from "dayjs";
  import fuzzysort from "fuzzysort";
  import _ from "lodash";

  import ClientResource from "@/api/resources/ClientResource";
  import UserResource from "@/api/resources/UserResource";
  import { EventBus } from "@/bootstrap/EventBus";
  import SupportRequests from "@/components/utilities/SupportRequests.vue";
  import processAuth from "@/helpers/auth";
  import ActionMenu from "@/views/layout/header/components/ActionMenu.vue";

  export default {
    name: "HeaderBar",
    components: { SupportRequests, ActionMenu },
    props: ["userImpersonation"],
    data() {
      return {
        clients: [],
        searchInput: null,
        searchTerm: "",
        existingRequestsDialog: false,
      };
    },
    computed: {
      logo() {
        const tenant = this.$store.getters["authorization/currentTenant"];
        const currentUser = this.$store.getters["authorization/currentUser"];

        if (tenant && tenant.partnerTenant === true) {
          return tenant.logo;
        }

        // eslint-disable-next-line global-require
        let logo = require(`@/assets/new-logo.png`);

        if (
          currentUser &&
          currentUser.UserType.name === "Partner" &&
          currentUser.partner &&
          currentUser.partner.logo &&
          currentUser.partner.logo.url
        ) {
          logo = currentUser.partner.logo.url;
        }

        return logo;
      },
      clientSearchItems() {
        const clients = this.$store.getters["layout/simpleClientList"];

        return clients.map((client) => {
          let { name } = client;

          if (client.identifier) {
            name = `${client.name} <small class="text-gray-600">(Dealer Code: ${client.identifier})</small>`;
          }

          if (client.canceledAt && dayjs().isAfter(client.canceledAt)) {
            name = `<span class="text-gray-600">${name}</span>`;
          }

          return {
            name,
            group:
              client.activatedAt && dayjs().isAfter(client.activatedAt) ? "Client" : "Prospect",
            route: {
              name: "clients.profile.activity",
              params: { slug: client.slug },
            },
          };
        });
      },
      searchItems() {
        let searchItems = [{ header: "Clients" }];

        searchItems = searchItems.concat(this.clientSearchItems);
        searchItems.push({ divider: true });

        if (this.$store.getters["authorization/currentUser"].UserType.name === "Employee") {
          searchItems = searchItems.concat(this.getRouteSearches());
        }
        if (this.searchTerm) {
          searchItems = fuzzysort
            .go(this.searchTerm, searchItems, { key: "name", threshold: -50000 })
            .map((el) => el.obj);
        }

        if (searchItems.length === 1 && searchItems[0].group === "Client") {
          const { name, route } = searchItems[0];
          const data = [
            {
              name: `${name} Client Insights (This Month)`,
              group: "Reporting",
              route: {
                name: "clients.insights",
                params: route.params,
              },
            },
            {
              name: `${name} Client Insights (Last Month)`,
              group: "Reporting",
              route: {
                name: "clients.insights",
                params: route.params,
                query: {
                  startDate: dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
                  endDate: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
                },
              },
            },
            {
              name: `${name} Budgets`,
              group: "Accounting",
              route: {
                name: "clients.profile.budgets",
                params: route.params,
              },
            },
            {
              name: `${name} Integrations`,
              group: "Client Profile",
              route: {
                name: "clients.profile.integrations",
                params: route.params,
              },
            },
          ];

          searchItems = [...searchItems, ...data];
        }

        return searchItems;
      },
    },
    async created() {
      if (this.isNotPDFUser) {
        await this.fetchInitialData();
      }
    },
    watch: {
      "$route.name": function routeName(val) {
        if (val !== "clients.profile.activity") {
          this.$nextTick(() => {
            this.searchInput = null;
          });
        }
      },
    },
    methods: {
      async stopImpersonating() {
        const userId = this.$store.getters["authorization/currentUser"].impersonatedBy;

        await UserResource.loginWithImpersonation(userId, userId).then(async (res) => {
          await processAuth(res.data, this.$router, this.$route);
          this.$vuetify.theme.themes.light.primary = "#2854DF";
        });
      },
      async fetchInitialData() {
        const isEmployee = this.$store.getters["authorization/isEmployee"];

        if (isEmployee) {
          ClientResource.getSimpleClientList().then((res) => {
            this.clients = res.data;
          });

          this.clients = this.clients.filter(
            (client) =>
              client.canceledAt >= dayjs().format("YYYY-MM-DD") || client.canceledAt === null
          );
        }
      },
      getRouteSearches() {
        const routes = this.$router.getRoutes();
        let allItems = [];
        let routeSearches = [];
        const { roles } = this.$store.getters["authorization/currentUser"];

        routes.forEach((route) => {
          if (
            route.meta &&
            route.meta.searchable &&
            (route.meta.requiredRoles
              ? roles?.some((role) => route.meta.requiredRoles.includes(role))
              : true)
          ) {
            routeSearches.push({
              name: route.meta.title,
              group: route.meta.searchGroup,
              route: {
                path: route.path,
              },
            });
          }
        });

        routeSearches = _.groupBy(_.orderBy(routeSearches, "group"), "group");

        Object.keys(routeSearches).forEach((group) => {
          allItems.push({ header: group });

          allItems = allItems.concat(routeSearches[group]);

          allItems.push({ divider: true });
        });

        return allItems;
      },
      toggleNotificationBar() {
        this.$store.dispatch("layout/toggleNotificationMenu");
      },
      toggleSidebar() {
        EventBus.$emit("openSidebar");
      },
      selectSearchResult(result) {
        if (result && result.route) {
          this.$nextTick(() => {
            this.searchInput = null;
            this.searchTerm = "";
          });

          this.$router.push(result.route);
        }
      },
    },
  };
</script>
