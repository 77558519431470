export default [
  {
    path: "/agreements",
    component: () => import("../../../views/pages/agreements/AgreementsPage.vue"),
    children: [
      {
        path: "",
        name: "agreements.index",
        component: () => import("../../../views/pages/agreements/index/AgreementsIndexPage.vue"),
        meta: {
          title: "Agreements",
          requiresAuth: true,
          searchable: false,
        },
      },
      {
        path: "create",
        name: "agreements.create",
        component: () => import("../../../views/pages/agreements/create/AgreementsCreatePage.vue"),
        meta: {
          title: "Create Agreement",
          requiresAuth: true,
          searchable: false,
        },
      },
    ],
  },
];
