import axios from "axios";
import axiosRetry from "axios-retry";
import download from "downloadjs";

import axiosRetryConfig from "../config/axios-retry";
import store from "../store";

const axiosInstance = axios.create({});

axiosRetry(axiosInstance, axiosRetryConfig);

export default {
  coreUrl: process.env.VUE_APP_API_GATEWAY_HOST,

  /**
   * Http Default
   */
  http: axiosInstance,

  /**
   * Fetch Api Token from store
   */
  updateAccessToken() {
    this.http.defaults.headers.common.Authorization = `Bearer ${store.getters["authorization/currentUserAccessToken"]}`;

    this.http.defaults.headers.common.ImpersonatedBy =
      store.getters["authorization/currentUser"]?.impersonatedBy || null;
  },
  checkTokenOnExpires(err) {
    if (err.response && (err.response.status === 401 || err.response.data === "Unauthenticated")) {
      store.dispatch("authorization/logout").then(() => {
        if (window.location.pathname !== "/login") {
          const redirectPath = window.location.pathname + window.location.search;

          window.location = `/login?redirect=${encodeURIComponent(redirectPath)}`;
        }
      });
    }
  },

  /**
   * Get Api App route
   *
   * @param additional
   * @returns {string}
   */
  apiRoute(additional) {
    this.updateAccessToken();

    return this.coreUrl + additional;
  },

  get(route, params = {}, options = {}, skipTokenCheck = false) {
    const that = this;

    return new Promise((resolve, reject) => {
      that.http
        .get(that.apiRoute(route), { params, ...options })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.error("Api Get Request error");
          console.error(e);

          if (!skipTokenCheck) {
            this.checkTokenOnExpires(e);
          }

          reject(e);
        });
    });
  },

  post(route, data = {}, options = {}, skipTokenCheck = false) {
    const that = this;

    return new Promise((resolve, reject) => {
      that.http
        .post(that.apiRoute(route), data, options)
        .then((res) => {
          // EventBus.$emit(`${route }-created`, res.data);
          resolve(res);
        })
        .catch((e) => {
          console.error("Api POST Request error");
          console.error(e);

          if (!skipTokenCheck) {
            this.checkTokenOnExpires(e);
          }

          reject(e);
        });
    });
  },

  put(route, data, options = {}, skipTokenCheck = false) {
    const that = this;

    return new Promise((resolve, reject) => {
      that.http
        .put(that.apiRoute(route), data, options)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.error("Api PUT Request error");
          console.error(e);

          if (!skipTokenCheck) {
            this.checkTokenOnExpires(e);
          }

          reject(e);
        });
    });
  },

  patch(route, data, options = {}, skipTokenCheck = false) {
    const that = this;

    return new Promise((resolve, reject) => {
      that.http
        .patch(that.apiRoute(route), data, options)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.error("Api PATCH Request error");
          console.error(e);

          if (!skipTokenCheck) {
            this.checkTokenOnExpires(e);
          }

          reject(e);
        });
    });
  },

  delete(route, skipTokenCheck = false) {
    const that = this;

    return new Promise((resolve, reject) => {
      that.http
        .delete(that.apiRoute(route))
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.error("Api Delete Request error");
          console.error(e);

          if (!skipTokenCheck) {
            this.checkTokenOnExpires(e);
          }

          reject(e);
        });
    });
  },

  async postAgreements(route, data, options = {}, skipTokenCheck = false) {
    const that = this;

    return new Promise((resolve, reject) => {
      that.http
        .post(that.apiRoute(route), data, { ...options, responseType: "arraybuffer" })
        .then((response) => {
          const content = response.headers["content-type"];

          download(response.data, "Agreement.pdf", content);
          resolve(response);
        })
        .catch((e) => {
          console.error("Agreement error");
          console.error(e);

          if (!skipTokenCheck) {
            this.checkTokenOnExpires(e);
          }

          reject(e);
        });
    });
  },
};
