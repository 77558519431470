<template>
  <v-dialog v-model="dialog" persistent max-width="600px" :overlay-opacity="0.9">
    <v-card class="p-6">
      <v-card-title>
        <span class="text-h5 text-red-600 font-bolder">You Must Update Your Password Now!</span>
      </v-card-title>
      <v-card-text>
        <v-container class="my-3">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                label="Password"
                required
                filled
                :append-icon="show ? 'visibility_off' : 'visibility'"
                @click:append="() => (show = !show)"
                :type="!show ? 'password' : 'text'"
              ></v-text-field>
              <PasswordScore
                v-if="password"
                :value="password"
                class="mt-1"
                @score="(val) => (score = val)"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="confirmPassword"
                label="Confirm Password"
                required
                filled
                :append-icon="show ? 'visibility_off' : 'visibility'"
                @click:append="() => (show = !show)"
                :type="!show ? 'password' : 'text'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          :disabled="loading || score < 3"
          depressed
          color="primary"
          @click="updateUser"
        >
          Update Password
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import UserResource from "@/api/resources/UserResource";
  import PasswordScore from "@/components/utilities/PasswordScore.vue";

  export default {
    name: "UpdatePasswordModal",
    props: ["user"],
    components: { PasswordScore },
    data() {
      return {
        dialog: true,
        password: null,
        confirmPassword: null,
        show: false,
        loading: false,
        score: 0,
      };
    },
    computed: {
      passwordConfirmed() {
        return this.password.length > 0 && this.password === this.confirmPassword;
      },
    },
    methods: {
      async updateUser() {
        try {
          if (this.password && this.confirmPassword && this.passwordConfirmed) {
            this.loading = true;
            const user = await UserResource.update(this.user.id, { password: this.password }).then(
              (res) => res.data
            );

            if (user) {
              await this.$store.dispatch("authorization/updateUser", {
                mustChangePassword: user.mustChangePassword,
              });

              this.showSuccessNotification("Successfully updated password");

              this.dialog = false;
            }
          } else {
            this.showErrorNotification("Passwords do not match");
          }
        } catch (err) {
          this.showErrorNotification(err.response?.data ? err.response?.data : undefined);
        } finally {
          this.loading = false;
        }
      },
    },
  };
</script>
