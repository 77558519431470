export default [
  {
    path: "/siteiq/contacts",
    name: "siteiq.contacts",
    component: () => import("../../../views/pages/site-iq/contacts/SiteIQContacts.vue"),
    meta: {
      title: "SiteIQ",
      requiresAuth: true,
      clientAccessible: true,
      searchable: true,
      searchGroup: "SiteIQ",
    },
  },
  {
    path: "/siteiq/contacts/:id",
    name: "siteiq.record",
    props: true,
    component: () => import("../../../views/pages/site-iq/contact/SiteIQContact.vue"),
    meta: {
      title: "SiteIQ Contact",
      requiresAuth: true,
      clientAccessible: true,
    },
  },
  {
    path: "/siteiq/intelligence",
    name: "siteiq.intelligence",
    component: () => import("../../../views/pages/site-iq/intelligence/SiteIQIntelligence.vue"),
    meta: {
      title: "SiteIQ Intelligence",
      requiresAuth: true,
      clientAccessible: true,
      searchable: true,
      searchGroup: "SiteIQ",
    },
  },
  {
    path: "/geoiq",
    name: "reporting.geoiq",
    component: () =>
      import(
        /* webpackChunkName: "reporting.geoiq" */
        "../../../views/pages/reporting/geo-iq/GeoIQReport.vue"
      ),
    meta: {
      title: "GeoIQ Reporting",
      requiresAuth: true,
      clientAccessible: true,
      searchable: true,
      searchGroup: "SiteIQ",
    },
  },
];
