export default [
  {
    path: "/clients",
    component: () => import("../../../views/pages/clients/ClientsPage.vue"),
    children: [
      {
        path: "",
        name: "clients.index",
        component: () =>
          import(
            /* webpackChunkName: "clients.index" */
            "../../../views/pages/clients/index/ClientsIndexPage.vue"
          ),
        meta: {
          title: "Clients",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Clients",
        },
      },
      {
        path: "/inactive",
        name: "clients.inactive",
        component: () =>
          import(
            /* webpackChunkName: "clients.inactive" */
            "../../../views/pages/clients/inactive/InactiveClientsPage.vue"
          ),
        meta: {
          title: "Inactive Clients",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Clients",
        },
      },
      {
        path: "/client-team",
        name: "clients.clientTeam",
        component: () =>
          import(
            /* webpackChunkName: "clients.clientTeam" */
            "../../../views/pages/clients/client-team/ClientTeamPage.vue"
          ),
        meta: {
          title: "Client Teams",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Clients",
        },
      },
      {
        path: "/clients/assessments",
        name: "clients.assessments",
        component: () =>
          import(
            /* webpackChunkName: "clients.assessments" */
            "../../../views/pages/clients/assessments/ClientAssessments.vue"
          ),
        meta: {
          title: "Client Assessments",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Clients",
        },
      },
      {
        path: ":slug",
        name: "clients.profile",
        component: () => import("../../../views/pages/clients/profile/ClientsProfilePage.vue"),
        meta: {
          title: "Client Profile",
          requiresAuth: true,
        },
        children: [
          {
            path: "contacts",
            name: "clients.profile.contacts",
            component: () =>
              import("../../../views/pages/clients/profile/pages/ClientContacts.vue"),
            meta: {
              title: "Client Profile",
              requiresAuth: true,
            },
          },
          {
            path: "credentials",
            name: "clients.profile.credentials",
            component: () =>
              import("../../../views/pages/clients/profile/pages/ClientCredentials.vue"),
            meta: {
              title: "Client Profile",
              requiresAuth: true,
            },
          },
          {
            path: "activity",
            name: "clients.profile.activity",
            component: () =>
              import("../../../views/pages/clients/profile/pages/ClientActivity.vue"),
            meta: {
              title: "Client Profile",
              requiresAuth: true,
            },
          },
          {
            path: "competitors",
            name: "clients.profile.competitors",
            component: () =>
              import("../../../views/pages/clients/profile/pages/ClientCompetitors.vue"),
            meta: {
              title: "Client Profile",
              requiresAuth: true,
            },
          },
          {
            path: "integrations",
            name: "clients.profile.integrations",
            component: () =>
              import("../../../views/pages/clients/profile/pages/ClientIntegrations.vue"),
            meta: {
              title: "Client Profile",
              requiresAuth: true,
            },
          },
          {
            path: "users",
            name: "clients.profile.users",
            component: () => import("../../../views/pages/clients/profile/pages/ClientUsers.vue"),
            meta: {
              title: "Client Profile",
              requiresAuth: true,
            },
          },
          {
            path: "team",
            name: "clients.profile.team",
            component: () => import("../../../views/pages/clients/profile/pages/ClientTeam.vue"),
            meta: {
              title: "Client Team",
              requiresAuth: true,
            },
          },
          {
            path: "accounting",
            name: "clients.profile.accounting",
            component: () =>
              import("../../../views/pages/clients/profile/pages/ClientAccountingInfo.vue"),
            meta: {
              title: "Client Profile Accounting",
              requiresAuth: true,
            },
          },
          {
            path: "seo",
            name: "clients.profile.seo",
            component: () => import("../../../views/pages/clients/profile/pages/ClientSEO.vue"),
            meta: {
              title: "Client Profile SEO",
              requiresAuth: true,
            },
          },
          {
            path: "sales",
            name: "clients.profile.sales",
            component: () => import("../../../views/pages/clients/profile/pages/ClientSales.vue"),
            meta: {
              title: "Client Profile Sales",
              requiresAuth: true,
            },
          },
          {
            path: "core-vehicles",
            name: "clients.profile.coreModels",
            component: () =>
              import("../../../views/pages/clients/profile/pages/ClientCoreModels.vue"),
            meta: {
              title: "Client Profile",
              requiresAuth: true,
            },
          },
          {
            path: "settings",
            name: "clients.profile.settings",
            component: () =>
              import("../../../views/pages/clients/profile/pages/ClientSettings.vue"),
            meta: {
              title: "Client Settings",
              requiresAuth: true,
            },
          },
          {
            path: "budgets",
            name: "clients.profile.budgets",
            component: () =>
              import(
                /* webpackChunkName: "clients.profile.budgets" */
                "../../../views/pages/clients/budgets/ClientBudgetsIndex.vue"
              ),
            meta: {
              title: "Client Budgets",
              requiresAuth: true,
            },
          },
          {
            path: "guest-access",
            name: "clients.profile.guest-access",
            component: () =>
              import("../../../views/pages/clients/profile/pages/ClientGuestAccess.vue"),
            meta: {
              title: "Client Guest Access List",
              requiresAuth: true,
            },
          },
          {
            path: "strategies",
            name: "clients.profile.strategies",
            component: () =>
              import("../../../views/pages/clients/profile/pages/ClientStrategies.vue"),
            meta: {
              title: "Client Strategies",
              requiresAuth: true,
            },
          },
          {
            path: "feeds",
            name: "clients.profile.feeds",
            component: () => import("../../../views/pages/clients/profile/pages/ClientFeeds.vue"),
            meta: {
              title: "Client Feeds",
              requiresAuth: true,
            },
          },
          {
            path: "first-party-data-upload",
            name: "clients.profile.first-party-data-upload",
            component: () =>
              import(
                /* webpackChunkName: "client.first-party-data-upload" */
                "../../../views/pages/clients/profile/pages/ClientFirstPartyDataUpload.vue"
              ),
            meta: {
              title: "First Party Data Upload",
              requiresAuth: true,
            },
          },
          {
            path: "mystery-shop-requests",
            name: "clients.profile.mystery-shop-requests",
            component: () =>
              import(
                /* webpackChunkName: "client.mystery-shop" */
                "../../../views/pages/clients/profile/pages/ClientMysteryShop.vue"
              ),
            meta: {
              title: "Mystery Shop",
              requiresAuth: true,
            },
          },
          {
            path: "assessments",
            name: "clients.profile.assessments",
            component: () =>
              import(
                /* webpackChunkName: "client.assessments" */
                "../../../views/pages/clients/profile/pages/ClientAssessments.vue"
              ),
            meta: {
              title: "Assessments",
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: ":slug/yoy-performance",
        name: "clients.yoy",
        component: () =>
          import(
            /* webpackChunkName: "clients.yoy" */
            "../../../views/pages/clients/yoy-performance/ClientYoYPerformancePage.vue"
          ),
        meta: {
          title: "YoY Performance",
          requiresAuth: true,
        },
      },

      // Client Reporting Views
      {
        path: ":slug",
        component: () =>
          import(
            /* webpackChunkName: "clients.reporting" */
            "../../../views/pages/clients/reporting/ClientsReportingPage.vue"
          ),
        props: true,
        children: [
          {
            path: "insights",
            name: "clients.insights",
            component: () =>
              import(
                /* webpackChunkName: "clients.insights" */
                "../../../views/pages/clients/reporting/insights/ClientInsightsPage.vue"
              ),
            meta: {
              title: "Client Insights",
              requiresAuth: true,
              clientAccessible: true,
              exportable: true,
              hasExportOptions: true,
              guestAccess: true,
              guestAccessComponent: "ClientInsightsPage",
              refreshable: true,
            },
          },
          {
            path: "connected-tv-insights",
            name: "clients.connected-tv-insights",
            component: () =>
              import(
                /* webpackChunkName: "clients.connected-tv-insights" */
                "../../../views/pages/clients/reporting/connected-tv-insights/ReportConnectedTVInsights.vue"
              ),
            meta: {
              title: "Connected TV Insights",
              clientAccessible: true,
              requiresAuth: true,
              exportable: true,
              hasExportOptions: false,
              guestAccess: false,
              refreshable: true,
            },
          },
          {
            path: "programmatic-insights",
            name: "clients.programmatic-insights",
            component: () =>
              import(
                /* webpackChunkName: "clients.programmatic-insights" */
                "../../../views/pages/clients/reporting/programmatic-report/ProgrammaticReport.vue"
              ),
            meta: {
              title: "Programmatic Insights",
              requiresAuth: true,
              clientAccessible: true,
              exportable: true,
              hasExportOptions: false,
              guestAccess: false,
              refreshable: true,
            },
          },
          {
            path: "programmatic-audio-insights",
            name: "clients.programmatic-audio-insights",
            component: () =>
              import(
                /* webpackChunkName: "clients.programmatic-audio-insights" */
                "../../../views/pages/clients/reporting/programmatic-audio-report/ProgrammaticAudioReport.vue"
              ),
            meta: {
              title: "Programmatic Audio Insights",
              requiresAuth: true,
              clientAccessible: true,
              exportable: true,
              hasExportOptions: false,
              guestAccess: false,
              refreshable: true,
            },
          },
          {
            path: "inventory-ads-report",
            name: "clients.inventory-ads-report",
            component: () =>
              import(
                /* webpackChunkName: "clients.inventory-ads-report" */
                "../../../views/pages/clients/reporting/inventory-ads-report/InventoryAdsReport.vue"
              ),
            meta: {
              title: "Inventory Ads Report",
              requiresAuth: true,
              clientAccessible: false,
              exportable: true,
              hasExportOptions: false,
              guestAccess: false,
              refreshable: false,
            },
          },
          {
            path: "google-business-profile-report",
            name: "clients.google-business-profile-report",
            component: () =>
              import(
                /* webpackChunkName: "clients.google-business-profile-report" */
                "../../../views/pages/clients/reporting/google-business-profile/ClientGoogleBusinessProfile.vue"
              ),
            meta: {
              title: "Google Business Profile Report",
              requiresAuth: true,
              clientAccessible: true,
              exportable: true,
              hasExportOptions: false,
              guestAccess: false,
              refreshable: false,
            },
          },
          {
            path: "client-activities",
            name: "clients.client-activities-report",
            component: () =>
              import(
                /* webpackChunkName: "clients.client-activities-report" */
                "../../../views/pages/clients/reporting/activities/ClientActivitiesReport.vue"
              ),
            meta: {
              title: "Client Activities",
              requiresAuth: true,
              clientAccessible: true,
              exportable: true,
              hasExportOptions: false,
              guestAccess: false,
              refreshable: false,
            },
          },
          {
            path: "local-service-ads",
            name: "clients.lsa-report",
            component: () =>
              import(
                /* webpackChunkName: "clients.lsa-report" */
                "../../../views/pages/clients/reporting/lsa-report/LsaReport.vue"
              ),
            meta: {
              title: "Local Service Ads",
              key: "local-service-ads",
              requiresAuth: true,
              clientAccessible: true,
              exportable: true,
              hasExportOptions: false,
              guestAccess: false,
              refreshable: true,
            },
          },
          {
            path: "location-report",
            name: "clients.location-report",
            component: () =>
              import(
                /* webpackChunkName: "clients.location-report" */
                "../../../views/pages/clients/reporting/location-report/LocationReportPage.vue"
              ),
            meta: {
              title: "Location Report",
              requiresAuth: true,
              clientAccessible: true,
              exportable: false,
              hasExportOptions: false,
              guestAccess: false,
              guestAccessComponent: "LocationReportPage",
            },
          },
          {
            path: "seo-division-report",
            name: "clients.seo-division-report",
            component: () =>
              import(
                /* webpackChunkName: "clients.seo-division-report" */
                "../../../views/pages/clients/reporting/seo-division-report/SeoDivisionReport.vue"
              ),
            meta: {
              title: "Detailed SEO Report",
              requiresAuth: true,
              clientAccessible: true,
              exportable: true,
              hasExportOptions: false,
              guestAccess: false,
              refreshable: true,
            },
          },
          {
            path: "conversioniq-insights",
            name: "clients.conversioniq-insights",
            component: () =>
              import(
                /* webpackChunkName: "clients.conversioniq-insights" */
                "../../../views/pages/clients/reporting/conversioniq-insights/ConversionIQInsights.vue"
              ),
            meta: {
              title: "ConversionIQ Insights",
              key: "conversioniq-insights",
              requiresAuth: true,
              clientAccessible: true,
              exportable: true,
              hasExportOptions: false,
              guestAccess: false,
              refreshable: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/prospects",
    component: () =>
      import(
        /* webpackChunkName: "prospects" */
        "../../../views/pages/prospects/ProspectsPage.vue"
      ),
    children: [
      {
        path: "",
        name: "prospects.index",
        component: () =>
          import(
            /* webpackChunkName: "prospects.index" */
            "../../../views/pages/prospects/index/ProspectsIndexPage.vue"
          ),
        meta: {
          title: "Prospects",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Prospects",
        },
      },
      {
        path: "create",
        name: "prospects.create",
        component: () =>
          import(
            /* webpackChunkName: "prospects.create" */
            "../../../views/pages/prospects/create/ProspectCreate.vue"
          ),
        meta: {
          title: "Create Prospect",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Prospects",
        },
      },
      {
        path: ":id/edit",
        name: "prospects.edit",
        component: () =>
          import(
            /* webpackChunkName: "prospects.edit" */
            "../../../views/pages/prospects/edit/ProspectEdit.vue"
          ),
        meta: {
          title: "Edit Prospect",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/contacts",
    component: () =>
      import(
        /* webpackChunkName: "contacts" */
        "../../../views/pages/contacts/ContactsPage.vue"
      ),
    children: [
      {
        path: "",
        name: "contacts.index",
        component: () =>
          import(
            /* webpackChunkName: "contacts.index" */
            "../../../views/pages/contacts/index/ContactsIndexPage.vue"
          ),
        meta: {
          title: "Contacts",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Clients",
        },
      },
    ],
  },
  {
    path: "/agreement/:slug",
    component: () =>
      import(
        /* webpackChunkName: "agreements" */
        "../../../views/pages/agreements/AgreementsPage.vue"
      ),
    children: [
      {
        path: "",
        name: "agreement.index",
        component: () =>
          import(
            /* webpackChunkName: "agreement.index" */
            "../../../views/pages/agreements/create/AgreementsCreatePage.vue"
          ),
        meta: {
          title: "Agreement",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/onboarding",
    component: () =>
      import(
        /* webpackChunkName: "onboarding" */
        "../../../views/pages/onboarding/OnboardingPage.vue"
      ),
    children: [
      {
        path: "",
        name: "onboarding.index",
        component: () =>
          import(
            /* webpackChunkName: "onboarding.index" */
            "../../../views/pages/onboarding/index/OnboardingIndex.vue"
          ),
        meta: {
          title: "Client Onboarding",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Client Onboarding",
        },
      },
      {
        path: "tasks",
        name: "onboarding.tasks",
        component: () =>
          import(
            /* webpackChunkName: "onboarding.tasks" */
            "../../../views/pages/onboarding/tasks/OnboardingTasksIndex.vue"
          ),
        meta: {
          title: "Onboarding Task Management",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Onboarding Task Management",
        },
      },
    ],
  },
  {
    path: "/leads",
    name: "leads.index",
    component: () => import("../../../views/pages/clients/leads/LeadsPage.vue"),
    meta: {
      title: "Leads",
      requiresAuth: true,
      searchable: true,
      searchGroup: "Leads",
    },
  },
  {
    path: "/priority-watchlist",
    component: () =>
      import("../../../views/pages/clients/priority-watchlist/PriorityWatchlist.vue"),
    children: [
      {
        path: "",
        name: "priority-watchlist.index",
        component: () =>
          import(
            "../../../views/pages/clients/priority-watchlist/index/PriorityWatchlistIndex.vue"
          ),
        meta: {
          title: "Priority Watchlist",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Priority Watchlist",
        },
      },
      {
        path: "/settings",
        name: "priority-watchlist.settings",
        component: () =>
          import(
            "../../../views/pages/clients/priority-watchlist/settings/PriorityWatchlistSettings.vue"
          ),
        meta: {
          title: "Priority Watchlist Settings",
          requiresAuth: true,
          searchable: false,
          requiredRoles: ["Super Administrator", "Administrator"],
        },
      },
    ],
  },
];
