import request from "../index";

export default {
  getEmails(params) {
    return request.get("/notifications/email", { ...params });
  },
  getEmailById(emailId) {
    return request.get(`/notifications/email/${emailId}`);
  },
  getStats(params) {
    return request.get("/notifications/email/stats", { ...params });
  },
  getCategoryStats(params) {
    return request.get("/notifications/email/category-stats", { ...params });
  },
  search(filters) {
    return request.post("/notifications/email/search", filters);
  },
  getEmailAttachments(emailId) {
    return request.get(`/notifications/email/${emailId}/attachments`);
  },
  queueEmail(emailId) {
    return request.post(`/notifications/email/${emailId}/queue`);
  },
  getCoffeeReportEmails(filters) {
    return request.get("/notifications/email/coffee-report", { ...filters });
  },
  getEmailsByCategory(filters) {
    return request.get("/notifications/email/get-by-category", { ...filters });
  },
  sendInternalEmail(email) {
    return request.post("/notifications/email-internal", { email });
  },
};
