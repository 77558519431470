<template>
  <div class="p-3 pb-12 md:p-4 lg:p-6 space-y-2">
    <slot />
  </div>
</template>

<script>
  export default {
    name: "BaseContent",
  };
</script>
