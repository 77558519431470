import request from "../api";
import store from "../store";

export default {
  computed: {
    isNotPDFUser() {
      const currentUser = store.getters["authorization/currentUser"];

      return currentUser && !currentUser.pdfUser;
    },
  },
  methods: {
    async getProfilesForClient(clientId, returnArray = false) {
      if (typeof clientId !== "number") {
        throw new Error("Missing client ID");
      }

      const profileTypes = await request
        .get(`/clients/${clientId}/profiles`)
        .then((res) => res.data);

      if (returnArray) {
        const profiles = {};

        profileTypes.forEach((profileType) => {
          if (!(profileType.name in profiles)) {
            profiles[profileType.name] = [];
          }

          profiles[profileType.name] = profiles[profileType.name].concat(
            profileType.Profiles.map((p) => p.identifier)
          );
        });

        return profiles;
      }

      const queryParams = {};

      profileTypes.forEach((profileType) => {
        queryParams[`account_profiles[${profileType.name}]`] = profileType.Profiles.map(
          (profile) => profile.identifier
        ).join();
      });

      return queryParams;
    },
  },
};
