<template>
  <div>
    <v-divider dark class="ma-6"></v-divider>

    <div class="flex justify-between align-center">
      <h4 class="text-h4 font-weight-light">Calendar</h4>

      <v-btn icon @click="this.fetchInitialData">
        <v-icon small>fas fa-sync-alt</v-icon>
      </v-btn>
    </div>

    <div class="mt-5">
      <template v-if="this.permitted">
        <event-list-item v-for="event in events" :key="event.id" :event="event" />
      </template>
      <p class="subtitle-1" v-else>
        Unable to connect to Google Calendar. Please verify the sharing permissions.
      </p>
    </div>
  </div>
</template>

<script>
  import CalendarResource from "../../../../../api/resources/CalendarResource";
  import EventListItem from "./EventListItem.vue";

  export default {
    name: "EventList",
    components: { EventListItem },
    data() {
      return {
        events: [],
        permitted: true,
      };
    },
    created() {
      this.fetchInitialData();
    },
    methods: {
      async fetchInitialData() {
        const { email } = this.$store.getters["authorization/currentUser"];

        this.events = await CalendarResource.getEvents(email)
          .then((res) => res.data)
          .catch(() => {
            this.permitted = false;
          });
      },
    },
    computed: {
      eventCount() {
        return this.events.length;
      },
    },
  };
</script>
