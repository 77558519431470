export default [
  {
    path: "/tools",
    component: () => import("../../../views/pages/tools/Tools.vue"),
    children: [
      {
        path: "integrations",
        component: () =>
          import(
            /* webpackChunkName: "integrations" */
            "../../../views/pages/tools/integrations/IntegrationsPage.vue"
          ),
        children: [
          {
            path: "",
            name: "tools.integrations.index",
            component: () =>
              import(
                /* webpackChunkName: "tools.integrations.index" */
                "../../../views/pages/tools/integrations/index/IntegrationsIndexPage.vue"
              ),
            meta: {
              title: "Client Integrations Report",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Settings",
            },
          },
        ],
      },
      {
        path: "forms",
        component: () => import("../../../views/pages/tools/form-builder/Index.vue"),
        children: [
          {
            path: "",
            name: "tools.forms.index",
            component: () =>
              import(
                /* webpackChunkName: "tools.forms.index" */
                "../../../views/pages/tools/form-builder/forms/Forms.vue"
              ),
            meta: {
              title: "Custom Forms",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Tools",
            },
          },
          {
            path: "new",
            name: "tools.forms.new",
            component: () =>
              import(
                /* webpackChunkName: "tools.forms.new" */
                "../../../views/pages/tools/form-builder/forms/NewForm.vue"
              ),
            meta: {
              title: "New Form",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Tools",
            },
          },
          {
            path: ":id/edit",
            name: "tools.forms.edit",
            component: () =>
              import(
                /* webpackChunkName: "tools.forms.edit" */
                "../../../views/pages/tools/form-builder/forms/EditForm.vue"
              ),
            meta: {
              title: "Edit Form",
              requiresAuth: true,
              searchable: false,
            },
          },
          {
            path: "templates",
            name: "tools.forms.templates",
            component: () =>
              import(
                /* webpackChunkName: "tools.forms.templates" */
                "../../../views/pages/tools/form-builder/templates/Templates.vue"
              ),
            meta: {
              title: "Form Templates",
              requiresAuth: true,
              searchable: true,
              searchGroup: "Tools",
            },
          },
          {
            path: "templates/new",
            name: "tools.forms.templates.new",
            component: () =>
              import(
                /* webpackChunkName: "tools.forms.templates.new" */
                "../../../views/pages/tools/form-builder/templates/NewTemplate.vue"
              ),
            meta: {
              title: "New Form Template",
              requiresAuth: true,
              searchable: true,
            },
          },
          {
            path: "/templates/:id/edit",
            name: "tools.forms.templates.edit",
            component: () =>
              import(
                /* webpackChunkName: "tools.forms.templates.edit" */
                "../../../views/pages/tools/form-builder/templates/EditTemplate.vue"
              ),
            meta: {
              title: "Edit Form Template",
              requiresAuth: true,
              searchable: false,
            },
          },
        ],
      },
      {
        path: "website-migrations",
        name: "tools.website_migrations",
        component: () =>
          import(
            /* webpackChunkName: "tools.website_migrations" */
            "../../../views/pages/tools/website-migrations/WebsiteMigrations.vue"
          ),
        meta: {
          title: "Website Migrations",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Tools",
        },
      },
      {
        path: "client-notes",
        name: "tools.client_notes",
        component: () =>
          import(
            /* webpackChunkName: "tools.client_notes" */
            "../../../views/pages/tools/client-notes/ClientNotesIndex.vue"
          ),
        meta: {
          title: "Client Notes",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Tools",
          partnerAccessible: true,
        },
      },
      {
        path: "dv360-override-file",
        name: "dv360-override-file.index",
        component: () =>
          import(
            /* webpackChunkName: "dv360-override-file.index" */
            "../../../views/pages/settings/dv360-override-file/Dv360OverrideFileIndex.vue"
          ),
        meta: {
          title: "Display & Video File Override",
          requiresAuth: true,
          searchable: true,
          searchGroup: "Settings",
        },
      },
    ],
  },
];
