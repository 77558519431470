<template>
  <div>
    <h6 class="my-2">Task Details</h6>
    <template>
      <v-row justify="center" class="p-3">
        <v-expansion-panels accordion v-model="panel">
          <v-expansion-panel v-for="(task, i) in tasks" :key="i">
            <v-expansion-panel-header class="grey lighten-4 h-10">
              {{ task.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="grey lighten-4">
              <v-form v-model="valid" class="flex flex-col space-y-2">
                <div>
                  <v-autocomplete
                    :items="departments"
                    v-model="tasks[i].teamId"
                    label="Team *"
                    item-text="name"
                    item-value="id"
                    @change="tasks[i].typeId = null"
                    hide-details
                    filled
                    :rules="requiredTextRule"
                  />
                </div>
                <div>
                  <v-autocomplete
                    :items="templates(i)"
                    v-model="tasks[i].typeId"
                    label="Type *"
                    item-text="name"
                    item-value="id"
                    hide-details
                    filled
                    :rules="requiredTextRule"
                  />
                </div>

                <div>
                  <v-autocomplete
                    :items="users"
                    v-model="tasks[i].assignee"
                    label="Assignee *"
                    item-text="fullName"
                    item-value="id"
                    hide-details
                    filled
                    :rules="requiredTextRule"
                  ></v-autocomplete>
                </div>

                <div class="w-full">
                  <v-dialog
                    ref="dueDateDialog"
                    v-model="dueDateDialog[i]"
                    :return-value.sync="tasks[i].dueDate"
                    persistent
                    width="290px"
                    @keydown.esc="dueDateDialog[i] = false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate(tasks[i].dueDate, 'MM/DD/YYYY')"
                        label="Due Date"
                        prepend-inner-icon="event"
                        readonly
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        filled
                        :rules="requiredTextRule"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="tasks[i].dueDate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dueDateDialog[i] = false">Cancel</v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dueDateDialog[i].save(tasks[i].dueDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </div>

                <div>
                  <v-text-field
                    v-model="tasks[i].title"
                    :rules="requiredTextRule"
                    label="Title *"
                    filled
                    required
                    hide-details
                  />
                </div>

                <div>
                  <p class="pb-0 mb-1">Description</p>
                  <custom-editor
                    v-model="tasks[i].description"
                    :users="users"
                    :index="i"
                    @updateMentions="updateMentions"
                    :rules="requiredTextRule"
                  />
                </div>
              </v-form>
              <v-btn v-if="i !== 0" @click="removeItem(i)" color="error" small class="my-2">
                Remove This Task
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </template>
    <div v-if="isCreatingTask" class="my-3 bg-orange-500 text-white p-6">
      <v-progress-circular class="mr-2" size="20" indeterminate color="white" />
      Please wait while task(s) are being created in TaskIQ.
    </div>
    <v-btn small color="primary" class="mt-3" @click="addNewTask">
      <v-icon x-small class="mr-2">fa-plus</v-icon>
      Add Another Task
    </v-btn>
  </div>
</template>

<script>
  import dayjs from "dayjs";
  import _ from "lodash";

  import TaskIQResource from "@/api/resources/TaskIQResource";
  import UserResource from "@/api/resources/UserResource";
  import CustomEditor from "@/components/utilities/CustomEditor.vue";

  export default {
    name: "CreateTask",
    props: ["interaction", "value"],
    components: { CustomEditor },
    data() {
      return {
        tasks: [],
        departments: [],
        taskTemplate: {
          assignee: null,
          teamId: null,
          title: "New Task",
          description: this.interaction.body,
          dueDate: dayjs().format("YYYY-MM-DD"),
          additionalFields: {}, // Default
          isApproval: 0, // Default,
          mentions: [], // Default,
          workflowId: null,
          priority: 2, // Default
          statusId: 1, // Default
          taskId: null, // Default,
          typeId: 1, // Default
          subtasks: [],
        },
        panel: 0,
        users: [],
        dueDateDialog: [],
        isCreatingTask: false,
      };
    },

    computed: {
      valid: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
      currentUser() {
        return this.$store.getters["authorization/currentUser"];
      },
    },

    async created() {
      this.resetTaskDetails();

      await Promise.allSettled([this.fetchDepartmentsWithTypes(), this.fetchUsers()]);
    },

    methods: {
      removeItem(i) {
        const tasksArray = this.tasks;

        tasksArray.splice(i, 1);
        this.tasks = tasksArray;
        this.panel = this.tasks.length - 1;
      },
      templates(i) {
        if (this.tasks[i].teamId) {
          return this.departments.find((d) => d.id === this.tasks[i].teamId).task_templates;
        }

        return [];
      },
      resetTaskDetails() {
        this.tasks = [
          {
            ...this.taskTemplate,
          },
        ];
      },
      addNewTask() {
        this.tasks = [
          ...this.tasks,
          {
            ...this.taskTemplate,
          },
        ];
        this.panel = this.tasks.length - 1;
      },
      updateMentions(mentions, index) {
        this.$set(this.tasks[index], "mentions", mentions);
      },
      async fetchUsers() {
        this.users = await UserResource.getActiveUsersList({ userTypeId: 1 }).then(
          (res) => res.data
        );
      },
      async fetchDepartmentsWithTypes() {
        this.departments = await TaskIQResource.getDepartmentsWithTypes().then((res) => {
          return _.sortBy(res.data, "name");
        });
      },
      async createTask() {
        this.isCreatingTask = true;

        const { kobiRefreshToken } = this.currentUser;

        await TaskIQResource.createTasks(
          kobiRefreshToken,
          this.tasks,
          this.interaction.client.id,
          this.currentUser.id
        )
          .then(async () => {
            this.showSuccessNotification("Task is successfully created in TaskIQ.");
          })
          .catch(() => {
            this.showErrorNotification("There was an error creating the task in TaskIQ.");
          })
          .finally(() => {
            this.isCreatingTask = false;
          });
      },
    },
  };
</script>
