function CKEditorMentionCustomization(editor) {
  editor.conversion.for("upcast").elementToAttribute({
    view: {
      name: "span",
      key: "data-mention",
      classes: "mention",
      attributes: { "data-mention-id": true },
    },
    model: {
      key: "mention",
      value: (viewItem) => {
        return editor.plugins
          .get("Mention")
          .toMentionAttribute(viewItem, { userId: viewItem.getAttribute("data-mention-id") });
      },
    },
    converterPriority: "high",
  });

  editor.conversion.for("downcast").attributeToElement({
    model: "mention",
    view: (modelAttributeValue, { writer }) => {
      if (!modelAttributeValue) {
        return;
      }

      if (modelAttributeValue.userId) {
        // eslint-disable-next-line consistent-return
        return writer.createAttributeElement("span", {
          class: "mention",
          "data-mention": modelAttributeValue.id,
          "data-mention-id": modelAttributeValue.userId,
        });
      }
    },
    converterPriority: "high",
  });
}

export default CKEditorMentionCustomization;
